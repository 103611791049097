import React from 'react';
import { IconButton, Menu, MenuItem, Dropdown } from '@mui/joy';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons';

interface MoreOptionsMenuProps {
  handleMoreOptionsClick: (e) => void;
  menuRef: any;
  anchorEl: any;
  handleClose: () => void;
  selectAll: (e) => void;
  deselectAll: (e) => void;
}

const MoreOptionsMenu = ({
  handleMoreOptionsClick,
  menuRef,
  anchorEl,
  handleClose,
  selectAll,
  deselectAll,
}: MoreOptionsMenuProps) => {
  return (
    <Dropdown>
      <IconButton
        size="sm"
        onClick={handleMoreOptionsClick}
        color="neutral"
        variant="soft"
        aria-label="More Options"
      >
        <Icon icon={faEllipsisV} />
      </IconButton>
      <Menu
        placement="bottom-end"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        ref={menuRef}
        onClick={(e) => e.stopPropagation()}
        onClose={handleClose}
      >
        <MenuItem
          sx={{ color: 'var(--Neutral-800, #2C2E2F)' }}
          onClick={selectAll}
        >
          Select All
        </MenuItem>
        <MenuItem onClick={deselectAll}>Deselect All</MenuItem>
      </Menu>
    </Dropdown>
  );
};

export default MoreOptionsMenu;
