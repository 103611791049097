import { Box, styled } from '@mui/joy';
import { GridToolbarContainerProps } from '@mui/x-data-grid-premium';
import { grey } from '@mui/material/colors';

interface ToolbarProps extends GridToolbarContainerProps {
  onExport?: (type: string) => void;
  onRemove?: () => void;
  variant: 'primary' | 'secondary';
  bottomHeight?: string;
  headerSlots?: {
    slot1?: React.ReactNode;
    slot2?: React.ReactNode;
  };
}

const ToolbarRow = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: 'var(--2, 1rem)',
  height: '100px',
}));

const ToolbarBottomRow = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'height',
})<{ height: string }>(({ theme, height }) => ({
  display: 'flex',
  padding: 'var(--2, 1rem)',
  alignItems: 'center',
  alignSelf: 'stretch',
  height: height || '100px',
}));

const TableToolbar = ({ bottomHeight = '100px', ...props }: ToolbarProps) => {
  const isPrimary = props.variant === 'primary';
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '200px',
        borderBottom: isPrimary
          ? `1px solid ${grey[300]}`
          : '1px solid #005B3899',
        background: isPrimary
          ? 'white'
          : 'var(--secondary-outlined-ActiveBg, #D6F6F1)',
      }}
    >
      <ToolbarRow
        sx={{
          borderBottom: isPrimary
            ? `1px solid ${grey[300]}`
            : '1px solid #005B3899',
        }}
      >
        {props.headerSlots?.slot1}
      </ToolbarRow>

      <ToolbarBottomRow height={bottomHeight}>
        {props.headerSlots?.slot2}
      </ToolbarBottomRow>
    </Box>
  );
};

export default TableToolbar;
