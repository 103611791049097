import React, { useMemo } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
} from '@mui/joy';
import { useGetSavedSearches } from 'src/hooks';
import { Folder } from '../Folder';
import { SavedSearch } from '../SavedSearch';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import { sentenceCase } from 'src/utils';
import { useSite } from 'src/context';

const Section = ({ index, name, toggleAccordion, section }) => {
  const { state } = useSite();
  const { data: savedSearches } = useGetSavedSearches();
  const openAccordion = state?.savedSearch?.openAccordion;
  const isCompany = name === 'COMPANY';

  const nonFolderItems = useMemo(() => {
    if (!savedSearches) return [];
    // Get all IDs for all items in the section
    const allIdsInFolders = section?.results
      ?.flatMap((folder) => folder?.saved_searches ?? [])
      .join(',');

    // handle short circuiting if there are no saved searches
    const stateSearches = state?.savedSearch?.tempSavedSearches ?? [];
    const combinedSearches = [...savedSearches, ...stateSearches];

    const sortedSearches = combinedSearches
      ?.filter((search) => {
        const isInFolders = allIdsInFolders?.includes(search.id);
        // If the search is not in any folders, it should be displayed in the section and not in a folder
        return !isInFolders && search.org_saved_search === isCompany;
      })
      .sort((a, b) => {
        // sort alphabetically by name
        return a.search_name?.localeCompare(b.search_name);
      });

    return sortedSearches.sort((a, b) => {
      // Prioritize temp_ IDs
      const aTemp = a.id?.toString()?.includes('temp-');
      const bTemp = b.id?.toString()?.includes('temp-');
      if (aTemp && !bTemp) return -1;
      if (!aTemp && bTemp) return 1;
      return 0;
    });
  }, [savedSearches, section, state?.savedSearch?.tempSavedSearches]);

  return (
    <Accordion
      expanded={openAccordion === index}
      onChange={() => toggleAccordion(index)}
      sx={{
        maxHeight: 'calc(100% - 74px)',
        overflow: 'hidden',
        borderTop: '1px solid #E0E0E0',
      }}
    >
      <AccordionSummary
        indicator={null}
        sx={{
          display: 'flex',
          height: '2.5rem',
          alignItems: 'center',
          gap: 'var(--1, 0.5rem)',
          width: '100%',
        }}
        aria-label={`${sentenceCase(name)}`}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 'var(--1, 0.25rem)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '24px',
              width: '24px',
              flex: '0 0 24px',
            }}
          >
            <Icon
              icon={openAccordion === index ? faChevronDown : faChevronRight}
              style={{
                color: '#636B74',
                padding: 0,
              }}
              size="sm"
            />
          </Box>
          <Typography
            sx={{
              color: 'var(--text-tertiary, #555E68)',
              fontSize: '0.875rem',
              fontWeight: 700,
              lineHeight: '1.243rem' /* 19.88px */,
            }}
          >
            {sentenceCase(name)}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            height: '100%',
            overflow: 'hidden',
            overflowY: 'auto',
            padding: 'var(--none, 0px) var(--1, 0.5rem)',
          }}
        >
          {section?.results?.map((folder, index) => {
            return <Folder key={index} folder={folder} />;
          })}
          {nonFolderItems?.map((search, index) => {
            return <SavedSearch key={index} search={search} />;
          })}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default Section;
