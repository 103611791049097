import { Typography, MenuItem, Menu, styled } from '@mui/material';
import { Link } from 'src/components';
import React, { useMemo } from 'react';
import { useLayoutControl, useLogging } from 'src/context';
import { useGetMappings } from 'src/hooks';
import { Box } from '@mui/joy';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faArrowUpRightFromSquare,
  faCircle,
  faCircleSmall,
  faDotCircle,
  faExternalLink,
  faUserTie,
} from '@fortawesome/pro-solid-svg-icons';

const StyledText = styled('span')(({ theme }) => ({
  fontSize: '0.8rem',
  fontWeight: 500,
  whiteSpace: 'nowrap',
  lineHeight: '1.25rem',
  marginRight: '-1px',
  width: '100%',
  color: theme.palette.primary.main,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: 'flex',
  gap: '0.5rem',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  padding: '0.5rem 1rem',
}));

const InnerWrapper = styled(Box)({
  display: 'flex',
  padding: '0.375rem 1rem',
  alignItems: 'center',
  gap: '.5rem',
  flex: '1 0 0',
  alignSelf: 'stretch',
});

const TextValue = styled(Typography)({
  color: ' #2C2E2F',
  fontFamily: 'Inter',
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: 'normal',
});

interface CRMLinks {
  crm: string;
  crm_link: string;
  crm_object_type: string;
  created_by: {
    firstname: string;
    lastname: string;
  };
}

interface CRMMaxMenuProps {
  provider: MedScout.Opportunity | null;
  integration: string;
  options: CRMLinks[];
  anchorEl: HTMLElement;
  setAnchorEl: (el: HTMLElement) => void;
}

const CRMMaxMenu = ({
  provider,
  integration,
  options = [],
  anchorEl,
  setAnchorEl,
}: CRMMaxMenuProps) => {
  const { setPushProviderToCRM } = useLayoutControl();
  const log = useLogging();

  const { data: allMappings } = useGetMappings({
    sort: 'label',
    order: 'asc',
    page: 0,
    pageSize: 100,
  });

  // Gets mappings for specific provider type, removing used mappings
  const mappings = useMemo(() => {
    return allMappings?.results
      ?.filter(
        (mapping) =>
          mapping?.medscout_object_type ===
            provider?.content_type?.toUpperCase() &&
          mapping?.enabled &&
          mapping?.crm?.toLowerCase() === integration?.toLowerCase()
      )
      ?.filter(
        (mapping) =>
          !provider?.crm_links?.some((link) => link.mapping_id === mapping.id)
      );
  }, [allMappings, integration, provider]);

  // Function to handle closing the menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Function to handle Link click event
  const handleLinkClick = (e) => {
    e.stopPropagation();
    log.event('viewInCRMLinkClicked');
    setAnchorEl(null);
  };

  const renderSingleCRMLink = (option) => {
    const object = option?.crm_object_type;
    const status = option?.crm_status;
    const link = option?.crm_link;
    const fullName = `${option?.owner?.firstname || ''} ${
      option?.owner?.lastname || ''
    }`?.trim();

    return (
      <Box sx={{ width: '10.9rem' }}>
        {status && (
          <InnerWrapper>
            <Icon
              icon={faCircleSmall}
              style={{ color: '#838586', width: '1rem', height: '1rem' }}
            />
            <TextValue>{status}</TextValue>
          </InnerWrapper>
        )}
        {fullName && (
          <InnerWrapper>
            <Icon
              icon={faUserTie}
              size="sm"
              style={{ color: '#838586', width: '1rem', height: '1rem' }}
            />
            <TextValue>{fullName}</TextValue>
          </InnerWrapper>
        )}
        {link && (
          <Link
            href={link}
            onClick={handleLinkClick}
            target="_blank"
            underline="none"
            sx={{
              display: 'flex',
              width: '10.9rem' /* 175px */,
              padding: '0.375rem 1rem',
              alignItems: 'center',
              gap: '.5rem',
              flex: '1 0 0',
              alignSelf: 'stretch',
              ':hover': { textDecoration: 'underline' },
            }}
          >
            <Icon
              icon={faArrowUpRightFromSquare}
              size="sm"
              style={{ color: '#838586', width: '1rem', height: '1rem' }}
            />
            <TextValue>View {object}</TextValue>
          </Link>
        )}
      </Box>
    );
  };

  const renderMenuItem = (link, index) => {
    const fullName = `${link?.owner?.firstname || ''} ${
      link?.owner?.lastname || ''
    }`?.trim();

    const label = link?.crm_object_type
      ? `View ${link?.crm_object_type} in CRM`
      : fullName;

    return (
      <MenuItem key={index} aria-label={`Menu Item For ${label}`} sx={{ p: 0 }}>
        <Link
          href={link?.crm_link}
          onClick={handleLinkClick}
          target="_blank"
          underline="none"
          sx={{
            display: 'flex',
            gap: '0.5rem',
            width: '100%',
            height: '100%',
            p: '0.5rem 1rem',
          }}
        >
          <StyledText>{label}</StyledText>
        </Link>
      </MenuItem>
    );
  };

  if (!options.length) return null;

  return (
    <>
      {options.length === 1 ? (
        <Menu
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '0px',
            borderRadius: '.5rem',
            border: '1px solid var(--neutral-outlined-Border, #CDD7E1)',
            /* shadow-md */
            boxShadow:
              '0px 2px 8px -2px rgba(21, 21, 21, 0.08), 0px 6px 12px -2px rgba(21, 21, 21, 0.08)',
          }}
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleMenuClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {renderSingleCRMLink(options[0])}
          <MenuItem
            onClick={() => {
              handleMenuClose();
              setPushProviderToCRM(provider, integration);
            }}
            sx={{
              p: 0,
              mt: 2,
              borderTop: '1px solid var(--neutral-outlined-Border, #CDD7E1)',
            }}
            disabled={!mappings?.length}
          >
            <StyledTypography>
              <StyledText>Push to New</StyledText>
            </StyledTypography>
          </MenuItem>
        </Menu>
      ) : (
        <Menu
          sx={{ mt: '0.25rem' }}
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleMenuClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {options.map((link, index) => renderMenuItem(link, index))}
          <MenuItem
            onClick={() => {
              handleMenuClose();
              setPushProviderToCRM(provider, integration);
            }}
            sx={{ p: 0 }}
            disabled={!mappings?.length}
          >
            <StyledTypography>
              <StyledText>Push to New</StyledText>
            </StyledTypography>
          </MenuItem>
        </Menu>
      )}
    </>
  );
};

export default CRMMaxMenu;
