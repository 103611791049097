import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import {
  useLogging,
  useNotification,
  useProspectSearch,
  useProspectSelection,
  useTable,
} from 'src/context';
import {
  baseColumns,
  InnerContainer,
  NewTableToolbar,
  NoVolumeOverlay,
  PrimaryTableContainer,
  TableCheckbox,
  TableControls,
  TableFooter,
  TablePrimaryHeader,
  TableSecondaryHeader,
  VolumeTableContainer,
} from '../components';
import { useFilters } from 'src/modules/Profile/context';
import {
  useAffiliationsDashboard,
  useCurrentTable,
  useGetSavedSearches,
  useLocalStorage,
  useSyncRowHovered,
  useSyncTableScroll,
  useVolumeColumns,
} from 'src/hooks';
import {
  DataGridPremium,
  GridFilterModel,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { VOLUME_SELECT_OPTIONS } from 'src/components/VolumeSelectors/constants';
import DataBox from 'src/components/DataBox';
import { TableLoadingIndicator } from 'src/components/LoadingIndicator';
import { TableSettings } from 'src/components/Table';
import { VolumeSelectors } from 'src/components/VolumeSelectors';

const GroupAffiliationsTable = () => {
  const router = useRouter();
  const log = useLogging();
  const apiRef = useGridApiRef();
  const apiRef2 = useGridApiRef();
  const { filters } = useFilters();
  const { prospectMode, prospectSearch, prospectVolumeType } =
    useProspectSearch();

  const { currentSearchIds, setCurrentSearchIds } = useTable();
  const { currentTable, currentYears } = useCurrentTable('affiliations');
  const { volumeColumns, optionColumns } = useVolumeColumns();

  const { type, id } = router.query as {
    id: string;
    type: string;
  };

  // Sync the scroll of the two tables
  useSyncTableScroll(apiRef, apiRef2);
  const { isRowHovered } = useSyncRowHovered(apiRef, apiRef2);

  const { setNotification } = useNotification();
  const {
    state: [, setNewProspects],
    selectedRows: [rowsAreSelected],
  } = useProspectSelection();
  const { data: savedSearches = [] } = useGetSavedSearches();
  const [selectionModel, setSelectionModel] = useState<any[]>([]);
  const [isExporting, setIsExporting] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  const [sort, setSort] = useState({
    field: 'name',
    order: 'desc',
    sort_search: '',
    sort_value: '',
  });

  useEffect(() => {
    if (!prospectSearch?.id) return;
    // set the sort value to the current saved search if one is selected
    setSort({
      field: 'saved_search',
      order: 'desc',
      sort_search: `${prospectSearch?.id}`,
      sort_value: prospectVolumeType,
    });

    return () =>
      setSort({
        field: 'name',
        order: 'desc',
        sort_search: '',
        sort_value: '',
      });
  }, [prospectSearch, prospectVolumeType]);

  // toggle state to clear selected rows
  useEffect(() => {
    setSelectionModel(() => []);
  }, [rowsAreSelected]);

  // update selected rows when DataGrid selection model changes
  useEffect(() => {
    if (!apiRef.current?.getSelectedRows) return;
    setNewProspects(Array.from(apiRef.current.getSelectedRows().values()));
    return () => setNewProspects([]);
  }, [apiRef, selectionModel, setNewProspects]);

  const [columnVisibilityModel, setColumnVisibilityModel] = useLocalStorage(
    'newAffiliationsTable',
    {
      name: true,
      crm_links: true,
      specialty: true,
      provider_id: false,
      address: false,
      city: false,
      state: false,
      zip_code: false,
      phone: false,
      email: false,
    }
  );

  const [showVolume, setShowVolume] = useLocalStorage(
    'showVolumeAffiliations',
    true
  );

  const handlePageChange = (e, newPage) => {
    setPaginationModel((prev) => ({ ...prev, page: newPage }));
  };

  const handlePageSizeChange = (e) => {
    setPaginationModel((prev) => ({
      ...prev,
      pageSize: parseInt(e.target.value, 10),
      page: 0,
    }));
  };

  const searchIds = useMemo(
    () =>
      Object.keys(columnVisibilityModel).filter(
        (k) =>
          columnVisibilityModel[k] &&
          savedSearches.map((s) => s.id).indexOf(Number(k)) > -1
      ),
    [columnVisibilityModel, savedSearches]
  );

  useEffect(() => {
    setCurrentSearchIds(searchIds);
  }, [searchIds]);

  // maintain column visibility state when currentTable trending changes.. this is to show the volume columns
  useEffect(() => {
    if (!currentTable) return;
    if (!volumeColumns?.length) return;
    if (!optionColumns?.length) return;
    // set options columns to visible if the table is trending
    setColumnVisibilityModel((columnVisibilityState) => ({
      ...columnVisibilityState,
      ...serializeColumnVisibility(columnVisibilityState),
    }));
  }, [currentTable, prospectSearch, volumeColumns]);

  const {
    data: newData,
    isLoading: isLoadingReferral,
    isFetching: isFetchingReferral,
    getExport,
  } = useAffiliationsDashboard({
    providerId: Number(id),
    mode: prospectMode,
    enabledSearchIds: currentSearchIds,
    sort: sort?.field,
    order: sort?.order,
    sort_search: sort?.sort_search,
    sort_value: sort?.sort_value,
    type: type,
    id: id,
    filters: filters,
    dateRange: {
      lte: currentTable?.lte,
      gte: currentTable?.gte,
    },
  });

  const serializeColumnVisibility = (currentVisibility) => {
    const visibilityMap = {};

    for (const col of volumeColumns) {
      if (col.field === 'crm') continue;
      const field = col.field;
      const isVisible = !!currentVisibility[field];

      visibilityMap[field] = isVisible;
    }

    for (const col of optionColumns) {
      const field = col.field;
      const fieldWithoutOption = field.split('_')[1];

      const isInOptions = VOLUME_SELECT_OPTIONS?.some(
        (o) => o.value === fieldWithoutOption
      );
      const doesShow =
        currentYears.includes(Number(fieldWithoutOption)) &&
        currentTable?.isTrending &&
        searchIds?.length > 0;

      if (doesShow) {
        visibilityMap[field] = true;
      } else if (isInOptions) {
        visibilityMap[field] = false;
      }
    }
    columnVisibilityModel[prospectSearch?.id] = showVolume;
    setShowVolume(visibilityMap[prospectSearch?.id]);
    return visibilityMap;
  };

  function handleSortChange(model) {
    let newField = 'total_patients';
    let sort_search = '';
    let sort_value = '';

    if (model?.length) {
      const { field } = model[0];
      newField =
        isNaN(Number(field)) && field !== 'volume' ? field : 'saved_search';

      if (newField === 'saved_search') {
        sort_search = field !== 'volume' ? field : `${prospectSearch?.id}`;
        sort_value = prospectVolumeType;
      }
    }

    setSort({
      field: newField,
      order: model?.length ? model[0].sort : 'desc',
      sort_search,
      sort_value,
    });
  }

  // This handles normal exports, i.e. not server side exports. Notes for later
  const handleExportList = useCallback(
    async (format) => {
      setIsExporting(true);
      try {
        const visibleColumns = apiRef.current
          .getVisibleColumns()
          .map((col) => col.field)
          .filter((col) => col !== 'volume' && col.indexOf('__') === -1);
        const filename = await getExport(format, visibleColumns);
        if (!filename) return;

        setNotification({
          title: 'Success',
          message:
            'Export was started successfully. Please check your downloads.',
          type: 'success',
        });

        log.event('Export', {
          type: 'affilitions',
          source: 'affilitations',
          format,
          count: apiRef.current?.getRowsCount(),
          filename,
        });
      } catch (err: any) {
        log.exception(`Error exporting affiliations: ${err.message}`);

        setNotification({
          title: 'Error',
          message: 'Error exporting affiliations',
          type: 'error',
        });
      } finally {
        setIsExporting(false);
      }
    },
    [apiRef, log, setNotification, getExport]
  );

  const filteredData = useMemo(() => {
    if (!newData?.table) return [];

    const query = filterModel.quickFilterValues?.join(' ');
    if (!query) return newData.table;

    return newData.table.filter((row) =>
      Object.values(row)
        .map((value) => (value ? value.toString().toLowerCase() : ''))
        .join(' ')
        .includes(query ? query.toLowerCase() : '')
    );
  }, [filterModel, newData]);

  const enhancedRows = useMemo(() => {
    if (!filteredData || !prospectSearch) return [];

    return filteredData?.map((row) => {
      return row;
    });
  }, [filteredData, prospectSearch]);

  return (
    <DataBox sx={{ height: '50rem', minWidth: 0 }} isCommercial>
      <PrimaryTableContainer>
        <InnerContainer>
          <DataGridPremium
            disableVirtualization
            apiRef={apiRef}
            rows={enhancedRows || []}
            rowCount={filteredData?.length || 0}
            columns={baseColumns}
            scrollbarSize={0}
            hideFooter={true}
            checkboxSelection
            getRowClassName={isRowHovered}
            disableColumnMenu
            disableRowSelectionOnClick
            sx={{
              // remove border from table and only show overflow on x axis
              border: 'none',
              borderRadius: 0,
              '& .MuiDataGrid-main, & .MuiDataGrid-footerContainer': {
                backgroundColor: 'white',
              },
              '& .MuiDataGrid-virtualScroller': {
                overflowY: 'scroll',
                overflowX: 'auto',
                marginRight: '-1rem', // hides the Y axis scrollbar
              },
              '& .MuiDataGrid-columnHeadersInner>div:first-of-type, & .MuiDataGrid-groupHeader, & .MuiDataGrid-columnHeader--emptyGroup':
                {
                  // display: 'none !important',
                  height: 'auto !important',
                },
              '& .MuiDataGrid-row:hover, & .Mui-hovered': {
                backgroundColor: 'rgba(204, 204, 194, 0.13)',
                '&.Mui-hovered': {
                  backgroundColor: 'rgba(204, 204, 194, 0.13)',
                  transition: 'background-color 0.3s ease-in-out',
                },
              },
              '& .MuiDataGrid-columnSeparator': {
                display: 'none !important',
              },
            }}
            initialState={{
              columns: { columnVisibilityModel },
              pagination: { paginationModel: { page: 0, pageSize: 50 } },
            }}
            slots={{
              toolbar: NewTableToolbar,
              baseCheckbox: TableCheckbox,
              ...TableSettings.slots,
            }}
            slotProps={{
              columnsPanel: {
                disableHideAllButton: true,
                disableShowAllButton: true,
              },
              toolbar: {
                variant: 'primary',
                bottomHeight: '100px',
                headerSlots: {
                  slot1: <TablePrimaryHeader title="Affiliations" />,
                  slot2: (
                    <TableControls
                      onExport={handleExportList}
                      isExporting={isExporting}
                      columns={baseColumns}
                      columnVisibilityModel={columnVisibilityModel}
                      setColumnVisibilityModel={setColumnVisibilityModel}
                      showAddToListButton
                    />
                  ),
                },
              },
            }}
            // Pagination
            pagination
            paginationModel={paginationModel}
            // Sorting
            sortingMode="server"
            onSortModelChange={handleSortChange}
            // Filtering
            filterMode="server"
            onFilterModelChange={setFilterModel}
            filterModel={filterModel}
            // Column visibility
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={setColumnVisibilityModel}
            // Rows
            getRowHeight={({ model, densityFactor }) => densityFactor * 80}
            onRowSelectionModelChange={setSelectionModel}
            rowSelectionModel={selectionModel}
            getRowId={(row) => row.provider_id}
          />
          <VolumeTableContainer width="500px">
            <DataGridPremium
              disableVirtualization
              apiRef={apiRef2}
              rows={enhancedRows || []}
              rowCount={filteredData?.length || 0}
              columns={[...volumeColumns, ...optionColumns]}
              hideFooter={true}
              getRowClassName={isRowHovered}
              disableColumnMenu
              disableRowSelectionOnClick
              sx={{
                // remove border from table
                border: 'none',
                borderRadius: 0,
                background: 'var(--secondary-outlined-ActiveBg, #D6F6F1)',
                '& .muiDataGrid-withBorderColor': {
                  border: 'none',
                },
                '& .MuiDataGrid-virtualScroller': {
                  overflowY: 'auto',
                  overflowX: 'hidden',
                },
                '& .MuiDataGrid-columnHeaderTitleContainerContent': {
                  display: 'flex',
                  alignItems: 'center',
                  flex: '1 0 0',
                  alignSelf: 'stretch',
                },
                '& .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainer':
                  {
                    borderBottom: 'none',
                  },
                // This fixes that little notch on the right created by the scrollbar
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor:
                    'var(--secondary-outlined-ActiveBg, #D6F6F1)',

                  '& .MuiDataGrid-columnSeparator': {
                    color: 'none',
                  },
                },
                '& .MuiDataGrid-overlayWrapperInner': {
                  backgroundColor:
                    'var(--secondary-outlined-ActiveBg, #D6F6F1)',
                },
                '& .MuiDataGrid-columnHeaderGroupCell': {
                  borderBottom: 'none', // Remove group header cell borders if present
                },
                '& .MuiDataGrid-row:hover, & .Mui-hovered': {
                  backgroundColor: 'rgba(204, 204, 194, 0.13)',
                  '&.Mui-hovered': {
                    backgroundColor: 'rgba(204, 204, 194, 0.13)',
                  },
                },
              }}
              slots={{
                toolbar: NewTableToolbar,
                noRowsOverlay: NoVolumeOverlay,
                noResultsOverlay: NoVolumeOverlay,
                ...TableSettings.slots,
              }}
              slotProps={{
                columnsPanel: {
                  disableHideAllButton: true,
                  disableShowAllButton: true,
                },
                toolbar: {
                  bottomHeight: '100px',
                  headerSlots: {
                    slot1: (
                      <TableSecondaryHeader
                        columns={volumeColumns}
                        columnVisibilityModel={columnVisibilityModel}
                        setColumnVisibilityModel={setColumnVisibilityModel}
                        showVolumeMenu
                      />
                    ),
                    slot2: (
                      <VolumeSelectors
                        showTreatingReferring
                        table="affiliations"
                        enabledSearchIds={searchIds}
                      />
                    ),
                  },
                },
              }}
              pagination
              paginationModel={paginationModel}
              // Sorting
              sortingMode="server"
              onSortModelChange={handleSortChange}
              // Column visibility
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={setColumnVisibilityModel}
              // Rows
              getRowHeight={({ model, densityFactor }) => densityFactor * 80}
              getRowId={(row) => row.provider_id}
            />
          </VolumeTableContainer>
          {(isLoadingReferral || isFetchingReferral) && (
            <TableLoadingIndicator top="255px" />
          )}
        </InnerContainer>
        <TableFooter
          page={paginationModel.page}
          pageSize={paginationModel.pageSize}
          rowCount={newData?.table?.length || 0}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </PrimaryTableContainer>
    </DataBox>
  );
};

export default GroupAffiliationsTable;
