import React, { useMemo } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/joy';
import { CardHeaderContainer, CardHeader, CardLoadingIndicator } from '../Card';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faFilter,
  faTrophyStar,
} from '@fortawesome/pro-solid-svg-icons';
import { SavedSearchFilter } from 'src/components';
import { useGetTopProviders } from 'src/hooks';
import { CardLocationRow } from '../Card/CardLocationRow';
import { InvalidSavedSearch, NoDataAvailable } from '../NoData';
import { useProfile, useSite } from 'src/context';

const ProfileTopProviders = () => {
  const { details } = useProfile();
  const { state } = useSite();

  const selectedSavedSearch =
    state?.savedSearch?.filterBySavedSearch?.selectedSavedSearch;

  const { data: topProvidersData, isLoading } = useGetTopProviders({
    providerId: details?.provider_id,
  });

  const sortedProviders = useMemo(() => {
    if (!topProvidersData) return [];
    return topProvidersData.results?.slice(0, 5);
  }, [topProvidersData]);

  const isValidSavedSearch = useMemo(() => {
    if (!selectedSavedSearch) return true;

    const hasProcedureCodes =
      selectedSavedSearch?.filters?.cpt?.length > 0 ||
      selectedSavedSearch?.filters?.hcpcs?.length > 0 ||
      selectedSavedSearch?.filters?.icd?.length > 0 ||
      selectedSavedSearch?.filters?.drg?.length > 0 ||
      selectedSavedSearch?.filters?.icdp?.length > 0;

    return hasProcedureCodes;
  }, [selectedSavedSearch]);

  return (
    <Card sx={{ padding: '1rem 0', background: 'white' }}>
      <CardHeaderContainer>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
          <CardHeader>
            <Icon icon={faTrophyStar} color="#636B74" />
            <Typography level="title-md" sx={{ fontWeight: 600 }}>
              Top Providers
            </Typography>
          </CardHeader>
          <Typography
            level="body-xs"
            sx={{ color: '#636B74', paddingBottom: '.5rem', fontWeight: 400 }}
          >
            Procedure* volumes at this location, by claim, rounded to the
            nearest highest 5, medicare, medicaid, 2022 -2024
          </Typography>
        </Box>
        <SavedSearchFilter
          startDecorator={faFilter}
          endDecorator={faCaretDown}
          context="site"
        />
      </CardHeaderContainer>
      <CardContent sx={{ position: 'relative', minHeight: '5rem' }}>
        {/* Loading overlay */}
        {isLoading && <CardLoadingIndicator />}

        {/* Main content */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 'var(--1, 0.5rem)',
            paddingX: 'var(--2, 1rem)',
          }}
        >
          {!isValidSavedSearch ? (
            <InvalidSavedSearch />
          ) : sortedProviders?.length > 0 ? (
            sortedProviders.map((provider, index) => (
              <CardLocationRow key={index} location={provider} />
            ))
          ) : (
            !isLoading && <NoDataAvailable />
          )}
        </Box>
      </CardContent>
      <Box sx={{ padding: '0.125rem 1.25rem' }}>
        <Typography level="body-xs" sx={{ color: '#555E68', fontWeight: 400 }}>
          Prescription data not available in this widget.
        </Typography>
      </Box>
    </Card>
  );
};

export default ProfileTopProviders;
