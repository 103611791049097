import { Box } from '@mui/joy';
import React from 'react';

const VolumeTableContainer = ({
  children,
  width = '400px',
}: {
  children: React.ReactNode;
  width?: string;
}) => {
  return (
    <Box
      sx={{
        height: '100%',
        width: width,
        minWidth: 0,
        flexShrink: 0,
        background: 'var(--secondary-outlined-ActiveBg, #D6F6F1)',
        overflow: 'hidden',
        boxSizing: 'border-box',
        border: 'none',
        transition: 'width 0.3s easeInOut',
        transform: 'translateX(0)',
      }}
    >
      {children}
    </Box>
  );
};

export default VolumeTableContainer;
