import { useEffect, useRef } from 'react';
import { Box, styled } from '@mui/joy';
import { GridToolbarContainerProps } from '@mui/x-data-grid-premium';
import { useTable } from 'src/context';
import { grey } from '@mui/material/colors';

interface ToolbarProps extends GridToolbarContainerProps {
  onExport?: (type: string) => void;
  onRemove?: () => void;
  headerSlots?: {
    slot1?: React.ReactNode;
    slot2?: React.ReactNode;
    slot3?: React.ReactNode;
    slot4?: React.ReactNode;
  };
}

const ToolbarRow = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: '1rem',
}));

const TableToolbar = ({ ...props }: ToolbarProps) => {
  const secondaryHeaderRef = useRef<HTMLDivElement>(null);
  const { secondaryHeaderWidth, setSecondaryHeaderWidth } = useTable();

  useEffect(() => {
    if (!secondaryHeaderRef?.current) return;

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [secondaryHeaderRef]);

  function handleResize() {
    if (!secondaryHeaderRef?.current) return;
    setSecondaryHeaderWidth(secondaryHeaderRef.current.clientWidth);
  }

  const columns = !!props.headerSlots?.slot3;
  const secondRow = !!props.headerSlots?.slot2;
  return (
    <Box
      sx={{
        display: 'grid',
        background: 'white',

        gridTemplateColumns: {
          xs: columns ? '2fr 1fr' : '1fr',
        },
        borderBottom: secondRow ? `1px solid ${grey[300]}` : 'none',
        height: secondRow ? '13rem' : 'auto',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: secondRow ? '1fr 1fr' : '1fr',
        }}
      >
        {props.headerSlots?.slot1 && (
          <ToolbarRow sx={{ borderBottom: `1px solid ${grey[300]}` }}>
            {props.headerSlots.slot1}
          </ToolbarRow>
        )}

        {props.headerSlots?.slot2 && (
          <ToolbarRow>{props.headerSlots.slot2}</ToolbarRow>
        )}
      </Box>
      {(props.headerSlots?.slot3 || props.headerSlots?.slot4) && (
        <Box
          ref={secondaryHeaderRef}
          sx={{
            display: 'grid',
            gridTemplateRows: '1fr 1fr',
            background: '#E5F3FB',
            width: '100%',
            minWidth: secondaryHeaderWidth,
          }}
        >
          {props.headerSlots?.slot3 && (
            <ToolbarRow sx={{ borderBottom: `1px solid ${grey[300]}` }}>
              {props.headerSlots?.slot3}
            </ToolbarRow>
          )}
          {props.headerSlots?.slot4 && (
            <ToolbarRow>{props.headerSlots.slot4}</ToolbarRow>
          )}
        </Box>
      )}
    </Box>
  );
};

export default TableToolbar;
