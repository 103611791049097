import React from 'react';

interface CircularProgressProps {
  size?: 'sm' | 'md' | 'lg';
  color?: 'primary' | 'neutral' | 'soft';
}

const CircularProgress = ({
  size = 'md',
  color = 'primary',
}: CircularProgressProps) => {
  // Map sizes to dimensions and stroke widths
  const sizeMap = {
    sm: { diameter: 18, strokeWidth: 1.5 },
    md: { diameter: 36, strokeWidth: 3 },
    lg: { diameter: 72, strokeWidth: 6 },
  };

  // Map color schemes to TailwindCSS classes
  const colorMap = {
    primary: 'text-blue-500',
    neutral: 'text-gray-400',
    soft: 'text-indigo-300',
  };

  const { diameter, strokeWidth } = sizeMap[size] || sizeMap['md'];
  const strokeColor = colorMap[color] || colorMap['primary'];
  const radius = (diameter - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;

  return (
    <div
      className="relative flex items-center justify-center animate-spin"
      style={{ width: diameter, height: diameter }}
    >
      <svg
        className="block"
        width={diameter}
        height={diameter}
        viewBox={`0 0 ${diameter} ${diameter}`}
      >
        <circle
          cx={diameter / 2}
          cy={diameter / 2}
          r={radius}
          fill="none"
          stroke="currentColor"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={circumference * 0.25}
          className={strokeColor}
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default CircularProgress;
