import { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import GoogleMapReact from 'google-map-react';
import MapMarker from './components/MapMarker';
import useSupercluster from 'use-supercluster';
import { COLOR_MAP } from 'src/utils/constants/scss-variables.constants';
import mapStyles from './map-styles.json';
import { DRAW_MODES, MAX_ZOOM, MAX_WINDOW_WIDTH } from './constants';
import { useDiscoveryMap } from 'src/context';
import HoverData from './components/HoverData';
import { useWindowSize } from 'src/utils/hooks/useWindowSize';

export const getAllPolygonBounds = ({ polygons, maps }) => {
  const bounds = new maps.LatLngBounds();
  _.forEach(polygons, (polygon) => {
    const paths = polygon.getPaths();
    // forEach are defined methods, no replaceable by lodash
    paths?.forEach((path) => {
      // forEach are defined methods, no replaceable by lodash
      path?.forEach((latLng) => {
        bounds.extend(latLng);
      });
    });
  });
  return bounds;
};

interface MapProps {
  points;
  mapZoomCallback?;
  center?;
  disableClusters?: boolean;
  onAddToList?;
  showCreateTools?: boolean;
  containToTerritories?: boolean;
  normalizedProspectListData?;
  hoveredProviderId?;
  isProfileMap?: boolean;
}

const Map: React.FC<MapProps> = ({
  points,
  mapZoomCallback,
  center,
  disableClusters = false,
  onAddToList = null,
  showCreateTools = false,
  containToTerritories = false,
  normalizedProspectListData = null,
  hoveredProviderId = null,
  children = null,
  isProfileMap = false,
}) => {
  const mapRef = useRef<{ map; maps }>(null);
  const {
    addTempTerritoryCoordinates,
    editingTerritory,
    createNewTerritoryMode,
    selectedTerritories,
    existingTerritories,
  } = useDiscoveryMap();

  const { width } = useWindowSize();
  const MIN_ZOOM = width > MAX_WINDOW_WIDTH ? 5 : 4;
  // drawingModeReference utilized for eventListners on GeoJSON data layers of Google Maps

  const defaultCenter =
    center && !_.isArray(center)
      ? center
      : {
          lat: 37.0902,
          lng: -95.7129,
        };

  const [mapAvailable, setMapAvailable] = useState(false);
  const [zoom, setZoom] = useState(center ? 13 : MIN_ZOOM);
  const [bounds, setBounds] = useState(null);
  const [clusterBounds, setClusterBounds] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [addedTerritories, setAddedTerritories] = useState<{
    [id: string]: { polygons; id };
  }>(null);
  const [visiblePoints, setVisiblePoints] = useState([]);
  const [mapState, setMapState] = useState(null);
  const [hoverData, setHoverData] = useState(null);

  const updateOnMapMove = !editingTerritory && !createNewTerritoryMode;

  useEffect(() => {
    return () => {
      // on dismount ensure there aren't any errant polygon references on the map
      clearMap();
    };
  }, []);

  // This useEffect seems to handle the overlay of the territory on the map
  useEffect(() => {
    if (!mapAvailable) return;
    territoryHydration();
    // set any temp territories
    handleExistingTerritoriesDisplay();
  }, [mapAvailable, existingTerritories]);

  // This useEffect seems to handle the points on the map
  useEffect(() => {
    if (points && mapAvailable && updateOnMapMove) {
      const currentMapBounds = mapRef.current.map.getBounds();
      const temp = [];
      const tempAddedToMapById = {};
      const visiblePointsBounds = new mapRef.current.maps.LatLngBounds();

      _.forEach(points, (point) => {
        const lat = parseFloat(point?.lat);
        const lng = parseFloat(point?.lng);
        // if lat and lng are not valid do not add them as visible points
        if (!lat || !lng) return;

        const latLng = new mapRef.current.maps.LatLng({ lat, lng });
        const isVisibleOnMapBounds = currentMapBounds?.contains(latLng);

        if (!isVisibleOnMapBounds || tempAddedToMapById[point.id]) return;

        const ui_prospectLists =
          _.get(normalizedProspectListData, point.provider_id) || [];

        if (_.isEmpty(addedTerritories)) {
          tempAddedToMapById[point.id] = true;
          temp.push({ ...point, ui_prospectLists });
        }

        _.forEach(addedTerritories, ({ polygons }) => {
          if (tempAddedToMapById[point.id]) return;

          const territoryContainsLocation = _.some(polygons, (poly) => {
            return mapRef.current.maps.geometry.poly.containsLocation(
              latLng,
              poly
            );
          });
          if (!containToTerritories || territoryContainsLocation) {
            tempAddedToMapById[point.id] = true;
            temp.push({ ...point, ui_prospectLists });
          }
        });

        // Extend the bounds to include this point
        visiblePointsBounds.extend(latLng);
      });

      setVisiblePoints(temp);

      // This tells the map to fit the bounds of the visible points
      if (isProfileMap) {
        mapRef.current.map.fitBounds(visiblePointsBounds);
      }
    }
  }, [points, mapAvailable, mapState, addedTerritories, updateOnMapMove]);

  // This handles the territories in the RepsTerritoriesAndLists
  function handleExistingTerritoriesDisplay() {
    const tempTerritories = existingTerritories.filter(
      (territory) => territory.id !== editingTerritory?.id
    );
    // tempTerritories to a new layer UNDER the editing territory
    if (tempTerritories.length > 0) {
      const tempTerritoriesAddedById: { [id: string]: { polygons; id } } = {};

      _.forEach(tempTerritories, (territory) => {
        const isNew = !!territory?.polygons;
        const paths = isNew ? territory?.polygons : territory?.old_polygons;

        const isMultiPolygon = Array.isArray(paths?.[0]?.[0]);

        if (_.isEmpty(paths)) return;

        const notAddedToTempList = !tempTerritoriesAddedById[territory.id];
        const previouslyVisibleTerritory = addedTerritories?.[territory.id];
        if (previouslyVisibleTerritory) {
          _.forEach(previouslyVisibleTerritory?.polygons, (poly) => {
            poly.setMap(null);
          });
        }
        if (notAddedToTempList) {
          const isCurrentEditingTerritory =
            editingTerritory?.id === territory.id;
          const polygonColor = isCurrentEditingTerritory
            ? COLOR_MAP['blue-dark']
            : COLOR_MAP['gray-dark'];

          const territoryWithPolygon = {
            ...territory,
            polygons: isMultiPolygon
              ? _.map(paths, (path) => {
                  return new mapRef.current.maps.Polygon({
                    paths: path,
                    strokeColor: territory.color || polygonColor,
                    strokeOpacity: 0.8,
                    strokeWeight: 3,
                    fillColor: territory.color || polygonColor,
                    fillOpacity: 0.25,
                    editable: isCurrentEditingTerritory,
                    zIndex: isCurrentEditingTerritory ? 10 : 1,
                    // provide the correct feedback to the user in both edit and non editable mode
                    clickable: false,
                  });
                })
              : [
                  new mapRef.current.maps.Polygon({
                    // array of { lat, lng }
                    paths,
                    strokeColor: territory.color || polygonColor,
                    strokeOpacity: 0.8,
                    strokeWeight: 3,
                    fillColor: territory.color || polygonColor,
                    fillOpacity: 0.25,
                    editable: isCurrentEditingTerritory,
                    zIndex: isCurrentEditingTerritory ? 10 : 1,
                    // provide the correct feedback to the user in both edit and non editable mode
                    clickable: false,
                  }),
                ],
          };

          tempTerritoriesAddedById[territory.id] = territoryWithPolygon;
          _.forEach(territoryWithPolygon.polygons, (poly) => {
            mapRef.current.maps.event.addListener(
              poly,
              'mouseup',
              function (evt) {
                if (!_.isNil(evt.vertex) || !_.isNil(evt.path)) {
                  const updatedCoordinates = _.map(
                    territoryWithPolygon.polygons,
                    (relatedPoly) => {
                      const tempCoords = [];
                      // forEach is a defined method, not replaceable by lodash
                      relatedPoly.getPaths().forEach((path) => {
                        // forEach is a defined method, not replaceable by lodash
                        path.forEach((latLng) => {
                          tempCoords.push([latLng.lng(), latLng.lat()]);
                        });
                      });

                      return tempCoords;
                    }
                  );
                  addTempTerritoryCoordinates({
                    items: updatedCoordinates,
                  });
                }
              }
            );
            poly.setMap(mapRef.current.map);
          });
        }
      });

      setAddedTerritories((prevAddedTerritories) => {
        // clear existing polygons that are no longer active
        _.forEach(prevAddedTerritories, (territory) => {
          if (!tempTerritoriesAddedById[territory.id]) {
            _.forEach(territory.polygons, (poly) => {
              poly.setMap(null);
            });
          }
        });

        return tempTerritoriesAddedById;
      });

      // I don't think we need this.
      if (!_.isEmpty(tempTerritoriesAddedById)) {
        const allPolygonBounds = getAllPolygonBounds({
          polygons: _.flatten(
            _.map(tempTerritoriesAddedById, (territory) => {
              return territory.polygons;
            })
          ),
          maps: mapRef.current.maps,
        });

        mapRef.current.map.fitBounds(allPolygonBounds);
      }
    }
  }

  function territoryHydration() {
    if (!mapAvailable) return;
    const tempTerritoriesAddedById: { [id: string]: { polygons; id } } = {};

    _.forEach(selectedTerritories, (territory) => {
      const isNew = !!territory?.polygons;
      const paths = isNew ? territory?.polygons : territory?.old_polygons;
      const isMultiPolygon = Array.isArray(paths?.[0]?.[0]);

      if (_.isEmpty(paths)) return;

      const notAddedToTempList = !tempTerritoriesAddedById[territory.id];
      const previouslyVisibleTerritory = addedTerritories?.[territory.id];
      if (previouslyVisibleTerritory) {
        _.forEach(previouslyVisibleTerritory?.polygons, (poly) => {
          poly.setMap(null);
        });
      }
      if (notAddedToTempList) {
        const isCurrentEditingTerritory = editingTerritory?.id === territory.id;
        const polygonColor = isCurrentEditingTerritory
          ? COLOR_MAP['blue-dark']
          : COLOR_MAP['gray-dark'];

        const territoryWithPolygon = {
          ...territory,
          polygons: isMultiPolygon
            ? _.map(paths, (path) => {
                return new mapRef.current.maps.Polygon({
                  paths: path,
                  strokeColor: territory.color || polygonColor,
                  strokeOpacity: 0.8,
                  strokeWeight: 3,
                  fillColor: territory.color || polygonColor,
                  fillOpacity: 0.25,
                  editable: isCurrentEditingTerritory,
                  zIndex: isCurrentEditingTerritory ? 10 : 1,
                  // provide the correct feedback to the user in both edit and non editable mode
                  clickable: false,
                });
              })
            : [
                new mapRef.current.maps.Polygon({
                  // array of { lat, lng }
                  paths,
                  strokeColor: territory.color || polygonColor,
                  strokeOpacity: 0.8,
                  strokeWeight: 3,
                  fillColor: territory.color || polygonColor,
                  fillOpacity: 0.25,
                  editable: isCurrentEditingTerritory,
                  zIndex: isCurrentEditingTerritory ? 10 : 1,
                  // provide the correct feedback to the user in both edit and non editable mode
                  clickable: false,
                }),
              ],
        };

        tempTerritoriesAddedById[territory.id] = territoryWithPolygon;
        _.forEach(territoryWithPolygon.polygons, (poly) => {
          mapRef.current.maps.event.addListener(
            poly,
            'mouseup',
            function (evt) {
              if (!_.isNil(evt.vertex) || !_.isNil(evt.path)) {
                const updatedCoordinates = _.map(
                  territoryWithPolygon.polygons,
                  (relatedPoly) => {
                    const tempCoords = [];
                    // forEach is a defined method, not replaceable by lodash
                    relatedPoly.getPaths().forEach((path) => {
                      // forEach is a defined method, not replaceable by lodash
                      path.forEach((latLng) => {
                        tempCoords.push([latLng.lng(), latLng.lat()]);
                      });
                    });

                    return tempCoords;
                  }
                );

                addTempTerritoryCoordinates({ items: updatedCoordinates });
              }
            }
          );
          poly.setMap(mapRef.current.map);
        });
      }
    });

    setAddedTerritories((prevAddedTerritories) => {
      // clear existing polygons that are no longer active
      _.forEach(prevAddedTerritories, (territory) => {
        if (!tempTerritoriesAddedById[territory.id]) {
          _.forEach(territory.polygons, (poly) => {
            poly.setMap(null);
          });
        }
      });

      return tempTerritoriesAddedById;
    });

    if (!_.isEmpty(tempTerritoriesAddedById)) {
      const allPolygonBounds = getAllPolygonBounds({
        polygons: _.flatten(
          _.map(tempTerritoriesAddedById, (territory) => {
            return territory.polygons;
          })
        ),
        maps: mapRef.current.maps,
      });

      mapRef.current.map.fitBounds(allPolygonBounds);
    }
  }

  function clearMap() {
    _.forEach(addedTerritories, (territory) => {
      _.forEach(territory.polygons, (poly) => {
        poly.setMap(null);
      });
    });
  }

  const onChange = (state) => {
    if (!state) return;
    setZoom(state.zoom);

    setBounds([
      [state.bounds.nw.lat, state.bounds.nw.lng],
      [state.bounds.se.lat, state.bounds.se.lng],
    ]);
    // Cluster bounds are in a different format than the bounds for territory data
    // TODO: Refactor to use the same format, need to talk to Jim about this
    setClusterBounds([
      state.bounds.nw.lng,
      state.bounds.se.lat,
      state.bounds.se.lng,
      state.bounds.nw.lat,
    ]);

    setMapState(state);

    // React was not happy with this inside of setMapState callback
    !!mapZoomCallback && mapZoomCallback(state);
  };

  // Take all of the points on our map and split them into clusters
  const clusterObjects = _.map(visiblePoints, (point) => {
    return {
      details: point,
      properties: {
        cluster: false,
        id: point.id,
        count: point.count,
        provider_id: point.provider_id,
        hovered: `${hoveredProviderId}` === `${point.provider_id}`,
      },
      geometry: {
        type: 'Point',
        coordinates: [point.lng, point.lat],
      },
    };
  });

  // Initialize the clusters
  let radius = 75;
  if (disableClusters || zoom >= MAX_ZOOM) {
    radius = 0;
  } else if (zoom < MAX_ZOOM && zoom > 14) {
    radius = 25;
  } else if (zoom < 11 && zoom > 8) {
    radius = 50;
  }

  const { clusters, supercluster } = useSupercluster({
    points: clusterObjects,
    bounds: clusterBounds,
    zoom,
    options: {
      clickableIcons: false,
      radius,
      maxZoom: MAX_ZOOM,
      map: (props) => {
        return {
          provider_id: props.provider_id || props.id,
          hovered: props.hovered,
          count: props.count,
        };
      },
      reduce: (acc, props) => {
        acc.hovered = acc.hovered || props.hovered;
        if (!acc.count) acc.count = 1;

        if (!props.count) props.count = 1;

        acc.count += props.count;
        return acc;
      },
    },
  });

  // Zoom and expand to a level that automatically breaks the clusters apart when one is clicked on
  const clusterClick = (clusterId, lat, lng) => {
    const zoomLevel = supercluster.getClusterExpansionZoom(clusterId);
    const expansionZoom = isNaN(zoomLevel)
      ? Math.min(zoom + 2, MAX_ZOOM)
      : zoomLevel;

    if (expansionZoom >= MAX_ZOOM && zoom === expansionZoom - 1) {
      // When we have hit max zoom and a cluster is clicked on w/ the same zoom level
      // Focus the marker, to display info for any locations under the cluster
      // maintain toggle functionality
      setActiveMarker(activeMarker === clusterId ? null : clusterId);
    } else {
      // Not at max zoom for cluster, zoom to attempt showing more fine grained markers
      // Remove any previously focused marker
      mapRef.current.map.panTo({ lat, lng });
      mapRef.current.map.setZoom(expansionZoom);
      setActiveMarker(null);
    }
  };

  // Callback for when a marker is clicked
  // If the one that's clicked is the active one, deactivate it to hide the info box
  const markerClick = (markerId: string) => {
    const marker = markerId === activeMarker ? null : markerId;
    setActiveMarker(marker);
  };

  const MarkerSet = clusters.map((cluster, index) => {
    const [lng, lat] = cluster.geometry.coordinates;
    const { cluster: isCluster, count } = cluster.properties;
    const showClusterMarkerInfo = isCluster && zoom === MAX_ZOOM;

    if (isCluster) {
      return (
        <MapMarker
          id={cluster.id}
          key={`cluster_${cluster.id}_${index}`}
          type={'cluster'}
          lat={lat}
          lng={lng}
          isHovered={cluster.properties.hovered}
          clusterLeaves={
            showClusterMarkerInfo && supercluster.getLeaves(cluster.id, 200)
          }
          pointCount={count}
          onClick={clusterClick}
          onAddToList={onAddToList}
        />
      );
    }

    return (
      <MapMarker
        type={'point'}
        id={cluster.details.provider_id}
        key={`point_${cluster.details.provider_id}_${index}`}
        lat={lat}
        lng={lng}
        onClick={markerClick}
        details={cluster.details}
        pointCount={count}
        isHovered={cluster.properties.hovered || cluster.details.hovered}
        onAddToList={onAddToList}
      />
    );
  });

  return (
    <>
      <div style={{ position: 'relative', height: '100%', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY,
            libraries: ['places', 'geometry', 'drawing'],
          }}
          defaultCenter={defaultCenter}
          defaultZoom={MIN_ZOOM}
          zoom={zoom}
          onChange={onChange}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            if (!map || !maps) return;

            mapRef.current = { map, maps };

            // on initialization if the center is an array of points, center the map initially on all points
            if (_.isArray(center)) {
              const bounds = new maps.LatLngBounds();
              _.forEach(center, (centeredMarker) => {
                bounds.extend({
                  lat: centeredMarker.lat,
                  lng: centeredMarker.lng,
                });
              });
              map.fitBounds(bounds);
            }
            setMapAvailable(true);
          }}
          options={{
            styles: showCreateTools || editingTerritory ? mapStyles : null,
            scrollwheel: true,
            minZoom: MIN_ZOOM,
            maxZoom: MAX_ZOOM,
            fullscreenControl: false,
            zoomControlOptions: {
              position: global.google?.maps?.ControlPosition?.RIGHT_TOP || 3,
            },
          }}
        >
          {updateOnMapMove && MarkerSet}
        </GoogleMapReact>
        {children}
      </div>
      {hoverData && <HoverData data={hoverData} />}
    </>
  );
};

export default Map;
