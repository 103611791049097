import React from 'react';
import { Box, Button, Typography, Tooltip } from '@mui/joy';
import { styled } from '@mui/material';
import { TypeIcon, AddToListDialog } from 'src/components';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faExternalLinkAlt,
  faPlus,
  faQuestionCircle,
  faCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { useLogging, useNotification } from 'src/context';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { addItemsToList } from 'src/hooks';

const RowContainer = styled(Box)({
  display: 'flex',
  height: 'var(--8, 4.25rem)',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  alignSelf: 'stretch',
  borderRadius: 'var(--radius-md, 0.5rem)',
  border: '1px solid var(--neutral-outlined-Border, #CDD7E1)',
  background: 'var(--background-surface, #FBFCFE)',
  overflow: 'hidden',
});

const LeftSideContainer = styled(Box)({
  display: 'flex',
  position: 'relative',
  flex: 1,
  alignItems: 'center',
  height: '100%',
  '&:hover .hover-button': {
    opacity: 1,
    pointerEvents: 'auto',
  },
  '&:hover .left-side': {
    background: 'var(--neutral-outlined-HoverBg, #F0F4F8)',
  },
});

const LeftSide = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  height: '100%',
  padding: '0 var(--2, 1rem)',
  cursor: 'pointer',
  flex: '1 0 0',
});

const RightSide = styled(Box)({
  display: 'flex',
  height: '100%',
  padding: '0 1rem',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-end',
  backgroundColor: '#D6F6F1',
  // had to play around with this value to get the right alignment for longer values
  flex: '0.2 0 0',
});

const HoverButton = styled(Button)({
  position: 'absolute',
  top: '50%',
  right: '16px', // Adjust the position as needed
  transform: 'translateY(-50%)',
  opacity: 0,
  pointerEvents: 'none',
  transition: 'opacity 0.3s ease-in-out',
  boxShadow: '0px 1px 2px 0px rgba(21, 21, 21, 0.08)',
  background: 'var(--background-surface, #FBFCFE)',
});

const StyledLink = styled('a')({
  display: 'flex',
  alignItems: 'center',
  color: '#021B9C',
});

const MetaTypography = styled(Typography)({
  color: 'var(--text-tertiary, #555E68)',
  fontSize: '0.75rem',
  lineHeight: '1.095rem',
  fontWeight: 500,
});

const MetaContainer = styled(Box)({
  display: 'flex',
  gap: '0.5rem',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
});

interface LocationRowItemProps {
  location: {
    provider_id: string;
    type: string;
    name: string;
    claims: string;
    percentage: number;
    obfuscated: boolean;
    specialty: string;
    city: string;
    state: string;
  };
}

const CardLocationRow = ({ location }: LocationRowItemProps) => {
  const log = useLogging();
  const { setNotification } = useNotification();
  const { sameTabRouting } = useFlags();
  const [showProviderDialog, setShowProviderDialog] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const percentage =
    location?.percentage || location?.percentage === 0
      ? `${location?.percentage}%`
      : null;

  const name = location?.name || 'N/A';
  const isLessThanEleven = location?.claims && location?.claims === '<11';
  const providers = [location];
  const specialty = location?.specialty || 'N/A';
  const cityState =
    location.city && location.state
      ? `${location?.city}, ${location?.state}`
      : 'N/A';

  const handleAddProvider = async (listId: number) => {
    if (!listId) return;
    setIsLoading(true);
    try {
      await addItemsToList(listId, [{ ...location }]);
      log.event('addProvider', {
        source: 'ProfileSummary',
        count: providers?.length,
      });

      setNotification({
        title: 'Providers added',
        message: `Added ${providers?.length} prospects to the list.`,
        type: 'success',
      });
    } catch (err) {
      log.exception('addProvider', {
        tags: {
          source: 'ProfileSummary',
          count: providers?.length,
          error: err.message,
        },
      });

      setNotification({
        title: 'Error',
        message:
          err.message ||
          'An error occurred while adding providers to the list.',
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <RowContainer>
        <LeftSideContainer
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          <LeftSide className="left-side">
            <TypeIcon
              type={location.type}
              size="lg"
              style={{
                marging: '0',
                color: '#021B9C',
              }}
            />

            <Box sx={{ height: '100%', width: '100%', alignContent: 'center' }}>
              <StyledLink
                href={`/profiles/${location?.type}/${location?.provider_id}`}
                target={sameTabRouting ? '_self' : '_blank'}
                rel="noopener noreferrer"
                onClick={(e) => {
                  e.stopPropagation();
                  log.event('Location Clicked', {
                    source: 'web',
                    location: 'VolumeByLocation',
                    provider_id: location?.provider_id,
                  });
                }}
              >
                <Typography
                  level="body-sm"
                  sx={{ color: 'inherit', fontWeight: 500 }}
                >
                  {name}
                  <Icon
                    icon={faExternalLinkAlt}
                    style={{ paddingLeft: '.25rem' }}
                    size="xs"
                  />
                </Typography>
              </StyledLink>
              <MetaContainer>
                <MetaTypography
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '12.5rem',
                  }}
                >
                  {specialty || 'n/a'}
                </MetaTypography>
                <Icon
                  icon={faCircle}
                  style={{
                    fontSize: '6px',
                  }}
                  color="#D9D9D9"
                />
                <MetaTypography
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '9rem',
                  }}
                >
                  {cityState || 'n/a'}
                </MetaTypography>
              </MetaContainer>
            </Box>
          </LeftSide>
          <HoverButton
            loading={isLoading}
            className="hover-button"
            variant="outlined"
            color="neutral"
            startDecorator={<Icon icon={faPlus} />}
            onClick={() => setShowProviderDialog(true)}
          >
            Add
          </HoverButton>
        </LeftSideContainer>
        <RightSide>
          <Tooltip
            variant="soft"
            size="md"
            color="neutral"
            title="Data can be any number 1 to 10"
            placement="top"
            arrow
            disableFocusListener={!isLessThanEleven}
            disableHoverListener={!isLessThanEleven}
            disableTouchListener={!isLessThanEleven}
            disableInteractive={!isLessThanEleven}
          >
            <Typography
              level="body-md"
              style={{
                color: '#005B38',
                fontWeight: 600,
                cursor: isLessThanEleven ? 'pointer' : 'default',
              }}
            >
              {isLessThanEleven
                ? location?.claims
                : Number(location?.claims)?.toLocaleString()}
            </Typography>
          </Tooltip>
          <Tooltip
            variant="soft"
            size="md"
            color="neutral"
            title="of total Site volume"
            placement="top"
            arrow
          >
            {percentage ? (
              <Typography
                level="body-md"
                style={{
                  color: '#005B38',
                  cursor: 'pointer',
                  fontWeight: 400,
                }}
              >
                {percentage}
              </Typography>
            ) : (
              <Typography level="body-md">
                <Icon icon={faQuestionCircle} size="sm" color="#636B74" />
              </Typography>
            )}
          </Tooltip>
        </RightSide>
      </RowContainer>
      <AddToListDialog
        open={showProviderDialog}
        onClose={() => setShowProviderDialog(false)}
        onAddProvider={handleAddProvider}
      />
    </>
  );
};

export default CardLocationRow;
