import { useQuery } from 'react-query';
import { INTEGRATION_KEYS } from './constants';
import { getCRMStatuses } from './integrationsApi';

const useGetAllStatuses = () => {
  return useQuery([INTEGRATION_KEYS.GET_ALL_STATUSES], () => getCRMStatuses(), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};

export default useGetAllStatuses;
