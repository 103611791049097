import React, { useState, useRef, useEffect, useMemo } from 'react';
import {
  Dropdown,
  Menu,
  MenuItem,
  MenuButton,
  Switch,
  IconButton,
} from '@mui/joy';
import ViewWeekRoundedIcon from '@mui/icons-material/ViewWeekRounded';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTable } from 'src/context';
import { useOnClickOutside } from 'src/hooks';
import { VOLUME_SELECT_OPTIONS } from 'src/components/VolumeSelectors/constants';

const GridColumnMenu = ({
  columns = [],
  columnVisibilityModel,
  setColumnVisibilityModel,
}) => {
  const theme = useTheme();
  const menuRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const [anchorEl, setAnchorEl] = useState(null);

  const { showIcon } = useTable();

  useEffect(() => {
    if (!anchorEl) return;
    setAnchorEl(null);
  }, [isMobile]);

  const handleChange = (field) => {
    setColumnVisibilityModel((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (e, field) => {
    handleChange(field);
  };

  useOnClickOutside(menuRef, anchorEl, handleClose);

  // reorder columns putting the visible ones first
  const visibleColumns = useMemo(() => {
    if (!anchorEl) return [];
    // remove prospectSearch column from the list, its desplayed as volume
    // Remove all options columns from the list
    const newColumns = columns.filter(
      (column) =>
        !VOLUME_SELECT_OPTIONS.map((option) => option.value).includes(
          column.field.split('_')[1]
        )
    );

    return newColumns.sort((a, b) => {
      if (columnVisibilityModel[a.field] && !columnVisibilityModel[b.field]) {
        return -1;
      }
      if (!columnVisibilityModel[a.field] && columnVisibilityModel[b.field]) {
        return 1;
      }
      return 0;
    });
  }, [anchorEl]);

  return (
    <Dropdown>
      {isMobile || showIcon ? (
        <IconButton
          onClick={handleClick}
          variant="soft"
          color="neutral"
          sx={{
            fontWeight: 900,
          }}
          aria-label="Columns Menu"
        >
          <ViewWeekRoundedIcon fontSize="small" />
        </IconButton>
      ) : (
        <MenuButton
          onClick={handleClick}
          startDecorator={<ViewWeekRoundedIcon fontSize="small" />}
          variant="soft"
          color="neutral"
          sx={{
            fontWeight: 900,
          }}
          aria-label="Columns Menu"
        >
          Columns
        </MenuButton>
      )}
      <Menu
        placement="bottom-end"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        sx={{
          boxShadow: 'sm',
          flexGrow: 0,
          maxHeight: 300,
          overflow: 'auto',
        }}
        ref={menuRef}
      >
        {visibleColumns?.map((column) => (
          <MenuItem
            key={`column_${column.field}`}
            sx={{
              display: 'flex',
              gap: '0.25rem',
              fontSize: '1rem',
              fontWeight: 500,
              padding: '0.5rem 1rem',
            }}
            onClick={(e) => handleMenuItemClick(e, column.field)}
          >
            <Switch
              size="sm"
              checked={!!columnVisibilityModel[column.field]}
              color={
                !!columnVisibilityModel[column.field] ? 'success' : 'neutral'
              }
              variant={'outlined'}
            />
            {column.headerName}
          </MenuItem>
        ))}
      </Menu>
    </Dropdown>
  );
};

export default GridColumnMenu;
