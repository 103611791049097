import React, { useMemo } from 'react';
import { Drawer } from '@mui/material';
import { Box, Typography, Select, Option, CircularProgress } from '@mui/joy';
import { styled } from '@mui/material';

import { ProfileBladeHeader } from './components';
import { OldProfileProvider, useSite } from 'src/context';
import { useGetProfileDetails } from 'src/api/profiles/profiles';
import { ActivityList } from 'src/components';
import NewProviderProfile from 'src/components/Profiles/NewProviderProfile';
import CenterProfile from 'src/components/Profiles/CenterProfile';
import ClinicProfile from 'src/components/Profiles/ClinicProfile';

import { ActivitySelectOption } from 'src/components/Activity';
import { useGetEntityActivities, useGetActivityTypes } from 'src/hooks';

const Tabs = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: 'var(--none, 0px) var(--1, 0.5rem) 0px var(--1, 0.5rem)',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 'var(--2, 1rem)',
  alignSelf: 'stretch',
  borderTop: '1px solid var(--neutral-outlined-Border, #CDD7E1)',
  borderBottom: '1px solid var(--neutral-outlined-Border, #CDD7E1)',
  background: 'var(--common-white, #FFF)',
}));

const TabList = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '56px',
  alignItems: 'flex-end',
  gap: 'var(--1, 0.5rem)',
}));

const Tab = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '56px',
  padding: 'var(--none, 0px) var(--05, 0.25rem)',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 'var(--05, 0.25rem)',
  cursor: 'pointer',
}));

const TabContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected: boolean }>(({ theme, isSelected }) => ({
  display: 'flex',
  padding: 'var(--05, 0.25rem) var(--1, 0.5rem)',
  alignItems: 'center',
  gap: 'var(--05, 0.25rem)',
  borderRadius: 'var(--radius-sm, 0.375rem)',
  backgroundColor: isSelected ? '#DDE7EE' : 'transparent',
  '&:hover': {
    background: 'var(--neutral-plain-HoverBg, #F0F4F8)',
  },
}));

const TabTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected: boolean }>(({ theme, isSelected }) => ({
  color: isSelected
    ? 'var(--Neutral-800, #2C2E2F)'
    : 'var(--Neutral-600, #5D5E5F)',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '1rem',
  fontStyle: 'normal',
  fontWeight: isSelected ? 500 : 400,
  lineHeight: '1.75rem' /* 175% */,
}));

const TabPanel = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: '1 0 0',
  alignSelf: 'stretch',
  height: 'calc(100% - 4rem)',
  overflowY: 'auto',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}));

const TabControls = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  left: 0,
  display: 'flex',
  padding: '1rem var(--2, 1rem)',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  borderBottom: '1px solid var(--Neutral-200, #DDDFE0)',
  background: 'var(--common-white_states-main, #FFF)',
}));

const TabPanelContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: 'var(--2, 1rem)',
  flexDirection: 'column',
  alignItems: 'flex-end',
  gap: '1rem',
  alignSelf: 'stretch',
  flex: '1 0 0',
}));

const ProfileBlade = () => {
  const { state, dispatch } = useSite();

  const [selectedTab, setSelectedTab] = React.useState(0);
  const [activity, setActivity] = React.useState({
    label: 'All Activity',
    value: null,
  });

  const { data: activityTypes } = useGetActivityTypes();

  const { data, isLoading, isError, refetch } = useGetProfileDetails({
    id: state.profile.provider?.id,
    contentType: state.profile.provider?.type,
    shouldRefetch: false,
  });

  const { isLoading: loadingActivities } = useGetEntityActivities(
    data?.provider_id
  );

  const activityData = useMemo(() => {
    if (!activityTypes?.results) return [];
    return activityTypes?.results?.map((item) => ({
      label: item.label,
      value: item.id,
      ...item,
    }));
  }, [activityTypes]);

  const handleActivityChange = (e, activity: string) => {
    if (!activity) {
      setActivity({
        label: 'All Activity',
        value: null,
      });

      dispatch({
        type: 'SET_QUERY_PARAMS',
        payload: {
          activities: [],
        },
      });
    } else {
      const newActivity = activityData?.find((item) => item.value === activity);
      setActivity(newActivity);

      dispatch({
        type: 'SET_QUERY_PARAMS',
        payload: {
          activities: [newActivity?.id],
        },
      });
    }
  };

  const handleClose = () => {
    dispatch({ type: 'CLOSE_PROFILE_DRAWER' });
  };

  const contentType = data?.details?.content_type;
  return (
    <Drawer
      anchor="right"
      open={state.profile.profileDrawerOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: '500px',
          top: '3.4rem',
          bottom: 0,
          height: 'auto',
          borderRadius: '0.75rem 0px 0px 0.75rem',
          position: 'absolute',
        },
      }}
    >
      <OldProfileProvider value={{ ...data, refetch }}>
        <ProfileBladeHeader />
        <Box sx={{ overflow: 'hidden', flexGrow: 1 }}>
          <Tabs>
            <TabList>
              <Tab
                onClick={() => setSelectedTab(0)}
                style={{
                  borderBottom:
                    selectedTab === 0
                      ? '4px solid var(--Primary-700, #2391D9)'
                      : 'none',
                }}
              >
                <TabContainer isSelected={selectedTab === 0}>
                  <TabTypography isSelected={selectedTab === 0}>
                    Activity
                  </TabTypography>
                </TabContainer>
              </Tab>
              <Tab
                onClick={() => setSelectedTab(1)}
                style={{
                  borderBottom:
                    selectedTab === 1
                      ? '4px solid var(--Primary-700, #2391D9)'
                      : 'none',
                }}
              >
                <TabContainer isSelected={selectedTab === 1}>
                  <TabTypography isSelected={selectedTab === 1}>
                    Overview
                  </TabTypography>
                </TabContainer>
              </Tab>
            </TabList>
          </Tabs>
          <TabPanel
            style={{
              display: selectedTab === 0 ? 'flex' : 'none',
            }}
          >
            <TabControls>
              <Select
                value={activity?.value}
                onChange={handleActivityChange}
                slotProps={{
                  listbox: {
                    placement: 'bottom-start',
                    sx: {
                      zIndex: 99999,
                    },
                  },
                }}
                renderValue={(value) => {
                  const selectedActivity = activityData?.find(
                    (item) => item.value === value.value
                  );

                  return (
                    <ActivitySelectOption item={selectedActivity || activity} />
                  );
                }}
              >
                <Option value={null}>
                  <ActivitySelectOption
                    item={{
                      label: 'All Activity',
                      value: null,
                      id: null,
                      icon: '',
                      color: '',
                    }}
                  />
                </Option>
                {activityData?.map((item) => (
                  <Option key={item.value} value={item.value}>
                    <ActivitySelectOption item={item} />
                  </Option>
                ))}
              </Select>
            </TabControls>
            <TabPanelContainer>
              <ActivityList isFiltered={activity.value} />
            </TabPanelContainer>
          </TabPanel>
          <TabPanel
            style={{
              display: selectedTab === 1 ? 'flex' : 'none',
            }}
          >
            <TabPanelContainer>
              {contentType === 'hcp' && (
                <NewProviderProfile singleColumn={true} />
              )}
              {contentType === 'center' && (
                <CenterProfile singleColumn={true} />
              )}
              {contentType === 'clinic' && (
                <ClinicProfile singleColumn={true} />
              )}
            </TabPanelContainer>
          </TabPanel>
        </Box>
      </OldProfileProvider>
      {(isLoading || loadingActivities || !data) && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.3)',
          }}
        >
          <CircularProgress size="sm" />
        </Box>
      )}
    </Drawer>
  );
};

export default ProfileBlade;
