import React, {
  useState,
  ReactElement,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import {
  Box,
  Typography,
  Menu,
  Checkbox,
  Dropdown,
  CircularProgress,
} from '@mui/joy';
import { styled } from '@mui/material';
import { UnfoldMore } from '@mui/icons-material';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-solid-svg-icons';
import { useOnClickOutside } from 'src/hooks';
import FilterPill from '../FilterPill';
import { MoreOptionsMenu } from '../MoreOptionsMenu';
import { exists } from 'src/utils';

const MenuContainer = styled(Menu)({
  display: 'flex',
  minWidth: '15rem', //240px
  padding: 'var(--1, 0.5rem)',
  flexDirection: 'column',
  alignItems: 'flex-start',
  borderRadius: '0.5rem',
  border: '1px solid var(--neutral-outlined-Border, #CDD7E1)',
  background: 'var(--background-surface, #FBFCFE)',

  /* shadow-md */
  boxShadow:
    '0px 2px 8px -2px rgba(21, 21, 21, 0.08), 0px 6px 12px -2px rgba(21, 21, 21, 0.08)',
});

interface SegmentedMultiSelectMenuProps {
  filterHeader: string;
  optionType: string;
  optionData: { [key: string]: { label: string; id: string }[] };
  startDecorator: ReactElement;
  selectedOptions: { [key: string]: string[] };
  setSelectedOptions: any;
  onMenuScroll: (isActivity: boolean) => void;
  isLoading: boolean;
  expandedSections: any;
  setExpandedSections: any;
}

const SegmentedMultiSelectMenu = ({
  filterHeader,
  optionType,
  optionData,
  startDecorator,
  selectedOptions,
  setSelectedOptions,
  onMenuScroll,
  isLoading,
  expandedSections,
  setExpandedSections,
}: SegmentedMultiSelectMenuProps) => {
  const menuRef = useRef(null);
  const menuRef2 = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [allSelected, setAllSelected] = useState(false);

  const toggleSection = (header: string) => {
    setExpandedSections((prev) => ({
      ...prev,
      [header]: !prev[header],
    }));
  };

  const selectedOptionsCombinedLength = Object.values(selectedOptions).reduce(
    (acc, optionsArray) => acc + optionsArray.length,
    0
  );

  useEffect(() => {
    if (
      Object.keys(selectedOptions).length === Object.keys(optionData).length
    ) {
      // if all options are selected, change dropdown placeholder to All instead of filterpill
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [optionData?.length, selectedOptions?.length]);

  const handleOpenMenu = (e) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleMoreOptionsClick = (e) => {
    setAnchorEl2(anchorEl2 ? null : e.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
    setAnchorEl2(null); //if main menu closes ensure more option menu closes as well
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const selectAll = (e) => {
    e.stopPropagation();

    const allFormattedOptions = Object.fromEntries(
      Object.entries(optionData).map(([key, valueArray]) => [
        key,
        valueArray.map((item) => item.label),
      ])
    );

    setAllSelected(true);
    setSelectedOptions(optionType, allFormattedOptions);
    setAnchorEl2(null);
  };

  const deselectAll = (e) => {
    e.stopPropagation();
    setAllSelected(false);
    setSelectedOptions(optionType, {});
    setAnchorEl2(null);
  };

  const handleCheckboxChange = (header, e) => {
    e.stopPropagation();
    const value = e.target?.value;

    const formattedOptions = () => {
      const newStatuses = { ...selectedOptions };
      if (newStatuses[header]?.includes(value)) {
        newStatuses[header] = newStatuses[header].filter(
          (opt) => opt !== value
        );
      } else {
        newStatuses[header] = [...(newStatuses[header] || []), value];
      }
      return newStatuses;
    };

    setSelectedOptions(optionType, formattedOptions());
  };

  const filterPillOptions = Object.values(selectedOptions).reduce(
    (acc, optionsArray) => acc.concat(optionsArray),
    []
  );

  const singleFilterValue = () => {
    return filterPillOptions[0];
  };

  const isChecked = useMemo(
    () => (header, option) => {
      return (
        header in selectedOptions &&
        selectedOptions?.[header]?.includes(option.label)
      );
    },
    [selectedOptions]
  );

  const menuRefs = menuRef2 ? [menuRef, menuRef2] : menuRef;
  useOnClickOutside(menuRefs, anchorEl, handleClose1);
  useOnClickOutside(menuRef2, anchorEl2, handleClose2);

  // handles infinite scroll trigger
  const containerRef = useRef(null);

  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollTop + clientHeight >= scrollHeight) {
        onMenuScroll(false);
      }
    }
  };

  return (
    <Dropdown>
      <button
        onClick={handleOpenMenu}
        style={{
          gap: '.5rem',
          alignSelf: 'stretch',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0.375rem 0.5rem 0.375rem 0.75rem',
          width: '10rem' /* 160px */,
          height: '2rem' /* 32px */,
          borderRadius: '3rem',
          border: '1px solid var(--Neutral-200, #DDDFE0)',
          background: 'var(--background-body, #FFF)',
        }}
      >
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {startDecorator}
            {!allSelected && selectedOptionsCombinedLength > 1 ? (
              <FilterPill
                selectedOptions={filterPillOptions}
                handleClearFilters={deselectAll}
              />
            ) : (
              <Typography
                level="body-sm"
                color="neutral"
                sx={{
                  fontWeight: 400,
                  flex: '1 0 0',
                  textAlign: 'left',
                  color: 'var(--Neutral-800, #2C2E2F)',
                  marginLeft: '.5rem',
                  width: '6.25rem', //100px
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {!allSelected && selectedOptionsCombinedLength === 1
                  ? singleFilterValue()
                  : 'All'}
              </Typography>
            )}
            <UnfoldMore
              fontSize="small"
              sx={{
                fontWeight: 200,
                color: 'var(--neutral-600, #6B7280)',
              }}
            />
          </Box>
        </Box>
      </button>
      <MenuContainer
        ref={menuRef}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        placement="bottom-start"
        autoFocus={false}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0 0 0 var(--1, 0.5rem)',
            }}
          >
            <Typography level="body-sm" sx={{ fontWeight: 500 }}>
              {filterHeader}
            </Typography>
            <MoreOptionsMenu
              handleMoreOptionsClick={handleMoreOptionsClick}
              menuRef={menuRef2}
              anchorEl={anchorEl2}
              handleClose={handleClose2}
              selectAll={selectAll}
              deselectAll={deselectAll}
            />
          </Box>

          {exists(optionData) &&
            Object.entries(optionData).map(([header, options], i) => {
              return (
                <Box
                  key={header}
                  sx={{
                    padding: expandedSections[header]
                      ? '0.5rem 0.5rem 0 0.5rem'
                      : '0.5rem var(--1, 0.5rem)',
                    borderTop: '1px solid #DCDFE2',
                    marginTop: i === 0 ? '.5rem' : 0,
                  }}
                >
                  <Typography
                    level="body-xs"
                    sx={{
                      fontWeight: 700,
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                    onClick={() => toggleSection(header)}
                    endDecorator={
                      <Icon
                        icon={
                          expandedSections[header] ? faAngleUp : faAngleDown
                        }
                      />
                    }
                  >
                    {header}
                  </Typography>
                  {expandedSections[header] && (
                    <Box
                      ref={containerRef}
                      onScroll={handleScroll}
                      sx={{
                        maxHeight: '12.5rem' /* 200px */,
                        width: '100%',
                        overflowY: 'auto',
                        overflowX: 'auto',
                      }}
                    >
                      {(options as any[])
                        ?.sort((a, b) => {
                          const aChecked = selectedOptions[header]?.includes(
                            a.id
                          );
                          const bChecked = selectedOptions[header]?.includes(
                            b.id
                          );
                          return aChecked === bChecked ? 0 : aChecked ? -1 : 1;
                        })
                        .map((option) => {
                          return (
                            <Box
                              key={option.id}
                              sx={{
                                display: 'flex',
                                width: '100%',
                                margin:
                                  '0.375rem 0.5rem 0.375rem var(--1, 0.5rem)',
                                alignItems: 'center',
                                gap: '0.75rem',
                                height: '1.5rem',
                              }}
                            >
                              <Checkbox
                                variant="outlined"
                                color="neutral"
                                size="sm"
                                sx={{
                                  alignItems: 'center',
                                  whiteSpace: 'nowrap',
                                  maxWidth: '18.75rem', //300px
                                }}
                                label={option.label}
                                value={option.id}
                                onChange={(e) =>
                                  handleCheckboxChange(header, e)
                                }
                                checked={isChecked(header, option)}
                              />
                            </Box>
                          );
                        })}
                    </Box>
                  )}
                </Box>
              );
            })}
          {isLoading && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress size="sm" />
            </Box>
          )}
        </Box>
      </MenuContainer>
    </Dropdown>
  );
};

export default SegmentedMultiSelectMenu;
