import useSWR from 'swr';
import { getMedMapSearchResults } from './medscoutmapapi';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { QUERY_KEY } from './constants';
import {
  useMedScoutMap,
  useTable,
  useAuth,
  useProspectSearch,
} from 'src/context';

import { exists } from 'src/utils';

const useGetMedSearchResults = () => {
  const { user } = useAuth();
  const { isEditing, isCreating, mapBounds, currentTerritory, zoom, heatMap } =
    useMedScoutMap();

  const { dateRange } = useTable();
  const { prospectMode, prospectType, prospectFilters } = useProspectSearch();

  const currentTable = dateRange?.every_table
    ? dateRange?.every_table
    : dateRange['discovery'];

  let containsNonExclusionPaymentsFilter = false;

  const groupedFiltersByContext = prospectFilters.reduce(
    (result, { context, value }) => {
      if (!containsNonExclusionPaymentsFilter) {
        // [id, min, max] value pattern for payments, when max is defined as 0 this is an 'exclusion payment filter'
        containsNonExclusionPaymentsFilter =
          context !== 'payments' ? true : value[2] !== 0;
      }

      if (!result[context]) {
        result[context] = context === 'year' ? value : [value];
      } else {
        result[context] = [...result[context], value];
      }

      return result;
    },
    {}
  );

  const sortedParams = Object.keys(groupedFiltersByContext).reduce(
    (result, key) => {
      const currentParams = groupedFiltersByContext[key];
      if (Array.isArray(currentParams)) {
        result[key] = Array.from(new Set(currentParams)).sort();
      } else {
        result[key] = currentParams;
      }
      return result;
    },
    {}
  );

  const showHeat = (isEditing || isCreating) && !!heatMap;

  const queryParams = getSearchQueryParamsForAPI({
    bounds: mapBounds,
    company_id: user.company?.id,
    dateRange: {
      lte: currentTable?.lte,
      gte: currentTable?.gte,
    },
    mode: prospectMode,
    type: prospectType,
    ex_pr: 0,
    map: currentTerritory?.id && !showHeat ? [currentTerritory?.id] : null,
    zoom: zoom,
    heatmap: heatMap,
    ...sortedParams,
  });

  const shouldFetch =
    containsNonExclusionPaymentsFilter && exists(prospectFilters);

  return useSWR(
    shouldFetch ? [QUERY_KEY.GET_MAP_SEARCH_RESULTS, queryParams] : null,
    () => getMedMapSearchResults(queryParams),
    {
      keepPreviousData: true,
      retry: false,
      revalidateOnFocus: false,
    }
  );
};

export default useGetMedSearchResults;
