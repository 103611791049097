import React, { useMemo } from 'react';
import { useMap } from '@vis.gl/react-google-maps';
import useSupercluster from 'use-supercluster';
import { MAX_ZOOM } from '../../../Map/constants';
import { FeaturesClusterMarker, FeatureMarker } from './components';
import { useMedScoutMap, useSite } from 'src/context';
import { useGetMedMapSearchResults } from 'src/hooks';

const MedMapMarkers = ({
  activeMarker,
  setActiveMarker,
  addToList,
  showProviderLists,
  showMarkers,
}) => {
  const map = useMap();
  const { state } = useSite();
  const { zoom, bounds, mapBounds } = useMedScoutMap();

  // Initialize the clusters
  let radius = 75;
  if (zoom >= MAX_ZOOM) {
    radius = 0;
  } else if (zoom < MAX_ZOOM && zoom > 14) {
    radius = 25;
  } else if (zoom < 11 && zoom > 8) {
    radius = 50;
  }

  const { data: searchResults } = useGetMedMapSearchResults();

  const clusterObjects = useMemo(() => {
    const pointsToShow = showProviderLists
      ? state?.lists?.formattedProspectLists
      : showMarkers
      ? searchResults
      : [];

    return pointsToShow?.map((point) => {
      const isHovered =
        !!activeMarker &&
        activeMarker?.toString() ===
          (point?.provider_id?.toString() || point?.id?.toString());

      return {
        details: point,
        properties: {
          cluster: false,
          id: point.id,
          count: point.count,
          provider_id: point.provider_id,
          hovered: isHovered,
        },
        geometry: {
          type: 'Point',
          coordinates: [point.lng, point.lat],
        },
      };
    });
  }, [
    showProviderLists,
    state?.lists?.formattedProspectLists,
    showMarkers,
    searchResults,
    activeMarker,
  ]);

  const { clusters, supercluster } = useSupercluster({
    points: clusterObjects || [],
    // Ensure the bounds are formatted as [westLng, southLat, eastLng, northLat]
    bounds: [bounds[0][1], bounds[1][0], bounds[1][1], bounds[0][0]],
    zoom,
    options: {
      clickableIcons: false,
      radius,
      maxZoom: MAX_ZOOM,
      map: (props) => ({
        provider_id: props.provider_id || props.id,
        hovered: props.hovered,
        count: props.count || 1, // Default count to 1 if not provided
      }),
      reduce: (acc, props) => {
        acc.hovered = acc.hovered || props.hovered;
        acc.count = (acc.count || 0) + (props.count || 1); // Ensure count is accumulated correctly
        return acc;
      },
    },
  });

  const handleClusterClick = (clusterId: number, lat: number, lng: number) => {
    const zoomLevel = supercluster.getClusterExpansionZoom(clusterId);
    const expansionZoom = isNaN(zoomLevel)
      ? Math.min(zoom + 2, MAX_ZOOM)
      : zoomLevel;

    if (expansionZoom >= MAX_ZOOM && zoom === expansionZoom - 1) {
      // When we have hit max zoom and a cluster is clicked on w/ the same zoom level
      // Focus the marker, to display info for any locations under the cluster
      // maintain toggle functionality
      setActiveMarker(activeMarker === clusterId ? null : clusterId);
    } else {
      // Not at max zoom for cluster, zoom to attempt showing more fine grained markers
      // Remove any previously focused marker
      map.panTo({ lat, lng });
      map.setZoom(expansionZoom);
      setActiveMarker(null);
    }
  };

  const handleMarkerClick = (markerId: number) => {
    const marker = markerId === activeMarker ? null : markerId;
    setActiveMarker(marker);
  };

  return (
    <>
      {clusters?.map((feature, index) => {
        const { geometry, properties, id } = feature;
        const [lng, lat] = geometry.coordinates;
        const { cluster: isCluster, count } = properties;
        const showClusterMarkerInfo = isCluster && zoom === MAX_ZOOM;

        let leaves = [];
        if (isCluster && showClusterMarkerInfo) {
          leaves = supercluster?.getLeaves(Number(id), 200) || [];
        }
        const showLeaves = showClusterMarkerInfo && leaves.length > 1;
        const isHovered =
          activeMarker === Number(feature.id) ||
          feature.properties?.hovered ||
          feature?.details?.hovered;

        return isCluster ? (
          <FeaturesClusterMarker
            key={index}
            clusterId={Number(feature.id)}
            position={{ lat, lng }}
            size={count}
            sizeAsText={String(count)}
            isHovered={isHovered}
            addToList={addToList}
            clusterLeaves={showLeaves ? leaves : []}
            onMarkerClick={() =>
              handleClusterClick(Number(feature.id), lat, lng)
            }
            onMouseOver={() => {
              setActiveMarker(Number(feature.id));
            }}
            onMouseOut={() => {
              setActiveMarker(null);
            }}
          />
        ) : (
          <FeatureMarker
            key={index}
            feature={feature}
            featureId={feature?.properties?.id}
            position={{ lat, lng }}
            size={count || 1}
            sizeAsText={String(count || 1)}
            isHovered={isHovered}
            addToList={addToList}
            onMarkerClick={() => handleMarkerClick(feature?.properties?.id)}
            onMouseOver={() => {
              setActiveMarker(feature?.properties?.id);
            }}
            onMouseOut={() => {
              setActiveMarker(null);
            }}
          />
        );
      })}
    </>
  );
};

export default MedMapMarkers;
