import { useState } from 'react';
import { Button, IconButton, CircularProgress } from '@mui/joy';
import revisionInfo from './revision-content';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { useLogging, useAuth } from 'src/context';
import { useAcceptTerms } from 'src/hooks';
import { isAfter } from 'date-fns';

const TermsAndConditionsModal = ({
  showOverride,
  onHide,
}: {
  showOverride?;
  onHide?;
}) => {
  const { user } = useAuth();
  const [inlineError, setInlineError] = useState('');
  const log = useLogging();

  const { isLoading: isAcceptingTerms, mutateAsync: acceptTerms } =
    useAcceptTerms();

  const toggleModal = () => {
    setInlineError('');
    onHide && onHide();
  };

  const localShow = user?.company?.terms_accepted_at
    ? isAfter(
        new Date(revisionInfo.revisionDate),
        new Date(user?.company?.terms_accepted_at)
      )
    : true;

  const onAccept = async () => {
    try {
      await acceptTerms({
        termsVersion: revisionInfo.revisionName,
      });

      log.event('termsAccepted', {
        revisionDate: revisionInfo.revisionDate,
        revisionName: revisionInfo.revisionName,
      });
      toggleModal();
    } catch (err) {
      setInlineError(err.message);
    }
  };

  const isOpen = Boolean(showOverride) ? showOverride : localShow;
  // const isOpen = true;
  return (
    // overlay
    <div
      className={`fixed h-full w-full top-0 left-0 bg-black bg-opacity-50 flex justify-center items-center transition-opacity duration-300  ${
        isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
      }`}
      style={{
        zIndex: 1400,
      }}
    >
      <div className="h-2/3 w-8/12 lg:w-6/12 xl:w-4/12 bg-white overflow-hidden rounded-md flex flex-col ">
        <div className="flex items-center justify-between bg-[#f0f0f0] p-2 px-4 border-b border-[#ddd]">
          <h3 className="text-lg font-medium">End User Agreement</h3>
          <IconButton
            onClick={toggleModal}
            aria-label="Close"
            className="bg-none border-none cursor-pointer"
          >
            <Icon icon={faClose} />
          </IconButton>
        </div>
        <div className="p-4 h-full w-full overflow-hidden overflow-y-auto bg-white font-sans">
          <revisionInfo.ContentComponent />
        </div>
        <div className="flex justify-end p-2 px-4 bg-[#f0f0f0] border-t border-[#ddd]">
          <Button
            disabled={isAcceptingTerms}
            onClick={onAccept}
            aria-label="Accept Terms and Conditions"
          >
            {isAcceptingTerms ? (
              <CircularProgress size="sm" color="neutral" />
            ) : (
              <span>Accept</span>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsModal;
