const FormDivider = (props) => {
  return (
    <div className="flex items-center" style={{ marginTop: 15 }} {...props}>
      <div className="flex-1 h-1 bg-gray-100" />
      <div className="px-4 text-gray-700 font-semibold text-sm">
        ADDITIONAL FILTERS
      </div>
      <div className="flex-1 h-1 bg-gray-100" />
    </div>
  );
};

export default FormDivider;
