import React from 'react';
import { Button, IconButton, Menu } from '@mui/joy';
import { ClickAwayListener } from '@mui/base';
import { styled } from '@mui/material';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faBolt } from '@fortawesome/pro-solid-svg-icons';
import { useLogging, useSite } from 'src/context';
import { AddEditActivity } from '../AddEditActivity';

const StyledMenu = styled(Menu)({
  width: '386px', // Specific to keep the menu width consistent
  background: 'transparent',
  border: 'none',
  boxShadow: 'none !important',
  zIndex: 9999,
});

const NewActivityLog = ({ smallHeader = false }) => {
  const log = useLogging();
  const { state, dispatch } = useSite();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    log.event('Activity Log Button Clicked');
  };

  const handleCancel = () => {
    if (state?.activity?.hasData) {
      if (
        !confirm('You have unsaved changes. Are you sure you want to close?')
      ) {
        return;
      }
    }
    setAnchorEl(null);
    dispatch({ type: 'SET_HAS_DATA', payload: false });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ClickAwayListener onClickAway={handleCancel}>
      <div>
        {smallHeader ? (
          <IconButton
            variant="solid"
            onClick={toggleMenu}
            sx={{
              backgroundColor: '#2391D9',
            }}
          >
            <Icon icon={faBolt} />
          </IconButton>
        ) : (
          <Button
            startDecorator={<Icon icon={faBolt} />}
            variant="solid"
            onClick={toggleMenu}
            sx={{
              backgroundColor: '#2391D9',
              whiteSpace: 'nowrap',
            }}
          >
            Log Activity
          </Button>
        )}
        <StyledMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          placement="bottom-start"
        >
          <AddEditActivity
            onClose={handleClose}
            onCancel={handleCancel}
            checkHasData
          />
        </StyledMenu>
      </div>
    </ClickAwayListener>
  );
};

export default NewActivityLog;
