import { Box, Typography } from '@mui/joy';
import React from 'react';
import { ActivityIcon } from 'src/components/Activity';

interface ActivitySelectOption {
  item: {
    value: string;
    label: string;
    id: number;
    icon: string;
    color: string;
  };
}

const ActivitySelectOption = ({ item }: ActivitySelectOption) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        width: '100%',
      }}
    >
      {item?.value && (
        <ActivityIcon
          item={item}
          height="24px"
          width="24px"
          iconSize="0.75rem"
        />
      )}
      <Typography
        sx={{
          color: 'var(--Light-text-tertiary, var(--text-tertiary, #555E68))',
          fontFamily: 'Inter',
          fontSize: '0.875rem',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '1.243rem' /* 19.88px */,
        }}
      >
        {item?.label}
      </Typography>
    </Box>
  );
};

export default ActivitySelectOption;
