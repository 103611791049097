import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  useLogging,
  useNotification,
  useProspectSearch,
  useProspectSelection,
  useSite,
} from 'src/context';
import { Typography } from '@mui/joy';
import {
  DataGridPremium,
  GridFilterModel,
  GridRowId,
  useGridApiRef,
  GridColDef,
  GridColumnGroupingModel,
} from '@mui/x-data-grid-premium';
import {
  baseColumns,
  NewTableToolbar,
  TableCheckbox,
  TableColumn,
  TableFooter,
  TableLoadingIndicator,
  TableSettings,
  GroupHeader,
  NewTablePrimaryHeader,
  NewTableControls,
} from 'src/components';
import {
  useLocalStorage,
  useGetTreating,
  useGetSavedSearches,
  useGetMappings,
  useSyncTableScroll,
  useSyncRowHovered,
} from 'src/hooks';

import { useRouter } from 'next/router';
import NewVolumeHeader from 'src/components/Table/NewVolumeHeader';
import {
  NoVolumeOverlay,
  TableVolumeHeader,
  InnerContainer,
  PrimaryTableContainer,
  VolumeTableContainer,
  NewVolumeCell,
} from '../components';
import { grey } from '@mui/material/colors';
import { getTreatingReferringExport } from 'src/api/affiliations/affiliationsExportApi';

const listColumns: GridColDef[] = [
  TableColumn?.tags('tags', 'Tags'),
  TableColumn?.notes('notes', 'Notes'),
  TableColumn?.affiliations('affiliations', 'Affiliations'),
  TableColumn?.groups('groups', 'Groups'),
  TableColumn?.streetAddress('street_address', 'Street Address'),
  TableColumn?.secondaryAddresses(
    'secondary_addresses_v2',
    'Secondary Address'
  ),
  TableColumn?.dateAdded('created_at', 'Date Added'),
  TableColumn?.systems('systems', 'Hospital System'),
];

const TreatingTable = () => {
  const router = useRouter();
  const { id, type: location } = router.query as { id: string; type: string };
  const log = useLogging();
  const { setNotification } = useNotification();
  const apiRef = useGridApiRef();
  const apiRef2 = useGridApiRef();

  const { state } = useSite();

  // Sync the scroll of the two tables
  useSyncTableScroll(apiRef, apiRef2);
  const { isRowHovered } = useSyncRowHovered(apiRef, apiRef2);
  const { prospectSearch } = useProspectSearch();

  const [columnVisibilityModel, setColumnVisibilityModel] = useLocalStorage(
    'treatingTable',
    {
      name: true,
      crm_links: true,
      specialty: true,
      rep_name: false,
      tags: false,
      provider_id: false,
      address: false,
      street_address: false,
      secondary_addresses_v2: false,
      city: false,
      state: false,
      zip_code: false,
      affiliations: false,
      groups: false,
      actions: false,
      notes: false,
      phone: false,
      email: false,
      created_at: false,
      systems: false,
    }
  );

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });
  const [isExporting, setIsExporting] = useState(false);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [sort, setSort] = useState({
    sort_value: null,
    order: null,
    sort_search: null,
  });

  const { data: savedSearches = [] } = useGetSavedSearches();

  const searchIds = useMemo(() => {
    return Array.from(
      new Set(
        state?.siteProvider[location]?.selectedSearches
          ?.map((search) => search?.id?.toString())
          .filter(Boolean)
      )
    );
  }, [location, state]);

  const queryParams = {
    providerId: id,
    sort: sort?.sort_value,
    order: sort?.order,
    sort_search: sort?.sort_search,
    page: paginationModel.page,
    pageSize: paginationModel.pageSize,
    enabledSearchIds: searchIds,
    q: state?.siteProvider[location]?.search,
    volume_type: state?.siteProvider[location]?.selectedType,
    dateRange: state?.siteProvider[location]?.selectedDate,
  };

  const { data: treatingData, isLoading } = useGetTreating(queryParams);

  const { data: allMappings } = useGetMappings({
    sort: 'label',
    order: 'asc',
    page: 0,
    pageSize: 100,
  });

  const crmTypes = useMemo(() => {
    return Array.from(
      new Set(
        allMappings?.results
          ?.filter((mapping) => mapping.enabled)
          .map((mapping) => mapping.crm?.toLowerCase())
      )
    );
  }, [allMappings]);

  const {
    state: [, setNewProspects],
  } = useProspectSelection();

  useEffect(() => {
    if (!apiRef.current?.getSelectedRows) return;
    setNewProspects(Array.from(apiRef.current.getSelectedRows().values()));

    return () => setNewProspects([]);
  }, [selectionModel]);

  // Calculate row height based on address and/or affiliations
  const getRowHeight = useCallback(
    ({ model, densityFactor }) => {
      const addressMultiplyer = 75;
      const affiliationMultiplyer = 32;

      let addressHeight = 0;
      let affiliationHeight = 0;

      if (model.address_line_1) {
        addressHeight = addressMultiplyer * densityFactor;
      }

      if (
        columnVisibilityModel.affiliations !== false &&
        model.affiliations &&
        model.affiliations?.length > 0
      ) {
        affiliationHeight =
          affiliationMultiplyer * model.affiliations?.length * densityFactor;
      }

      return Math.max(addressHeight, affiliationHeight) || 'auto';
    },
    [columnVisibilityModel]
  );

  // maintain column visibility state when currentTable trending changes.. this is to show the volume columns
  useEffect(() => {
    // set options columns to visible if the table is trending
    setColumnVisibilityModel((columnVisibilityState) => ({
      ...columnVisibilityState,
      ...serializeColumnVisibility(),
    }));
  }, []);

  const enhancedRows = useMemo(() => {
    if (!treatingData?.results || !prospectSearch) return [];

    return treatingData.results?.map((row) => {
      return row;
    });
  }, [treatingData, prospectSearch]);

  const serializeColumnVisibility = () => {
    const visibilityMap = {};

    // Hide CRM column if user does not have access to CRM
    visibilityMap['crm'] = crmTypes.length > 0;

    return visibilityMap;
  };

  const handleExportList = (type: string) => {
    const columns = Object.entries(columnVisibilityModel)
      .filter(([key, value]) => value === true)
      .map(([key]) => key)
      .concat(['volume_at_location', 'volume_total']);
    setIsExporting(true);
    try {
      const res = getTreatingReferringExport({
        searchParams: queryParams,
        tableType: 'treating',
        fileType: type,
        columns,
      });

      if (!res) return;
      setNotification({
        title: 'Success',
        message:
          'Export initiated - You will receive an email when it is ready for download',
        type: 'success',
      });
      log.event('Export', {
        type: 'affiliations',
        source: 'All Affiliated - Treating',
        columns,
      });
    } catch (err: any) {
      log.exception(
        `Error exporting All Affiliated - Treating: ${err.message}`
      );

      setNotification({
        title: 'Error',
        message: 'Error exporting, please try again.',
        type: 'error',
      });
    } finally {
      setIsExporting(false);
    }
  };

  const handleSortChange = (model) => {
    let newField = 'name';
    let sort_search = '';
    let sort_value = '';

    if (model.length) {
      const { field } = model[0];
      newField =
        isNaN(Number(field)) && field !== 'volume' ? field : 'saved_search';

      if (newField?.includes('here_')) {
        // remove the here_
        const searchId = field.replace('here_', '');
        sort_search = searchId;
        sort_value = 'saved_search_at_location';
      } else if (newField?.includes('total_')) {
        // remove the total_
        const searchId = field.replace('total_', '');
        sort_search = searchId;
        sort_value = 'saved_search_in_total';
      }
    }
    setSort({
      order: model.length ? model[0].sort : 'desc',
      sort_search,
      sort_value,
    });
  };

  const handlePageChange = (e, newPage) => {
    setPaginationModel((prev) => ({ ...prev, page: newPage }));
  };

  const handlePageSizeChange = (e) => {
    setPaginationModel((prev) => ({
      ...prev,
      pageSize: parseInt(e.target.value, 10),
      page: 0,
    }));
  };

  // main columns for the table
  const mainColumns = useMemo(() => {
    return [
      ...baseColumns.slice(0, 2),
      ...listColumns,
      ...baseColumns.slice(2),
    ];
  }, [baseColumns, listColumns]);

  // grouping model for main columns
  const columnGroupingModel: GridColumnGroupingModel = useMemo(() => {
    return [
      {
        groupId: 'Provider Info',
        description: 'Provider Information',
        renderHeaderGroup: (params) => {
          return <GroupHeader {...params} isVolume={false} />;
        },
        children: mainColumns,
      },
    ];
  }, [mainColumns]);

  // Dynamically create columns for each searchId
  const newVolumeColumns = useMemo(() => {
    return searchIds?.length > 0
      ? searchIds.flatMap((id) => [
          {
            field: `here_${id}`,
            headerName: 'Here',
            width: 100,
            renderHeader: (params) => <NewVolumeHeader {...params} />,
            renderCell: (params) => <NewVolumeCell {...params} />,
          },
          {
            field: `total_${id}`,
            headerName: 'Total',
            width: 100,
            renderHeader: (params) => <NewVolumeHeader {...params} />,
            renderCell: (params) => <NewVolumeCell {...params} />,
          },
        ])
      : [
          {
            field: 'placeholder_here',
            headerName: '',
            sortable: false,
            width: 100,
          },
          {
            field: 'placeholder_total',
            headerName: '',
            sortable: false,
            width: 100,
          },
        ];
  }, [searchIds]);

  // grouping model for volume columns
  const volumeGroupingModel = useMemo(() => {
    return searchIds && searchIds?.length > 0
      ? searchIds.map((id) => {
          const search = savedSearches.find((s) => s.id === Number(id));
          return {
            groupId: id,
            headerName: search?.search_name,
            renderHeaderGroup: (params) => {
              return <GroupHeader {...params} isVolume={true} />;
            },
            children: [{ field: `here_${id}` }, { field: `total_${id}` }],
          };
        })
      : [
          {
            groupId: 'placeholder',
            headerName: '',

            children: [
              { field: 'placeholder_here' },
              { field: 'placeholder_total' },
            ],
          },
        ];
  }, [searchIds, savedSearches]);

  return (
    <PrimaryTableContainer>
      <InnerContainer>
        <DataGridPremium
          disableVirtualization
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          apiRef={apiRef}
          rows={enhancedRows || []}
          rowCount={treatingData?.count || 0}
          columns={mainColumns}
          scrollbarSize={0}
          hideFooter={true}
          experimentalFeatures={{
            columnGrouping: true,
          }}
          columnGroupingModel={columnGroupingModel}
          checkboxSelection
          getRowClassName={isRowHovered}
          sx={{
            // remove border from table and only show overflow on x axis
            border: 'none',
            borderRadius: 0,
            '& .MuiDataGrid-main, & .MuiDataGrid-footerContainer': {
              backgroundColor: 'white',
            },
            '& .MuiDataGrid-virtualScroller': {
              overflowY: 'scroll',
              overflowX: 'auto',
              marginRight: '-1rem', // hides the Y axis scrollbar
            },
            '& .MuiDataGrid-columnHeadersInner>div:first-of-type, & .MuiDataGrid-groupHeader, & .MuiDataGrid-columnHeader--emptyGroup':
              {
                // display: 'none !important',
                height: 'auto !important',
              },
            '& .MuiDataGrid-row:hover, & .Mui-hovered': {
              backgroundColor: grey[100],
              '&.Mui-hovered': {
                backgroundColor: grey[200],
              },
            },
          }}
          initialState={{
            columns: { columnVisibilityModel },
            pagination: { paginationModel: { page: 0, pageSize: 50 } },
          }}
          slots={{
            toolbar: NewTableToolbar,
            baseCheckbox: TableCheckbox,
            ...TableSettings.slots,
          }}
          slotProps={{
            columnsPanel: {
              disableHideAllButton: true,
              disableShowAllButton: true,
            },
            toolbar: {
              variant: 'primary',
              headerSlots: {
                slot1: <NewTablePrimaryHeader title="Treating" />,
                slot2: (
                  <NewTableControls
                    columns={mainColumns}
                    columnVisibilityModel={columnVisibilityModel}
                    setColumnVisibilityModel={setColumnVisibilityModel}
                    onExport={handleExportList}
                    isExporting={isExporting}
                  />
                ),
              },
            },
          }}
          // Pagination
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          // Sorting
          sortingMode="server"
          onSortModelChange={handleSortChange}
          // Filtering
          filterMode="server"
          onFilterModelChange={setFilterModel}
          filterModel={filterModel}
          // Column visibility
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={setColumnVisibilityModel}
          // Rows
          getRowHeight={getRowHeight}
          onRowSelectionModelChange={setSelectionModel}
          rowSelectionModel={selectionModel}
        />
        <VolumeTableContainer>
          <DataGridPremium
            disableVirtualization
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            apiRef={apiRef2}
            rows={searchIds?.length > 0 ? enhancedRows : []}
            rowCount={treatingData?.count || 0}
            columns={newVolumeColumns}
            hideFooter={true}
            experimentalFeatures={{
              columnGrouping: true,
            }}
            columnGroupingModel={volumeGroupingModel}
            getRowClassName={isRowHovered}
            sx={{
              // remove border from table
              border: 'none',
              borderRadius: 0,
              background: 'var(--secondary-outlined-ActiveBg, #D6F6F1)',
              '& .muiDataGrid-withBorderColor': {
                border: 'none',
              },
              '& .MuiDataGrid-columnHeaderTitleContainerContent': {
                display: 'flex',
                alignItems: 'center',
                flex: '1 0 0',
                alignSelf: 'stretch',
              },
              '& .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainer':
                {
                  borderBottom: 'none',
                },
              // This fixes that little notch on the right created by the scrollbar
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: 'var(--secondary-outlined-ActiveBg, #D6F6F1)',
                '& .MuiDataGrid-columnSeparator': {
                  color: 'none',
                },
              },
              '& .MuiDataGrid-overlayWrapperInner': {
                backgroundColor: 'var(--secondary-outlined-ActiveBg, #D6F6F1)',
              },
              '& .MuiDataGrid-columnHeaderGroupCell': {
                borderBottom: 'none', // Remove group header cell borders if present
              },
              '& .MuiDataGrid-row:hover, & .Mui-hovered': {
                backgroundColor: grey[100],
                '&.Mui-hovered': {
                  backgroundColor: grey[200],
                },
              },
              '& .MuiDataGrid-columnSeparator': {
                display: 'none !important',
              },
            }}
            slots={{
              toolbar: NewTableToolbar,
              noRowsOverlay: NoVolumeOverlay,
              noResultsOverlay: NoVolumeOverlay,
              ...TableSettings.slots,
            }}
            slotProps={{
              columnsPanel: {
                disableHideAllButton: true,
                disableShowAllButton: true,
              },
              toolbar: {
                headerSlots: {
                  slot1: (
                    <TableVolumeHeader
                      type="SERVICED"
                      selectedType={state?.siteProvider[location]?.selectedType}
                      yearRange={state?.siteProvider[location]?.selectedDate}
                    />
                  ),
                },
              },
            }}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            // Sorting
            sortingMode="server"
            onSortModelChange={handleSortChange}
            // Filtering
            filterMode="server"
            onFilterModelChange={setFilterModel}
            filterModel={filterModel}
            // Column visibility
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={setColumnVisibilityModel}
            // Rows
            getRowHeight={getRowHeight}
          />
        </VolumeTableContainer>
        {isLoading && <TableLoadingIndicator />}
      </InnerContainer>
      <TableFooter
        page={paginationModel.page}
        pageSize={paginationModel.pageSize}
        rowCount={treatingData?.count || 0}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />
    </PrimaryTableContainer>
  );
};

export default TreatingTable;
