import { faBolt } from '@fortawesome/pro-solid-svg-icons';
import { useGetHexReports } from 'src/hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const categoryTitles = {
  affiliations: 'Providers',
  old_affiliations: 'Affiliations',
  apc: 'APC Procedures',
  cpt: 'CPT® Procedures',
  drg: 'DRG Procedures',
  icd: 'Diagnoses',
  icdp: 'ICD Procedures',
  hcpcs: 'HCPCS Level II',
  prescriptions: 'Prescriptions',
  patient_patterns: 'Referrals',
  payments: 'Open Payments',
  education: 'Education Network',
  relationships: 'Relationships',
  referrals: '2015 Inbound Referrals',
};

type formatMenuContentProps = {
  id: string;
  type: string;
  categories: { [key: string]: boolean };
  viewportWidth: number;
  isSmallHeader: boolean;
};

type ProfileCategory = {
  label: string;
  path?: string;
  id?: string | null;
  tabs?: { label: string; path: string; id: string }[];
  icon?: any;
};

const useMenuContent = ({
  categories,
  id,
  type,
  viewportWidth,
  isSmallHeader,
}: formatMenuContentProps) => {
  const { data: reportsData } = useGetHexReports();
  const hasReports = reportsData?.results?.length > 0;
  const { useProfileActivityLogger } = useFlags();
  const tabMenuLength = isSmallHeader ? 1 : viewportWidth < 1050 ? 1 : 3;

  const isCenter = type === 'center';

  const profileCategories: ProfileCategory[] = [
    useProfileActivityLogger && {
      label: 'Activity',
      path: `/profiles/${type}/${id}/activity`,
      id: 'activity',
      icon: faBolt,
    },
    {
      label: 'Overview',
      path: `/profiles/${type}/${id}`,
      id: 'overview',
    },
  ].filter(Boolean);

  const procedureCategories = ['drg', 'apc', 'cpt', 'icdp', 'hcpcs'].filter(
    (proc) => !!categories[proc]
  );

  if (procedureCategories.length > 0) {
    profileCategories.push({
      label: 'Procedures',
      path: `/profiles/${type}/${id}/drg`,
      id: 'procedure',
      tabs: procedureCategories.map((proc) => ({
        label: categoryTitles[proc],
        path: `/profiles/${type}/${id}/${proc}`,
        id: proc,
      })),
    });
  }

  if (isCenter && hasReports) {
    profileCategories.push({
      label: 'Reports',
      path: `/`,
      id: 'reports',
      tabs: reportsData.results.map((report) => ({
        label: report.label,
        path: `/profiles/${type}/${id}/${report.id}`,
        id: report.id,
      })),
    });
  }

  const remainingCategories = Object.keys(categoryTitles)
    .filter(
      (view) =>
        !procedureCategories.includes(view) &&
        (!!categories[view] ||
          !!categories[`inbound_${view}`] ||
          !!categories[`outbound_${view}`])
    )
    .map((view) => {
      if (view === 'old_affiliations') {
        const newView = 'group-affiliations';
        return {
          label: categoryTitles[view],
          path: `/profiles/${type}/${id}/${newView}`,
          id: newView,
        };
      }
      return {
        label: categoryTitles[view],
        path: `/profiles/${type}/${id}/${view}`,
        id: view,
      };
    });

  if (remainingCategories.length > tabMenuLength) {
    profileCategories.push(...remainingCategories.slice(0, tabMenuLength));
    profileCategories.push({
      label: 'More',
      path: '#',
      id: 'more',
      tabs: remainingCategories.slice(tabMenuLength),
    });
  } else {
    profileCategories.push(...remainingCategories);
  }

  return profileCategories;
};

export default useMenuContent;
