import useSWR from 'swr';
import { getAllHexReports } from 'src/api';
import { QUERY_KEYS } from './constants';

const useGetHexReports = () => {
  return useSWR([QUERY_KEYS.GET_ALL_HEX_REPORTS], () => getAllHexReports(), {
    keepPreviousData: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
};

export default useGetHexReports;
