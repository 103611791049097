import useSWR from 'swr';
import { getHexReportById } from 'src/api';
import { QUERY_KEYS } from './constants';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

interface GetLeakageReportProps {
  providerId: string;
  dashboard: string;
}

const useGetLeakageReport = ({
  providerId,
  dashboard,
}: GetLeakageReportProps) => {
  const queryParams = getSearchQueryParamsForAPI({
    provider_id: providerId,
  });

  const shouldFetch = providerId;
  return useSWR(
    shouldFetch
      ? [QUERY_KEYS.GET_HEX_REPORT_BY_ID, queryParams, dashboard]
      : null,
    () =>
      getHexReportById({
        dashboard,
        queryParams,
      }),
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
};

export default useGetLeakageReport;
