import React, { useMemo, useState } from 'react';
import { Container, Box, Table, Typography, CircularProgress } from '@mui/joy';
import { TableRow } from '@mui/material';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faBolt, faLeftFromBracket } from '@fortawesome/pro-solid-svg-icons';
import ActivityTableToolbar from '../Tables/components/ActivityTable/ActivityTableToolbar';
import { daysSince } from 'src/utils';
import { useAuth, useSite } from 'src/context';
import { useDebounce, useDebouncedCallback } from 'use-debounce';
import ActivityTableHeader from '../Tables/components/ActivityTable/ActivityTableHeader';
import { TableFooter } from '../Tables';
import { ProfileBlade, PushToCRMGroup } from 'src/components';
import { TableRowItem } from '../Tables/components/ActivityTable/components/TableRowItem';
import ActivityTypeLabel from '../Tables/components/ActivityTable/components/ActivityTypeLabel';
import { useGetAllStatuses, useGetActivities } from 'src/hooks';

const RowItem = ({
  act,
  isManager,
  hasStatuses,
  isHovered,
  onHover,
  onShowblade,
}) => {
  return (
    <TableRow
      onMouseEnter={() => onHover(act.id)}
      onMouseLeave={() => onHover(null)}
      key={act.id}
      sx={{
        padding: 0,
        backgroundColor: '#fff',
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1rem 15rem 15rem 15rem 15rem 15rem auto',
        alignItems: 'center',
        height: '3.5rem' /*56px*/,
        '&:hover': {
          background: 'rgba(0, 0, 0, 0.05)',
        },
      }}
    >
      <TableRowItem width="1rem"></TableRowItem>
      <TableRowItem width="15rem">
        <ActivityTypeLabel activityId={act.type} />
      </TableRowItem>
      <TableRowItem width="15rem">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <Box>
            <Typography
              sx={{
                color: '#1B5FA3',
                fontWeight: 600,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '13.5rem',
              }}
            >
              {act.provider.name}
            </Typography>
            <Typography
              level="body-xs"
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '13rem',
              }}
            >
              {act.provider.specialty}
            </Typography>
          </Box>
          <button
            style={{
              position: 'absolute',
              right: 0,
              display: 'flex',
              padding: '.5rem' /* 8px */,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 'var(--radius-md, 0.5rem)',
              border: '1px solid var(--neutral-outlined-Border, #CDD7E1)',
              background: 'var(--common-white, #FFF)',
              /* shadow-sm */
              boxShadow:
                '0px 1px 2px 0px rgba(21, 21, 21, 0.08), 0px 2px 4px 0px rgba(21, 21, 21, 0.08)',
              opacity: isHovered === act.id ? 1 : 0,
              transition: 'opacity 0.1s ease',
            }}
            onClick={() =>
              onShowblade({
                providerId: act?.provider?.id,
                type: act?.provider?.type,
              })
            }
          >
            <Icon
              height="1rem"
              width="1rem"
              icon={faLeftFromBracket}
              color="#636B74"
            />
            <Typography
              sx={{
                color: 'var(--text-icon, #636B74)',
                fontSize: '0.875rem',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: '114%' /* 15.96px */,
                padding: '0 0.25rem',
              }}
            >
              Open
            </Typography>
          </button>
        </Box>
      </TableRowItem>
      {isManager && (
        <TableRowItem width="15rem">
          {`${act.owner.first_name} ${act.owner.last_name}`}
        </TableRowItem>
      )}
      <TableRowItem width="15rem">{daysSince(act.created_at)}</TableRowItem>
      <TableRowItem width="15rem">
        <Box>
          <PushToCRMGroup provider={act?.provider} />
        </Box>
      </TableRowItem>
      <TableRowItem>
        <div
          style={{
            flexGrow: 1,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
          }}
        >
          {act.details}
        </div>
      </TableRowItem>
    </TableRow>
  );
};
import { useIntegrations } from 'src/modules/Settings/context';

const Activity = () => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { user } = useAuth();
  const isManager =
    user.permissions.is_manager || user.permissions.is_superuser;

  const { dispatch } = useSite();
  const { mappings } = useIntegrations();
  const showStatusColumn = mappings?.length > 0;

  const [sort, setSort] = useState<{
    sort_value: string;
    order: 'asc' | 'desc';
  }>({
    sort_value: null,
    order: null,
  });
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const [selectedReps, setSelectedReps] = useState<string[] | null>([]);
  const [selectedActivities, setSelectedActivities] = useState<string[] | null>(
    []
  );
  const [selectedStatuses, setSelectedStatuses] = useState<{
    [key: string]: string[];
  }>({});
  const [selectedTimeframe, setSelectedTimeframe] = useState<string | null>();
  const [hoveredRow, setHoveredRow] = useState(null);

  const [visibleStart, setVisibleStart] = useState(0); // Tracks the starting index of visible rows
  const [visibleEnd, setVisibleEnd] = useState(0); // Tracks the ending index of visible rows

  const ROW_HEIGHT = 57; // Height of each row in pixels
  const BUFFER_ROWS = 10; // Number of extra rows to render above and below the viewport for smooth scrolling

  const queryParams = {
    sort: sort?.sort_value,
    order: sort?.order,
    page: paginationModel.page,
    pageSize: paginationModel.pageSize,
    q: debouncedSearchTerm,
  };

  const { data, isLoading } = useGetActivities({
    reps: selectedReps?.length > 0 ? selectedReps : null,
    activityTypes: selectedActivities?.length > 0 ? selectedActivities : null,
    statuses:
      selectedStatuses && Object.keys(selectedStatuses).length > 0
        ? selectedStatuses
        : null,
    timeframe: selectedTimeframe,
    ...queryParams,
  });

  const activities = useMemo(() => data?.results || [], [data]);

  const { data: allStatuses } = useGetAllStatuses();

  const handleColSort = (col: string, order: 'asc' | 'desc') => {
    setSort({ sort_value: col, order });

    if (containerRef.current) {
      containerRef.current.scrollTop = 0; // Reset scroll to top
    }
  };

  const hasStatuses = allStatuses?.some((obj) => obj.crm_status != null);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const handlePageChange = (e, newPage) => {
    setPaginationModel((prev) => ({ ...prev, page: newPage }));

    if (containerRef.current) {
      containerRef.current.scrollTop = 0; // Reset scroll to top
    }
  };

  const handlePageSizeChange = (e) => {
    setPaginationModel((prev) => ({
      ...prev,
      pageSize: parseInt(e.target.value, 10),
      page: 0,
    }));
  };

  const handleShowBlade = ({ providerId, type }) => {
    if (!providerId || !type) return;
    dispatch({
      type: 'SET_CURRENT_PROVIDER',
      payload: {
        id: providerId,
        type,
      },
    });
    dispatch({ type: 'OPEN_PROFILE_DRAWER' });
  };

  const totalRows = activities.length;

  // Adjust scroll logic
  const handleScroll = () => {
    if (!containerRef.current) return;

    const scrollTop = containerRef.current.scrollTop;
    const visibleHeight = containerRef.current.clientHeight;

    // Calculate start and end indices
    const startIdx = Math.floor(scrollTop / ROW_HEIGHT);
    const endIdx = Math.ceil((scrollTop + visibleHeight) / ROW_HEIGHT);

    setVisibleStart(Math.max(0, startIdx - BUFFER_ROWS));
    setVisibleEnd(Math.min(totalRows, endIdx + BUFFER_ROWS));
  };

  const debouncedHandleScroll = useDebouncedCallback(handleScroll, 100);

  React.useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', debouncedHandleScroll);
    }

    // Set initial visible rows on mount
    const visibleHeight = containerRef.current?.clientHeight || 0;
    setVisibleStart(0);
    setVisibleEnd(Math.ceil(visibleHeight / ROW_HEIGHT) + BUFFER_ROWS);

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener(
          'scroll',
          debouncedHandleScroll
        );
      }
    };
  }, [debouncedHandleScroll]);

  const visibleActivities = useMemo(() => {
    return activities.slice(visibleStart, visibleEnd);
  }, [visibleStart, visibleEnd, activities]);

  const hasActivities = activities && activities?.length > 0;

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        height: '100%',
        width: '100%',
        backgroundColor: '#FFFFFF',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          borderBottom: '1px solid var(--Neutral-200, #DDDFE0)',
          backgroundColor: '#FFFFFF',
        }}
      >
        <div
          style={{
            padding: '1rem',
            borderBottom: '1px solid var(--Neutral-200, #DDDFE0)',
            backgroundColor: '#FFFFFF',
          }}
        >
          <Typography level="h4">
            <Icon icon={faBolt} color={'#636B74'} /> Activity
          </Typography>
        </div>
        <ActivityTableToolbar
          searchTerm={searchTerm}
          onSearch={handleSearch}
          selectedReps={selectedReps}
          setSelectedReps={setSelectedReps}
          selectedActivities={selectedActivities}
          setSelectedActivities={setSelectedActivities}
          selectedStatuses={selectedStatuses}
          setSelectedStatuses={setSelectedStatuses}
          selectedTimeframe={selectedTimeframe}
          setSelectedTimeframe={setSelectedTimeframe}
          statuses={allStatuses}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          backgroundColor: '#FFFFFF',
          borrom: 0,
        }}
      >
        <Table
          noWrap
          stickyHeader
          stickyFooter
          sx={{
            height: '100%',
            tableLayout: 'auto',
          }}
        >
          <ActivityTableHeader
            sort={sort}
            handleColSort={handleColSort}
            isManager={isManager}
            showStatusColumn={showStatusColumn}
          />
          <div
            ref={containerRef}
            style={{
              height: 'calc(100% - 9.5rem)',
              overflowY: 'auto',
              width: '100%',
              position: 'relative',
            }}
          >
            {/* Virtualized table rows */}
            {hasActivities ? (
              <div
                style={{
                  height: `${totalRows * ROW_HEIGHT}px`, // Total height of all rows
                  position: 'relative',
                }}
              >
                {visibleActivities.map((act, index) => (
                  <div
                    key={act.id}
                    style={{
                      position: 'absolute',
                      top: `${(visibleStart + index) * ROW_HEIGHT}px`, // Correct positioning
                      left: 0,
                      right: 0,
                      height: `${ROW_HEIGHT}px`,
                      display: 'flex',
                      alignItems: 'center',
                      borderBottom: '1px solid var(--Neutral-200, #DDDFE0)',
                    }}
                  >
                    <RowItem
                      key={index}
                      act={act}
                      isManager={isManager}
                      hasStatuses={hasStatuses}
                      isHovered={hoveredRow}
                      onHover={setHoveredRow}
                      onShowblade={handleShowBlade}
                    />
                  </div>
                ))}
              </div>
            ) : (
              !isLoading &&
              !data && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <Typography
                    sx={{
                      color: 'var(--Neutral-600, #5D5E5F)',
                      fontSize: '1rem',
                      fontWeight: 500,
                    }}
                  >
                    No activities
                  </Typography>
                </Box>
              )
            )}

            {isLoading && (
              <Box
                sx={{
                  position: 'absolute', // Keep it relative to the container
                  top: `${containerRef.current?.scrollTop || 0}px`, // Account for the scroll position
                  left: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                  minHeight: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  zIndex: 10,
                }}
              >
                <CircularProgress size="sm" color="neutral" />
              </Box>
            )}
          </div>
        </Table>
        <TableFooter
          rowCount={data?.count || 0}
          page={paginationModel.page}
          pageSize={paginationModel.pageSize}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Box>
      <ProfileBlade />
    </Container>
  );
};

export default Activity;
