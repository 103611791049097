import useSWR from 'swr';
import { getNewSavedSearches } from 'src/api';
import { QUERY_KEYS } from './constants';
import { useSite } from 'src/context';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

/**
 * Custom hook to fetch saved searches for the selected company.
 * @returns The result of the query.
 */
export default function useGetSavedSearches() {
  const { state } = useSite();

  const queryParams = state?.savedSearch?.queryParams
    ? getSearchQueryParamsForAPI(state?.savedSearch?.queryParams)
    : '';
  return useSWR(
    [QUERY_KEYS.saved_searches, queryParams],
    () => getNewSavedSearches(queryParams),
    {
      revalidateOnFocus: false,
    }
  );
}
