import { Box, IconButton, Typography } from '@mui/joy';
import React from 'react';
import { CloseSharp } from '@mui/icons-material';

const FilterPill = ({ selectedOptions, handleClearFilters }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        maxHeight: '1.5rem',
        padding: '0 0 0 .5rem',
        alignItems: 'center',
        borderRadius: '1.5rem',
        background: 'var(--Neutral-200, #DDDFE0)',
        whiteSpace: 'nowrap', // Prevent text wrapping
      }}
    >
      <Typography
        sx={{
          color: 'var(--Neutral-800, #2C2E2F)',
          fontSize: '.875rem' /* 14px */,
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: '150%' /* 21px */,
        }}
      >
        {selectedOptions.length} Filter
        {selectedOptions.length > 1 ? 's' : ''}
      </Typography>
      <IconButton
        onClick={handleClearFilters}
        color="neutral"
        variant="outlined"
        aria-label="Clear Filters"
        sx={{
          border: 'none',
          borderRadius: '1rem',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <CloseSharp
          style={{
            height: '1.25rem',
            width: '1.25rem',
          }}
        />
      </IconButton>
    </Box>
  );
};

export default FilterPill;
