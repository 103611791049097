import React, { useMemo } from 'react';
import { useAuth, useLogging, useNotification, useSite } from 'src/context';
import {
  addSavedSearchFolder,
  updateSavedSearchFolder,
  deleteSavedSearchFolder,
} from 'src/api';
import { useGetSavedSearches, useGetSavedSearchFolders } from 'src/hooks';
import { Box, IconButton, Typography, Input } from '@mui/joy';
import { styled } from '@mui/material';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faFolderBookmark,
  faFolderOpen,
  faPencil,
  faCheck,
  faTimes,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import { SavedSearch } from '../SavedSearch';
import { SavedSearchActionTypes } from 'src/context/reducers/actionTypes';
import { RoundButton } from '../buttons';

const StyledFolder = styled(Box)({
  display: 'flex',
  height: '32px',
  padding: 'var(--none, 0px) var(--1, 0.5rem)',
  alignItems: 'center',
  gap: 'var(--05, 0.25rem)',
  alignSelf: 'stretch',
  borderRadius: 'var(--radius-md, 0.5rem)',
  cursor: 'pointer',
  '&:hover': {
    background: 'var(--background-level1, #F0F4F8)',
  },
});

const Title = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active: boolean }>(({ active }) => ({
  color: 'var(--text-primary, #171A1C)',
  fontSize: '0.875rem',
  fontWeight: active ? 700 : 500,
  lineHeight: '1.243rem' /* 19.88px */,
  flex: '1 0 0',
}));

const IconContainer = styled(Box)({
  width: '20px',
  height: '20px',
  display: 'flex',
  alignItems: 'center',
});

const Folder = ({ folder }) => {
  const log = useLogging();
  const { setNotification } = useNotification();
  const { user } = useAuth();
  const { mutate } = useGetSavedSearchFolders();
  const { data: savedSearches } = useGetSavedSearches();
  const { state, dispatch } = useSite();

  const [isHovered, setIsHovered] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [newName, setNewName] = React.useState('');

  const isActive = state?.savedSearch?.activeFolder === folder.id;
  const canEdit =
    folder.type === 'COMPANY' &&
    (!user?.permissions?.is_manager || !user?.is_superuser)
      ? false
      : true;

  const showEdit = isEditing || folder.name === 'New Folder';

  const folderWithSearches = useMemo(() => {
    if (!folder || !savedSearches) return folder;
    const searches = folder.saved_searches?.map((search) =>
      savedSearches.find((s) => s.id === Number(search))
    );

    return {
      ...folder,
      saved_searches: searches?.sort((a, b) => {
        return a.search_name?.localeCompare(b.search_name);
      }),
    };
  }, [folder, savedSearches]);

  const handleClick = () => {
    dispatch({
      type: SavedSearchActionTypes.SET_ACTIVE_FOLDER,
      payload: isActive ? null : folder.id,
    });

    log.event('savedSearchFolderClicked', {
      location: 'SavedSearch',
      action: 'handleClick',
      object: folder,
      user: user,
    });
  };

  const handleNameChange = (e) => {
    setNewName(e.target.value);
  };

  const handleEnableEdit = () => {
    setIsEditing(true);
    setNewName(folder?.name || '');
  };

  const handleCancel = () => {
    // If the folder is new, remove it
    if (folder.id?.toString()?.includes('temp-')) {
      dispatch({
        type: SavedSearchActionTypes.REMOVE_TEMP_FOLDER,
        payload: folder.id,
      });
    }
    setNewName('');
    setIsEditing(false);
  };

  const handleDelete = async (e) => {
    e.stopPropagation();
    if (!confirm('Are you sure you want to delete this folder?')) {
      return;
    }
    try {
      await deleteSavedSearchFolder(folder.id);
      log.event('savedSearchFolderDeleted', {
        location: 'SavedSearch',
        action: 'handleDelete',
        object: folder,
        user: user,
      });
    } catch (err) {
      setNotification({
        title: 'Error',
        message: 'Error deleting folder',
        type: 'error',
      });
      log.exception('savedSearchFolderError', {
        tags: {
          location: 'SavedSearch',
          action: 'handleDelete',
          object: folder,
          user: user as any,
          error: err,
        },
      });
    } finally {
      mutate();
      handleCancel();
    }
  };

  const onSubmit = async () => {
    if (!newName) return;
    try {
      if (isEditing) {
        await updateSavedSearchFolder({
          id: folder.id,
          name: newName,
        });
        log.event('savedSearchFolderUpdated', {
          location: 'SavedSearch',
          action: 'onSubmit',
          object: folder,
          user: user,
        });
      } else {
        const newFolder = await addSavedSearchFolder({
          name: newName,
          type: folder.type,
        });

        log.event('savedSearchFolderCreated', {
          location: 'SavedSearch',
          action: 'onSubmit',
          object: newFolder,
          user: user,
        });
      }
    } catch (err) {
      setNotification({
        title: 'Error',
        message: `Error saving folder: ${err.message}`,
        type: 'error',
      });
      log.exception('savedSearchFolderError', {
        tags: {
          location: 'SavedSearch',
          action: 'onSubmit',
          object: folder,
          user: user as any,
          error: err,
        },
      });
    } finally {
      handleCancel();
      mutate();
    }
  };

  const onDrop = async (e) => {
    e.preventDefault();
    const fileId = e.dataTransfer.getData('text/plain'); // Get the ID of the dragged file
    const folderId = folder.id; // ID of the current folder

    dispatch({
      type: SavedSearchActionTypes.SET_MOVED_SAVED_SEARCH,
      payload: {
        id: fileId,
        folderId: folderId,
      },
    });

    // Optimistically update the folder
    mutate(
      (currentData) => {
        if (!currentData) return currentData;

        const updatedResults = currentData.results.map((f) =>
          f.id === folderId
            ? {
                ...f,
                saved_searches: [...f.saved_searches, Number(fileId)],
              }
            : f
        );

        return { ...currentData, results: updatedResults };
      },
      false // Skip revalidation
    );

    try {
      const data = {
        ...folder,
        saved_searches: [...folder.saved_searches, fileId],
      };
      // Update the server
      await updateSavedSearchFolder(data);
      log.event('savedSearchFolderUpdated', {
        location: 'SavedSearch',
        action: 'onDrop',
        object: folder,
        user: user,
      });
    } catch (error) {
      log.exception('savedSearchFolderError', {
        tags: {
          location: 'SavedSearch',
          action: 'handleDrop',
          object: folder,
          user: user as any,
          error: error,
        },
      });
    } finally {
      // Revalidate the data
      handleCancel();
      mutate();
    }
  };

  const onHover = () => {
    setIsHovered(true);
  };

  const onLeave = () => {
    setIsHovered(false);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div onDragOver={onDragOver} onDrop={onDrop}>
      {showEdit ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            padding: 'var(--none, 0px) var(--1, 0.5rem)',
            gap: 'var(--05, 0.25rem)',
            alignSelf: 'stretch',
          }}
        >
          <Input
            size="sm"
            color="neutral"
            placeholder="Folder name"
            value={newName}
            onChange={handleNameChange}
            fullWidth
            autoFocus
            onFocus={(e) => e.target.select()}
          />
          <RoundButton
            size="sm"
            variant="plain"
            onClick={handleDelete}
            icon={faTrash}
          />
          <RoundButton
            size="sm"
            variant="plain"
            onClick={handleCancel}
            icon={faTimes}
          />
          <RoundButton
            size="sm"
            color="primary"
            variant="solid"
            onClick={onSubmit}
            icon={faCheck}
            sx={{
              background: 'var(--Primary-700, #2391D9)',
            }}
          />
        </Box>
      ) : (
        <>
          <StyledFolder
            onMouseEnter={onHover}
            onMouseLeave={onLeave}
            onClick={handleClick}
          >
            <IconContainer>
              <Icon
                icon={isHovered || isActive ? faFolderOpen : faFolderBookmark}
                style={{
                  color: 'var(--text-tertiary, #32383E)',
                }}
              />
            </IconContainer>
            <Title active={isActive}>{folder?.name}</Title>
            <IconButton
              size="sm"
              variant="plain"
              sx={{
                opacity: isHovered && canEdit ? 1 : 0,
                transition: 'opacity 0.3s ease-in-out',
              }}
              onClick={handleEnableEdit}
            >
              <Icon
                icon={faPencil}
                size="sm"
                style={{
                  color: 'var(--text-tertiary, #32383E)',
                }}
              />
            </IconButton>
          </StyledFolder>
          {isActive && (
            <div>
              {folderWithSearches?.saved_searches?.map((search, index) => {
                return <SavedSearch key={index} search={search} inFolder />;
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Folder;
