import React, { useState, ReactElement, useRef, useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import {
  Box,
  Typography,
  Menu,
  Input,
  Checkbox,
  Dropdown,
  CircularProgress,
} from '@mui/joy';
import { styled } from '@mui/material';
import { UnfoldMore } from '@mui/icons-material';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { useOnClickOutside } from 'src/hooks';
import FilterPill from '../FilterPill';
import { useDashboard } from 'src/context';
import ActivityTypeLabel from '../ActivityTypeLabel';
import { MoreOptionsMenu } from '../MoreOptionsMenu';

const MenuContainer = styled(Menu)({
  display: 'flex',
  width: '240px',
  padding: 'var(--1, 0.5rem)',
  flexDirection: 'column',
  alignItems: 'flex-start',
  borderRadius: '0.5rem',
  border: '1px solid var(--neutral-outlined-Border, #CDD7E1)',
  background: 'var(--background-surface, #FBFCFE)',

  /* shadow-md */
  boxShadow:
    '0px 2px 8px -2px rgba(21, 21, 21, 0.08), 0px 6px 12px -2px rgba(21, 21, 21, 0.08)',
});

interface MultiSelectMenuProps {
  header: string;
  optionType: string;
  enableSearch?: boolean;
  options:
    | { label: string; id: number }[]
    | { label: string; id: number; icon: string; color: string }[];
  startDecorator: ReactElement;
  isActivity?: boolean;
  selectedOptions: string[];
  setSelectedOptions: any;
  onMenuScroll: (isActivity: boolean) => void;
  isLoading: boolean;
}

const MultiSelectMenu = ({
  header,
  optionType,
  enableSearch,
  options,
  startDecorator,
  isActivity = false,
  selectedOptions,
  setSelectedOptions,
  onMenuScroll,
  isLoading,
}: MultiSelectMenuProps) => {
  const menuRef = useRef(null);
  const menuRef2 = useRef(null);
  const { userQueryParams, setUserQueryParams } = useDashboard();

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [allSelected, setAllSelected] = useState(false);

  const [search, setSearch] = useState('');

  const [debouncedSearch] = useDebounce(search, 500);

  useEffect(() => {
    if (!enableSearch) return;
    setUserQueryParams({ ...userQueryParams, q: debouncedSearch });

    return () => {
      setUserQueryParams({ ...userQueryParams, q: '' });
    };
  }, [debouncedSearch]);

  useEffect(() => {
    if (selectedOptions?.length === options?.length) {
      //if all options are selected, change dropdown placeholder to All instead of filterpill
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [options?.length, selectedOptions?.length]);

  const handleOpenMenu = (e) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleMoreOptionsClick = (e) => {
    setAnchorEl2(anchorEl2 ? null : e.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
    setAnchorEl2(null); //if main menu closes ensure more option menu closes as well
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleSearch = (e) => {
    e.stopPropagation();
    setSearch(e.target.value);
  };

  const selectAll = (e) => {
    e.stopPropagation();
    setAllSelected(true);
    setSelectedOptions(
      optionType,
      options.map((opt) => opt.value)
    );
    setAnchorEl2(null);
  };

  const deselectAll = (e) => {
    e.stopPropagation();
    setAllSelected(false);
    setSelectedOptions(optionType, []);
    setAnchorEl2(null);
  };

  const handleCheckboxChange = (e) => {
    e.stopPropagation();
    const value = e.target?.value;

    // If the value is already in the selected options, remove it
    if (selectedOptions?.includes(value)) {
      setSelectedOptions(
        optionType,
        selectedOptions.filter((opt) => opt !== value)
      );
    } else {
      setSelectedOptions(optionType, [...selectedOptions, value]);
    }
  };

  const singleFilterValue = () => {
    const singleFilter = options.find(
      (option) => option.id.toString() === selectedOptions[0]
    );

    return singleFilter && singleFilter.label;
  };

  const isChecked = (option) => {
    return selectedOptions?.includes(option.id.toString());
  };

  const menuRefs = menuRef2 ? [menuRef, menuRef2] : menuRef;
  useOnClickOutside(menuRefs, anchorEl, handleClose1);
  useOnClickOutside(menuRef2, anchorEl2, handleClose2);

  // handles infinite scroll trigger
  const containerRef = useRef(null);

  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollTop + clientHeight >= scrollHeight) {
        onMenuScroll(isActivity);
      }
    }
  };

  return (
    <Dropdown>
      <button
        onClick={handleOpenMenu}
        style={{
          gap: '.5rem',
          alignSelf: 'stretch',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0.375rem 0.5rem 0.375rem 0.75rem',
          width: '10rem' /* 160px */,
          height: '2rem' /* 32px */,
          borderRadius: '3rem',
          border: '1px solid var(--Neutral-200, #DDDFE0)',
          background: 'var(--background-body, #FFF)',
        }}
      >
        <Box
          sx={{
            width: '100%',
          }}
        >
          {/* To ensure that contents take up full width */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {startDecorator}
            {!allSelected && selectedOptions?.length > 1 ? (
              <FilterPill
                selectedOptions={selectedOptions}
                handleClearFilters={deselectAll}
              />
            ) : (
              <Typography
                level="body-sm"
                color="neutral"
                sx={{
                  fontWeight: 400,
                  flex: '1 0 0',
                  textAlign: 'left',
                  color: 'var(--Neutral-800, #2C2E2F)',
                  marginLeft: '.5rem',
                  width: '100px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {!allSelected && selectedOptions?.length === 1
                  ? singleFilterValue()
                  : 'All'}
              </Typography>
            )}
            <UnfoldMore
              fontSize="small"
              sx={{
                fontWeight: 200,
                color: 'var(--neutral-600, #6B7280)',
              }}
            />
          </Box>
        </Box>
      </button>
      <MenuContainer
        ref={menuRef}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        placement="bottom-start"
        autoFocus={false}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0 0 0 var(--1, 0.5rem)',
            }}
          >
            <Typography level="body-sm" sx={{ fontWeight: 500 }}>
              {header}
            </Typography>
            <MoreOptionsMenu
              handleMoreOptionsClick={handleMoreOptionsClick}
              menuRef={menuRef2}
              anchorEl={anchorEl2}
              handleClose={handleClose2}
              selectAll={selectAll}
              deselectAll={deselectAll}
            />
          </Box>
          {enableSearch && (
            <Box sx={{ padding: '.5rem 0' }}>
              <Input
                autoFocus
                id="search-options-input"
                type="search"
                variant="outlined"
                size="sm"
                startDecorator={<Icon icon={faSearch} />}
                placeholder="Search"
                sx={{
                  borderRadius: '3rem',
                  border: '1px solid var(--neutral-outlined-Border, #CDD7E1)',
                  background: 'var(--background-surface, #FBFCFE)',
                  /* shadow-xs */
                  boxShadow: '0px 1px 2px 0px rgba(21, 21, 21, 0.08)',
                  overflow: 'hidden',
                }}
                value={search}
                onChange={handleSearch}
                onFocus={(e) => e.target.select()}
              />
            </Box>
          )}
          <Box
            ref={containerRef}
            onScroll={handleScroll}
            sx={{
              maxHeight: '12.5rem' /* 200px */,
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            {options
              ?.sort((a, b) => {
                const aChecked = selectedOptions?.includes(a.id.toString());
                const bChecked = selectedOptions?.includes(b.id.toString());
                return aChecked === bChecked ? 0 : aChecked ? -1 : 1;
              })
              .map((option) => {
                const label = isActivity ? (
                  <ActivityTypeLabel activityId={option.id} />
                ) : (
                  option.label
                );
                return (
                  <Box
                    key={option.id}
                    sx={{
                      display: 'flex',
                      width: '100%',
                      margin: '0.375rem 0.5rem 0.375rem var(--1, 0.5rem)',
                      alignItems: 'center',
                      gap: '0.75rem',
                      height: '1.5rem',
                    }}
                  >
                    <Checkbox
                      variant="outlined"
                      color="neutral"
                      size="sm"
                      sx={{
                        alignItems: 'center',
                      }}
                      label={label}
                      value={option.id}
                      onChange={handleCheckboxChange}
                      checked={isChecked(option)}
                    />
                  </Box>
                );
              })}
            {isLoading && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress size="sm" />
              </Box>
            )}
          </Box>
        </Box>
      </MenuContainer>
    </Dropdown>
  );
};

export default MultiSelectMenu;
