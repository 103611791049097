import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { Box, Button, Select, Option, Checkbox, Tooltip } from '@mui/joy';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { useGetAllTerritories } from 'src/hooks';
import { getHexUrl } from 'src/api';
import { ReportIFrame } from './components';

const years = [
  { label: '2019', value: '2019' },
  { label: '2020', value: '2020' },
  { label: '2021', value: '2021' },
  { label: '2022', value: '2022' },
  { label: '2023', value: '2023' },
  { label: '2024', value: '2024' },
];

const InsightTech = () => {
  const router = useRouter();
  const { id } = router.query as { id: string };

  const { data: territories } = useGetAllTerritories();
  const [hexUrl, setHexUrl] = useState(null);
  const [territoryId, setTerritoryId] = useState('');
  const [startYear, setStartYear] = useState('');
  const [endYear, setEndYear] = useState('');
  const [includeAffiliates, setIncludeAffiliates] = useState(false);

  const handleTerritoryChange = (e, value) => {
    setTerritoryId(value);
  };

  const handleStartYearChange = (e, value) => {
    setStartYear(value);
  };

  const handleEndYearChange = (e, value) => {
    setEndYear(value);
  };

  const handleAffiliatesChange = (e) => {
    setIncludeAffiliates(e.target.checked);
  };

  const onSubmit = async () => {
    const queryParams = getSearchQueryParamsForAPI({
      provider_id: id,
      territory_id: territoryId,
      start_year: startYear?.length > 0 ? startYear : undefined,
      end_year: endYear?.length > 0 ? endYear : undefined,
      include_affiliates: includeAffiliates ? includeAffiliates : undefined,
    });

    try {
      const response = await getHexUrl({
        reportId: 'insight_tech',
        queryParams,
      });
      if (response) {
        setHexUrl(response);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box
      sx={{
        height: 'calc(100vh - 64px)',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 'var(--1, 0.5rem)',
          alignItems: 'center',
          padding: 'var(--1, 1rem)',
          borderBottom: '1px solid #ccc',
          backgroundColor: '#fff',
        }}
      >
        <Select
          size="sm"
          value={territoryId}
          onChange={handleTerritoryChange}
          slotProps={{
            listbox: {
              placement: 'bottom-start',
            },
          }}
        >
          <Option value="">Select Territory</Option>
          {territories?.map((territory) => (
            <Option key={territory.id} value={territory.id}>
              {territory.name}
            </Option>
          ))}
        </Select>
        <Select
          size="sm"
          value={startYear}
          onChange={handleStartYearChange}
          slotProps={{
            listbox: {
              placement: 'bottom-start',
            },
          }}
        >
          <Option value="">Start Year...</Option>
          {years.map((year) => (
            <Option key={year.value} value={year.value}>
              {year.label}
            </Option>
          ))}
        </Select>
        <Select
          size="sm"
          value={endYear}
          onChange={handleEndYearChange}
          slotProps={{
            listbox: {
              placement: 'bottom-start',
            },
          }}
        >
          <Option value="">End Year...</Option>
          {years.map((year) => (
            <Option key={year.value} value={year.value}>
              {year.label}
            </Option>
          ))}
        </Select>
        <Checkbox
          size="sm"
          label="Include Affiliates"
          checked={includeAffiliates}
          onChange={handleAffiliatesChange}
        />
        <Tooltip
          title="Territory is required"
          placement="bottom"
          arrow
          color="neutral"
          variant="soft"
          disableFocusListener={territoryId !== ''}
          disableHoverListener={territoryId !== ''}
          disableTouchListener={territoryId !== ''}
        >
          <span>
            <Button size="sm" onClick={onSubmit} disabled={!territoryId}>
              Show Report
            </Button>
          </span>
        </Tooltip>
      </Box>
      <ReportIFrame url={hexUrl?.embedded_url} />
    </Box>
  );
};

export default InsightTech;
