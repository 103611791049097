import { request } from 'src/api/request';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

interface GetExportProps {
  tableType: string;
  fileType: string;
  columns: string[];
  searchParams: {
    enabledSearchIds?: string[];
    dateRange?: {
      lte: string;
      gte: string;
    };
    providerId: string;
    page: number;
    pageSize: number;
    sort: any;
    order: any;
    sort_search: any;
    q: string;
    volume_type: 'claims' | 'patients';
  };
}

export const getTreatingReferringExport = async ({
  searchParams,
  tableType,
  fileType,
  columns,
}: GetExportProps) => {
  const {
    sort,
    order,
    sort_search,
    page,
    pageSize,
    q,
    volume_type,
    dateRange,
    enabledSearchIds,
    providerId,
  } = searchParams;
  let queryParams = getSearchQueryParamsForAPI({
    sort,
    order,
    sort_search,
    page: page + 1,
    pageSize,
    q,
    volume_type,
    dateRange,
  });

  const searchIdParams = enabledSearchIds
    ?.map(
      (id) => `&${encodeURIComponent('saved_search')}=${encodeURIComponent(id)}`
    )
    .join('');

  queryParams += searchIdParams;

  const url = `https://api.medscout.io/api/v1/entities/entity/${providerId}/affiliations/${tableType}/${queryParams}`;

  return request({
    url: '/v1/admin/export/',
    method: 'POST',
    data: {
      url,
      file_format: fileType,
      columns: columns,
    },
  });
};
