import React, { useEffect, useMemo } from 'react';
import { Box, Chip } from '@mui/joy';
import { useDebounce } from 'use-debounce';
import { useAuth, useLogging, useNewProfile, useSite } from 'src/context';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-solid-svg-icons';
import { Section } from './components';
import { ClickAwayListener } from 'src/components';
import { sections } from './constants';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useRouter } from 'next/router';

interface SavedSearchFilterProps {
  context?: 'provider' | 'site';
  multiple?: boolean;
  startDecorator?: IconProp;
  endDecorator?: IconProp;
  maxVisible?: number;
}

const SavedSearchFilter = ({
  context,
  multiple = false,
  startDecorator,
  endDecorator,
  maxVisible = 1,
}: SavedSearchFilterProps) => {
  const log = useLogging();
  const { user } = useAuth();
  const router = useRouter();
  const { type: location } = router.query as { type: string };
  const { state, dispatch } = useSite();
  const { setSiteProviderQueryParams, setVolumeByLocationQueryParams } =
    useNewProfile();

  const selectedSavedSearch = multiple
    ? state?.siteProvider[location]?.selectedSearches
    : state?.savedSearch?.filterBySavedSearch?.selectedSavedSearch;

  const locationMap = {
    provider: 'LocationMix',
    site: 'TopProviders',
  };

  // query params to use
  const queryParamsMap = {
    provider: setVolumeByLocationQueryParams,
    site: setSiteProviderQueryParams,
  };

  const [showSearches, setShowSearches] = React.useState(false);
  const [showAll, setShowAll] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);

  const isValidSavedSearch = useMemo(() => {
    if (!selectedSavedSearch || multiple) return true;

    const hasProcedureCodes =
      selectedSavedSearch?.filters?.cpt?.length > 0 ||
      selectedSavedSearch?.filters?.hcpcs?.length > 0 ||
      selectedSavedSearch?.filters?.icd?.length > 0 ||
      selectedSavedSearch?.filters?.drg?.length > 0 ||
      selectedSavedSearch?.filters?.icdp?.length > 0;

    return hasProcedureCodes;
  }, [multiple, selectedSavedSearch]);

  // group by type
  const groupedData = useMemo(() => {
    if (!state.savedSearch?.folders) return [];
    const folders = state.savedSearch.folders;
    const tempFolders = state.savedSearch?.tempFolders || [];

    const combined = [...tempFolders, ...folders];
    // group by type to remain in an array
    const grouped = combined?.reduce((acc, item) => {
      if (!acc[item.type]) {
        acc[item.type] = [];
      }

      acc[item.type].push(item);
      return acc;
    }, {});

    return Object.entries(grouped).map(([type, results]) => ({
      type,
      results,
    }));
  }, [state?.savedSearch?.folders, state?.savedSearch?.tempFolders]);

  useEffect(() => {
    // if we select a saved search, clear the search value
    // this only applies to single select
    if (selectedSavedSearch && !multiple) {
      setSearchValue('');
    }
  }, [multiple, selectedSavedSearch]);

  const handleSearch = (e) => {
    // we need to clear the selected search if we are typing
    // this only applies to single select
    if (selectedSavedSearch && !multiple) {
      dispatch({
        type: 'SET_SELECTED_SAVED_SEARCH',
        payload: null,
      });
    }

    setSearchValue(e.target.value);
  };

  const toggleAccordion = (index) => {
    const newIndex = state?.savedSearch?.openAccordion === index ? null : index;
    dispatch({
      type: 'SET_ACCORDION',
      payload: newIndex,
    });

    // clear search value
    setSearchValue('');

    log.event('toggleSavedSearchAccordion', {
      location: 'SavedSearch',
      action: 'onSubmit',
      object: index === 0 ? 'PERSONAL' : 'COMPANY',
      user: user,
    });
  };

  const handleShowSearches = () => {
    setShowSearches((prev) => !prev);
  };

  const handleClose = () => {
    setShowSearches(false);
    setSearchValue('');
    setShowAll(false);
  };

  const handleClearSearch = (e) => {
    e.stopPropagation();
    setSearchValue('');

    if (!multiple) {
      dispatch({
        type: 'SET_SELECTED_SAVED_SEARCH',
        payload: null,
      });

      queryParamsMap[context]({
        cpt: [],
        hcpcs: [],
        icd: [],
        drg: [],
        icdp: [],
      });
    }

    log.event('Saved Search Cleared', {
      source: locationMap[context],
    });
  };

  const handleRemoveSearch = (e, search) => {
    e.stopPropagation();
    const newValues = selectedSavedSearch.filter(
      (selected) => selected.id !== search.id
    );

    dispatch({
      type: 'SET_SELECTED_SEARCHES',
      payload: { location, selectedSearches: Array.from(newValues) },
    });
    log.event('Saved Search Chip Removed', {
      source: locationMap[context],
      search: search,
    });
  };

  const handleInputClick = (e) => {
    e.stopPropagation();
    setShowSearches(true);
    setShowAll(true);
  };

  const handleSavedSearchChange = (e, search) => {
    e.stopPropagation();

    if (multiple && !context) {
      // remove duplicates
      const newValues = new Set([...selectedSavedSearch, search]);
      dispatch({
        type: 'SET_SELECTED_SEARCHES',
        payload: { location, selectedSearches: Array.from(newValues) },
      });
    } else if (!multiple && context) {
      dispatch({
        type: 'SET_SELECTED_SAVED_SEARCH',
        payload: search,
      });
      setShowSearches(false);

      let cpt = [];
      let hcpcs = [];
      let icd = [];
      let drg = [];
      let icdp = [];

      // Add cpt and hcpcs arrays to the newValues array
      if (search?.filters?.cpt?.length > 0) {
        cpt = [...search.filters.cpt];
      }

      if (search?.filters?.hcpcs?.length > 0) {
        hcpcs = [...search.filters.hcpcs];
      }

      if (search?.filters?.icd?.length > 0) {
        icd = [...search.filters.icd];
      }

      if (search?.filters?.drg?.length > 0) {
        drg = [...search.filters.drg];
      }

      if (search?.filters?.icdp?.length > 0) {
        icdp = [...search.filters.icdp];
      }

      queryParamsMap[context]({
        cpt,
        hcpcs,
        icd,
        drg,
        icdp,
      });
    }

    log.event('Saved Search Clicked', {
      source: locationMap[context],
      search: search,
    });
  };

  const handleShowAll = (e) => {
    e.stopPropagation();
    setShowAll(!showAll);
  };

  // visible searches if multiple
  const { visible, remaining } = useMemo(() => {
    if (!selectedSavedSearch || !multiple)
      return { visible: [], remaining: [] };

    if (showAll) {
      return { visible: selectedSavedSearch, remaining: [] };
    }

    const visible = selectedSavedSearch.slice(0, maxVisible);
    const remaining = selectedSavedSearch.slice(maxVisible);

    return { visible, remaining };
  }, [maxVisible, multiple, selectedSavedSearch, showAll]);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box sx={{ flex: 1, position: 'relative' }} onClick={handleShowSearches}>
        <Box
          sx={{
            height: showAll ? 'auto' : '2.25rem',
            width: '100%',
            border: '1px solid #d2dbe4',
            background: '#fbfcfe',
            display: 'flex',
            alignItems: 'center',
            padding: '0 0.75rem',
            borderRadius: 'md',
            gap: '0.5rem',
            '&:focus-within': {
              outline: '1px solid #0077FF',
            },
            /* --joy-shadow-xs */
            boxShadow: '0px 1px 2px 0px rgba(21, 21, 21, 0.08)',
          }}
        >
          {startDecorator && (
            <Icon
              icon={startDecorator}
              color="#555E68"
              size="sm"
              style={{
                cursor: 'pointer',
              }}
            />
          )}
          {multiple && selectedSavedSearch?.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',

                maxWidth: showAll ? '100%' : 'calc(100% - 2rem)',
                gap: '0.25rem',
                whiteSpace: showAll ? 'none' : 'nowrap',
                flexWrap: showAll ? 'wrap' : 'nowrap',
                padding: showAll ? '0.25rem' : '0',
              }}
            >
              {multiple &&
                visible?.map((search, index) => (
                  <Chip
                    key={index}
                    variant="plain"
                    color="neutral"
                    sx={{
                      minWidth: 0,
                      backgroundColor: '#D6F6F1',
                      color: '#009976',
                      cursor: 'pointer',
                      '& .MuiChip-action': {
                        background: '#D6F6F1 !important',
                        '&:hover': {
                          background: '#CBE9E4 !important',
                        },
                      },
                    }}
                    onClick={(e) => handleRemoveSearch(e, search)}
                    endDecorator={<Icon icon={faClose} />}
                  >
                    {search.search_name}
                  </Chip>
                ))}
              {remaining.length > 0 && (
                <Chip onClick={handleShowAll}>+{remaining.length}</Chip>
              )}
            </Box>
          )}
          <input
            type="text"
            placeholder="Filter by a saved search..."
            value={searchValue || selectedSavedSearch?.search_name || ''}
            onChange={handleSearch}
            onClick={handleInputClick}
            onFocus={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
            style={{
              flex: 1,
              fontSize: '1rem',
              fontWeight: 400,
              lineHeight: '1.5rem',
              color: 'text.primary',
              border: 'none',
              outline: 'none',
              ...(!isValidSavedSearch && {
                color: 'text.disabled',
                opacity: 0.5,
              }),
              padding: '0.33rem',
              background: 'transparent',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent:
                selectedSavedSearch && !multiple ? 'space-between' : 'flex-end',
              gap: '0.33rem',
              width: '2rem',
            }}
          >
            {selectedSavedSearch && !multiple && (
              <Icon
                icon={faClose}
                color="#555E68"
                onClick={handleClearSearch}
                size="sm"
                style={{
                  cursor: 'pointer',
                  zIndex: 9999,
                }}
              />
            )}
            {endDecorator && (
              <Icon
                icon={endDecorator}
                color="#555E68"
                style={{
                  cursor: 'pointer',
                  fontSize: '0.66rem',
                }}
              />
            )}
          </Box>
        </Box>
        {showSearches && (
          <Box
            sx={{
              position: 'absolute',
              top: '100%',
              left: 0,
              width: '100%',
              zIndex: 9999,
              marginTop: '0.25rem',
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                minHeight: '10rem',
                maxHeight: '20rem',
                overflow: 'hidden',
                overflowY: 'auto',
                border: '1px solid #E0E0E0',
                borderRadius: 'sm',
                boxShadow: 'sm',
                background: 'white',
              }}
            >
              {sections.map((name, index) => {
                const section = groupedData.find(
                  (group) => group.type === name
                );
                return (
                  <Section
                    key={index}
                    multiple={multiple}
                    index={index}
                    context={context}
                    name={name}
                    section={section}
                    searchValue={debouncedSearchValue}
                    onToggle={toggleAccordion}
                    onChange={handleSavedSearchChange}
                  />
                );
              })}
            </Box>
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default SavedSearchFilter;
