import useSWR from 'swr';
import { getActivities } from 'src/api';
import { QUERY_KEYS } from './constants';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { exists } from 'src/utils';

interface GetActivitiesParams {
  reps?: string[];
  activityTypes?: string[];
  statuses?: {
    [key: string]: string[];
  };
  timeframe?: string;
  sort?: string;
  order?: 'asc' | 'desc';
  page?: number;
  pageSize?: number;
  q?: string;
}

const useGetActivities = ({
  reps,
  activityTypes,
  statuses,
  timeframe,
  sort,
  order,
  page,
  pageSize,
  q,
}: GetActivitiesParams) => {
  const queryParams = getSearchQueryParamsForAPI({
    sort,
    order,
    page: page + 1,
    pageSize,
    q,
  });

  const compileFilterQuery = (key: string, items: any[]) =>
    items?.length ? items.map((item) => `${key}=${item}`).join('&') : undefined;

  const repQuery = compileFilterQuery('rep', reps);
  const activityQuery = compileFilterQuery('activity_type', activityTypes);

  const formatStatusQuery = () => {
    const compiledStatuses = [];

    statuses &&
      Object.entries(statuses).forEach(([crm_type, statusArray]) => {
        (statusArray as string[]).forEach((crm_status) => {
          compiledStatuses.push({ crm_type, crm_status });
        });
      });

    return compiledStatuses
      .map(
        (status, index) =>
          `crm_type_${index + 1}=${status.crm_type}&crm_status_${index + 1}=${
            status.crm_status
          }`
      )
      .join('&');
  };

  const statusQuery = formatStatusQuery();

  const timeframeQuery = exists(timeframe)
    ? `time_range=${timeframe}`
    : undefined;

  const queryString = [
    queryParams,
    repQuery,
    activityQuery,
    statusQuery,
    timeframeQuery,
  ]
    .filter(Boolean)
    .join('&');

  return useSWR(
    [QUERY_KEYS.GET_ACTIVITIES, queryString],
    () => getActivities({ queryString }),
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
    }
  );
};

export default useGetActivities;
