import { useMemo } from 'react';
import { CircularProgress } from 'src/components';
import { useGetSearchProviderDetails } from 'src/hooks';
import { useProspectSearch } from 'src/context';

const DetailsPopover = ({ row, show }) => {
  const { provider_id, details_params } = row;
  const { prospectVolumeType } = useProspectSearch();

  const { data, isLoading } = useGetSearchProviderDetails({
    provider_id,
    details_params,
    enabled: show,
  });

  const sortedRecordsByClaims = useMemo(() => {
    return data?.sort((a, b) => {
      const getClaimsValue = (detail) => {
        if (!detail.claims || detail.claims === '<11') return -1;
        return Number(detail.claims);
      };

      const claimsComparison = getClaimsValue(b) - getClaimsValue(a);
      if (claimsComparison !== 0) return claimsComparison;

      const yearComparison = b.year - a.year;
      if (yearComparison !== 0) return yearComparison;

      return b.payment - a.payment;
    });
  }, [data]);

  const sortedRecordsByPatients = useMemo(() => {
    return data?.sort((a, b) => {
      const getPatientsValue = (detail) => {
        if (!detail.patients || detail.patients === '<11') return -1;
        return Number(detail.patients);
      };

      const patientsComparison = getPatientsValue(b) - getPatientsValue(a);
      if (patientsComparison !== 0) return patientsComparison;

      const yearComparison = b.year - a.year;
      if (yearComparison !== 0) return yearComparison;

      return b.payment - a.payment;
    });
  }, [data]);

  return (
    <div className="w-full h-full" onClick={(e) => e.stopPropagation()}>
      <div className="h-full bg-gray-200 border-b text-md px-2 py-1 font-medium ">
        Detailed Breakdown
      </div>
      <div className="h-full overflow-y-auto max-h-64">
        {isLoading && (
          <div className="min-h-32 flex items-center justify-center text-center">
            <CircularProgress size="sm" color="neutral" />
          </div>
        )}
        {!details_params && (
          <div style={{ padding: 10, fontSize: 14 }}>
            <em>
              There are no expanded details available for this record. Please
              open their profile to view more information.
            </em>
          </div>
        )}
        {(prospectVolumeType === 'claims'
          ? sortedRecordsByClaims
          : sortedRecordsByPatients
        )?.map((row, idx) => {
          const isFirst = idx === 0;
          const isIndustryPaymentType = row.type === 'payments';
          return (
            <div
              key={`${row.code || row.drug}_${row.year}`}
              className={`border-t ${
                isFirst ? 'border-t-0' : 'border-t'
              } border-gray-200 p-2 ${isFirst ? 'pt-0' : 'pt-1'} pb-1`}
            >
              {isIndustryPaymentType && (
                <p>
                  <strong>Open Payment:</strong>{' '}
                  {row.payment?.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </p>
              )}
              {'claims' in row && prospectVolumeType === 'claims' && (
                <p>
                  <strong>Claims:</strong>{' '}
                  {row.claims === '<11'
                    ? row.claims
                    : row.claims?.toLocaleString('en-US')}
                </p>
              )}
              {'patients' in row && prospectVolumeType === 'patients' && (
                <p>
                  <strong>Patients:</strong>{' '}
                  {row.patients?.toLocaleString('en-US')}
                </p>
              )}
              {'company' in row && (
                <p style={{ fontSize: 12 }}>
                  <strong>Company:</strong> {row.company}
                </p>
              )}
              {'code' in row && (
                <p style={{ fontSize: 12 }}>
                  <strong>Code:</strong> {row.code} ({row.description})
                </p>
              )}
              {'drug' in row && (
                <p style={{ fontSize: 12 }}>
                  <strong>Drug:</strong> {row.drug}{' '}
                  {row?.generic && `(${row.generic})`}
                </p>
              )}
              {'year' in row && (
                <p style={{ fontSize: 12 }}>
                  <strong>Year:</strong> {row.year}
                </p>
              )}
              {!isIndustryPaymentType && 'avg_reimbursement' in row && (
                <p style={{ fontSize: 12 }}>
                  <strong>Reimbursement:</strong>{' '}
                  {row.avg_reimbursement?.toLocaleString('en-US', {
                    minimumFractionDigits: 0, // Allow no decimal places
                    maximumFractionDigits: 2, // Up to 2 decimal places
                  })}
                </p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DetailsPopover;
