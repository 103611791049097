import { ListActionTypes } from './actionTypes';

export type ListState = {
  formattedProspectLists: any[];
};

export const initialListState: ListState = {
  formattedProspectLists: [],
};

export const listReducer = (
  state: ListState = initialListState,
  action: any
): ListState => {
  switch (action.type) {
    case ListActionTypes.SET_FORMATTED_PROSPECT_LISTS:
      return {
        ...state,
        formattedProspectLists: action.payload,
      };
    default:
      return state;
  }
};
