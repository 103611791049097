import React, { useEffect, useMemo, useState } from 'react';
import { useLogging } from 'src/context';
import { Box, Typography } from '@mui/joy';
import { styled } from '@mui/material';
import { ActivityIcon } from '../ActivityIcon';
import { daysSince } from 'src/utils';
import { AddEditActivity } from '../AddEditActivity';
import { activityMap } from '../../constants';
import { useGetActivityTypes } from 'src/hooks';

const ActivityItemContainer = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  gap: 'var(--2, 1rem)',
  alignSelf: 'stretch',
});

const ActivityDetails = styled(Typography)({
  color: 'var(--Neutral-800, #2C2E2F)',
  fontFamily: 'Inter',
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '1.453rem' /* 23.24px */,
});

const ActivityType = styled(Typography)({
  color: 'var(--Neutral-800, #2C2E2F)',
  fontFamily: 'Inter',
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '1.593rem' /* 25.48px */,
});

const ActivityOwnerContainer = styled(Box)({
  display: 'flex',
  padding: '0.25rem 0px',
  alignItems: 'center',
  alignSelf: 'stretch',
});

const ActivityOwner = styled(Typography)({
  color: 'var(--Neutral-600, #5D5E5F)',
  fontFamily: 'Inter',
  fontSize: '0.813rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '1.186rem' /* 18.98px */,
});

const ActivityDate = styled(Typography)({
  color: 'var(--Neutral-600, #5D5E5F)',
  textAlign: 'right',
  fontFamily: 'Inter',
  fontSize: '0.813rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '1.243rem' /* 19.88px */,
});

const IconContainer = styled(Box)({
  width: '32px', // specific to keep the icon centered
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

const ExpandButton = styled(Typography)({
  color: 'var(--Primary-800, #207FC5)',
  fontFamily: 'Inter',
  fontSize: '0.813rem',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '1.186rem' /* 18.98px */,
  cursor: 'pointer',
});

const EditButton = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isHovered',
})<{ isHovered: boolean }>(({ isHovered }) => ({
  color: 'var(--Neutral-600, #5D5E5F)',
  fontFamily: 'Inter',
  fontSize: '0.813rem',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '1.186rem' /* 18.98px */,
  cursor: 'pointer',
  opacity: isHovered ? 1 : 0,
  transition: 'opacity 0.3s ease-in-out',
}));

const DeleteButton = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isHovered',
})<{ isHovered: boolean }>(({ isHovered }) => ({
  color: '#FF561D',
  fontFamily: 'Inter',
  fontSize: '0.813rem',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '1.186rem' /* 18.98px */,
  cursor: 'pointer',
  opacity: isHovered ? 1 : 0,
  transition: 'opacity 0.3s ease-in-out',
}));

const ActivityItem = ({ item, onDelete }) => {
  const log = useLogging();
  const [isEditing, setIsEditing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [text, setText] = useState('');

  const { data: activityTypes } = useGetActivityTypes();

  useEffect(() => {
    if (!item || !item.details) return;
    if (item.details?.length > 165 && !isExpanded) {
      // take the first 165 characters of the details
      setText(item.details.slice(0, 165) + '...');
    } else {
      setText(item.details);
    }
  }, [item, isExpanded]);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
    log.event('Expand Activity', {
      location: 'Activity Item',
      data: item,
    });
  };

  const handleEnableEdit = () => {
    setIsEditing(true);
    log.event('Enable Edit Activity', {
      location: 'Activity Item',
      data: item,
    });
  };

  const handleDelete = () => {
    onDelete(item.id);
  };

  const isTruncated = item?.details?.length > 165;
  const activity = useMemo(() => {
    return activityTypes?.results?.find((i) => i.id === item?.type);
  }, [activityTypes, item]);

  return (
    <ActivityItemContainer
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isEditing ? (
        <AddEditActivity
          item={item}
          onClose={() => setIsEditing(false)}
          onCancel={() => setIsEditing(false)}
        />
      ) : (
        <>
          <IconContainer>
            <ActivityIcon item={activity} />
          </IconContainer>
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <ActivityType>{activity?.label}</ActivityType>

              <ActivityDate>
                {daysSince(item?.created_at) || item?.created_at}
              </ActivityDate>
            </Box>
            <ActivityOwnerContainer>
              <ActivityOwner>
                {`${item?.owner?.first_name} ${item?.owner?.last_name}`}
              </ActivityOwner>
            </ActivityOwnerContainer>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.25rem',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  height: 'auto',
                  alignSelf: 'stretch',
                }}
              >
                <ActivityDetails>{text}</ActivityDetails>
              </Box>
              <Box
                sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
              >
                {isTruncated && (
                  <ExpandButton onClick={handleExpand}>
                    {isExpanded ? 'Read less' : 'Read more'}
                  </ExpandButton>
                )}
                <EditButton isHovered={isHovered} onClick={handleEnableEdit}>
                  Edit
                </EditButton>
                <DeleteButton isHovered={isHovered} onClick={handleDelete}>
                  Delete
                </DeleteButton>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </ActivityItemContainer>
  );
};

export default ActivityItem;
