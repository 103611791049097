import {
  Box,
  useTheme,
  styled,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { BrandIcon } from 'src/components';
import { grey } from '@mui/material/colors';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { truncateString } from 'src/utils';

// Regular button styles
const StyledCRMButton = styled(LoadingButton)<{ newHeaderView: boolean }>(
  ({ theme, newHeaderView }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 0,
    borderBottomLeftRadius: newHeaderView ? '0' : '0.95rem',
    whiteSpace: 'nowrap',
    lineHeight: '1.25rem',
    marginRight: '-1px',
    gap: '0.25rem',
  })
);

const StyledCRMWrapper = styled(Box)<{ newHeaderView: boolean }>(
  ({ theme, newHeaderView = false }) => ({
    borderRadius: newHeaderView ? '0.5rem' : '1rem',
    backgroundColor: newHeaderView ? '#2391D9' : 'white',
    borderTopLeftRadius: newHeaderView ? '0.5rem' : '0',
    borderBottomRightRadius: newHeaderView ? '0.5rem' : '0',
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem',
    paddingLeft: '0.5rem',
    overflow: 'hidden',
    cursor: 'pointer',
  })
);

const StyledText = styled('span')<{ newHeaderView: boolean }>(
  ({ theme, newHeaderView }) => ({
    fontSize: newHeaderView ? '14px' : '0.65rem',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    lineHeight: '1.25rem',
    marginRight: '-1px',
    width: '100%',
    paddingInline: newHeaderView ? '0' : '0.15rem',
    color: newHeaderView ? 'white' : 'inherit',
  })
);

// Small button styles
const SmallButton = styled(LoadingButton)<{
  loading: boolean;
  hasLinks: boolean;
  newHeaderView?: boolean;
}>(({ theme, loading, hasLinks, newHeaderView }) => ({
  px: '0.5rem',
  border: `1px solid ${loading ? grey[300] : '#2391D9'}`,
  borderRadius: 0,
  borderBottomLeftRadius: newHeaderView ? '0.375rem' : '0.95rem',
  borderTopRightRadius: newHeaderView ? '0.375rem' : '0.95rem',
  borderBottomRightRadius: '0.375rem',
  borderTopLeftRadius: '0.375rem',
  minWidth: 0,
  display: 'flex',
  alignItems: 'center',
  gap: '0.25rem',
  boxShadow: 'none',
  backgroundColor:
    newHeaderView && !hasLinks
      ? '#2391D9'
      : hasLinks
      ? 'white'
      : theme.palette.primary.main,
}));

const PushToCrm: React.FC<{
  hasLinks?: boolean;
  integration?: string;
  disabled?: boolean;
  sx?: SystemStyleObject;
  isLoading?: boolean;
  label: string;
  variant?: 'compact' | 'regular';
  onSubmit: (e) => void;
  newHeaderView?: boolean;
}> = ({
  hasLinks = false,
  integration = 'salesforce',
  sx = {},
  isLoading = false,
  disabled = false,
  label = 'Push to CRM',
  variant = 'regular',
  onSubmit,
  newHeaderView = false,
}) => {
  const theme = useTheme();

  switch (variant) {
    case 'compact':
      return (
        <Tooltip title={label} disableHoverListener={!hasLinks}>
          <span>
            <SmallButton
              variant={hasLinks ? 'outlined' : 'contained'}
              loading={isLoading}
              onClick={onSubmit}
              hasLinks={hasLinks}
              disabled={disabled}
              sx={{
                p: '0.5rem',
              }}
              newHeaderView={newHeaderView}
            >
              {isLoading ? (
                <CircularProgress size={15} />
              ) : (
                <>
                  <BrandIcon
                    brand={integration?.toLowerCase() || 'salesforce'}
                    width="1.1rem"
                    height="1rem"
                    invert={!hasLinks}
                  />
                  {hasLinks && <Icon icon={faChevronDown} size="sm" />}
                </>
              )}
            </SmallButton>
          </span>
        </Tooltip>
      );
      break;
    case 'regular':
      return (
        <StyledCRMWrapper
          onClick={onSubmit}
          sx={{
            border: `1px solid ${
              isLoading
                ? grey[300]
                : newHeaderView
                ? '#2391D9'
                : theme.palette.primary.main
            }`,
            height: newHeaderView ? '2.2rem' : '1.75rem',
            ...sx,
          }}
          newHeaderView={newHeaderView}
        >
          <BrandIcon
            brand={integration?.toLowerCase() || 'salesforce'}
            width="20px"
            height="15px"
            invert={newHeaderView}
          />
          <Tooltip title={label} disableHoverListener={!hasLinks}>
            <span>
              <StyledCRMButton
                variant={
                  newHeaderView ? 'text' : hasLinks ? 'outlined' : 'contained'
                }
                size="small"
                sx={
                  newHeaderView
                    ? {
                        pl: 0,
                        pr: '1rem',
                        '&:hover': { background: 'none' },
                      }
                    : {
                        px: '0.5rem',
                      }
                }
                loading={isLoading}
                disabled={disabled}
                newHeaderView={newHeaderView}
              >
                <StyledText newHeaderView={newHeaderView}>
                  {truncateString(label, 12)}
                </StyledText>
                {hasLinks && (
                  <Icon
                    icon={faChevronDown}
                    size="sm"
                    color={newHeaderView ? 'white' : 'inherit'}
                  />
                )}
              </StyledCRMButton>
            </span>
          </Tooltip>
        </StyledCRMWrapper>
      );
    default:
      break;
  }
};

export default PushToCrm;
