import { Box, Card, Container, Grid } from '@mui/joy';
import { styled } from '@mui/material';
import React from 'react';
import { ActivityList } from './components/ActivityList';

const ActivityColumn = styled(Box)({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '1rem',
});

const ActivityContainer = styled(Box)({
  display: 'flex',
  padding: 'var(--none, 0px) ',
  flexDirection: 'column',
  alignItems: 'flex-end',
  gap: '1rem',
  alignSelf: 'stretch',
});

const ActivityCard = styled(Card)({
  position: 'relative',
  display: 'flex',
  padding: 'var(--3, 1.5rem) var(--2, 1rem)',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 'var(--none, 0px)',
  alignSelf: 'stretch',
  borderRadius: '0.375rem',
  background: 'var(--common-white, #FFF)',
  boxShadow: '0px 0px 0.25rem 0.063rem rgba(0, 0, 0, 0.08)',
});

const ActivityLog = () => {
  return (
    <Container maxWidth="lg" disableGutters>
      <Grid container>
        <Grid xs={12} md={8}>
          <ActivityColumn>
            <ActivityContainer>
              <ActivityCard>
                <ActivityList showLoading />
              </ActivityCard>
            </ActivityContainer>
          </ActivityColumn>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ActivityLog;
