import { Typography } from '@mui/joy';
import React from 'react';

const TrendVolumeHeader = ({ field, ...props }) => {
  return (
    <Typography
      level="title-sm"
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      {props?.colDef?.headerName}
    </Typography>
  );
};

export default TrendVolumeHeader;
