import { Button, Box, styled } from '@mui/material';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { IconButton } from '@mui/joy';

const StyledListButtonWrapper = styled(Box)<{
  variant: 'pill' | 'rectangle' | 'compact';
}>(({ theme, variant }) => ({
  border: `1px solid #CDD7E1`,
  borderRadius: variant === 'pill' ? '0' : '0.5rem',
  backgroundColor: 'white',
  borderTopLeftRadius: variant === 'pill' ? '1rem' : '0.5rem',
  borderBottomRightRadius: variant === 'pill' ? '1rem' : '0.5rem',
  display: 'flex',
  alignItems: 'center',
  gap: variant === 'pill' ? '0.25rem' : '0.5rem',
  paddingLeft: '0.5rem',
  overflow: 'hidden',
  cursor: 'pointer',
  '&:hover': { background: '#EFEFF1' },
}));

const StyledListButton = styled(Button)`
  font-size: 0.65rem;
  font-weight: 400;
  white-space: nowrap;
  border-radius: 0;
`;

const AddToListButton: React.FC<{
  onClick: () => void;
  variant?: 'rectangle' | 'pill' | 'compact';
}> = ({ onClick, variant = 'rectangle' }) => {
  const isCompact = variant === 'compact';

  return (
    <>
      {isCompact ? (
        <IconButton
          onClick={onClick}
          color="neutral"
          variant="outlined"
          aria-label="Add to List"
        >
          <Icon icon={faPlus} size="sm" />
        </IconButton>
      ) : (
        <StyledListButtonWrapper variant={variant} onClick={onClick}>
          <Icon icon={faPlus} color="#636B74" size="sm" />
          <StyledListButton
            variant="text"
            size="small"
            sx={
              variant === 'pill'
                ? {
                    borderTopLeftRadius: '0.95rem',
                    px: '1rem',
                  }
                : {
                    pl: 0,
                    pr: '1rem',
                    color: '#636B74',
                    fontSize: '14px',
                    fontWeight: 600,
                    '&:hover': { background: 'none' },
                  }
            }
          >
            Add to List
          </StyledListButton>
        </StyledListButtonWrapper>
      )}
    </>
  );
};

export default AddToListButton;
