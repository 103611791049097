import React from 'react';
import { Button } from '@mui/joy';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/pro-regular-svg-icons';

const ReportDataIssueButton = ({ onClick }) => {
  return (
    <div className="w-full flex justify-center">
      <Button
        size="sm"
        variant="plain"
        onClick={onClick}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '0.5rem',
        }}
      >
        <Icon icon={faBullhorn} />
        <span>Report Data Issue</span>
      </Button>
    </div>
  );
};

export default ReportDataIssueButton;
