import { useEffect } from 'react';
import { useGetSavedSearches } from 'src/hooks';
import NewSavedSearchDialog from './NewSavedSearchDialog';
import { useLayoutControl, useProspectSearch, useAuth } from 'src/context';
import { faEllipsisV, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FilterDrawer } from './components';
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { IconButton } from 'src/components';

const SearchShade = () => {
  const { user } = useAuth();
  const {
    toggleShowFilterDrawer,
    setShowFilterDrawer,
    showFilterDrawer,
    showAddSavedSearchDialog,
    setShowAddSavedSearchDialog,
  } = useLayoutControl();
  const { prospectSearch, prospectFilters, isCustom } = useProspectSearch();
  const { data: savedSearches = [], isLoading: loadingSearches } =
    useGetSavedSearches();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const noSearch = !prospectSearch.id;
  const noFilters = !prospectFilters.length;
  let conditionalLabel = `${
    savedSearches.length <= 0 ? 'Add' : 'Select'
  } a search...`;
  if (noSearch && !noFilters && isCustom) {
    // No search selected, filters selected
    conditionalLabel = _.truncate(
      `* ${prospectFilters.map((filter) => filter.title).join(', ')}`,
      { length: 27 }
    );
  } else if (!noSearch && isCustom) {
    // Search selected, but ad-hoc
    conditionalLabel = _.truncate(
      `* ${
        prospectSearch?.search_name || prospectSearch?.name || 'Saved Search'
      }`,
      { length: 27 }
    );
  } else if (!noSearch && !noFilters && !isCustom) {
    // Search selected, filters selected, unmodified
    conditionalLabel = _.truncate(
      prospectSearch?.search_name || prospectSearch?.name || 'Saved Search'
    );
  }

  // Open the filter drawer if no search is selected
  useEffect(() => {
    if (prospectSearch?.id || !user?.company?.terms_accepted_at) return;
    setShowFilterDrawer(true);
  }, [user?.company?.terms_accepted_at, prospectSearch?.id]);

  return (
    <>
      {isMobile && (
        <IconButton
          icon={showFilterDrawer ? faTimes : faEllipsisV}
          aria-label="open prospect search manager"
          onClick={() => toggleShowFilterDrawer()}
          sx={{ ml: 1, mr: 3, color: 'white', '&:hover': { color: 'white' } }}
        />
      )}
      {!isMobile && (
        <Button
          variant="contained"
          onClick={() => {
            toggleShowFilterDrawer();
          }}
          aria-label="open prospect search manager"
          endIcon={<Icon icon={showFilterDrawer ? faTimes : faEllipsisV} />}
          sx={{
            width: '14rem',
            mr: 1,
            backgroundColor: 'primary.light',
            boxShadow: 'inset 1px 1px 5px 0 rgba(0, 0, 0, 0.25)',
            border: '1px solid white',
            color: 'fill.main',
            whiteSpace: 'nowrap',
            '&:hover, &:disabled': {
              backgroundColor: 'primary.light',
              boxShadow: 'inset 1px 1px 5px 0 rgba(0, 0, 0, 0.5)',
            },
            '& .MuiButton-endIcon': {
              flexGrow: 1,
              justifyContent: 'flex-end',
            },
          }}
        >
          <Typography
            sx={{
              fontSize: '0.875rem',
              fontWeight: 600,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {conditionalLabel}
          </Typography>
        </Button>
      )}

      <FilterDrawer
        open={showFilterDrawer}
        onClose={() => setShowFilterDrawer(false)}
      />

      <NewSavedSearchDialog
        show={showAddSavedSearchDialog}
        onHide={() => setShowAddSavedSearchDialog(false)}
      />
    </>
  );
};

export default SearchShade;
