import { combineReducers } from '@reduxjs/toolkit';
import { siteReducer, initialSiteState } from './siteProviderReducer';
import {
  savedSearchReducer,
  initialSavedSearchState,
} from './savedSearchReducer';
import { profileReducer, initialProfileState } from './profileReducer';
import { activityReducer, initialActivityState } from './activityReducer';
import { listReducer, initialListState } from './listReducer';

export const initialState = {
  siteProvider: initialSiteState,
  savedSearch: initialSavedSearchState,
  profile: initialProfileState,
  activity: initialActivityState,
  lists: initialListState,
};

export const rootReducer = combineReducers({
  siteProvider: siteReducer,
  savedSearch: savedSearchReducer,
  profile: profileReducer,
  activity: activityReducer,
  lists: listReducer,
});
