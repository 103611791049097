import React, { useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Option,
  Select,
  Typography,
} from '@mui/joy';
import {
  useAuth,
  useLogging,
  useMedScoutMap,
  useNotification,
  useLayoutControl,
} from 'src/context';
import {
  useGetAllMedTerritories,
  useGetAllUsers,
  createTerritory,
  editTerritory,
  deleteTerritory,
} from 'src/hooks';
import { DeleteRounded } from '@mui/icons-material';
import {
  ControlledJoyInput,
  ControlledJoyCheckbox,
  BLADE_NAMES,
} from 'src/components';
import RadiusPanel from '../RadiusPanel';
import DrawActionButtons from '../DrawActionButtons';
import { HeatmapButton } from '../HeatmapButton';

const AddEditTerritory = () => {
  const { user, setUser } = useAuth();
  const log = useLogging();
  const { setNotification } = useNotification();
  const { setToggleDrawer, toggleDrawer } = useLayoutControl();
  const {
    currentOverlay,
    currentTerritory,
    drawingMode,
    isCreating,
    isEditing,
    territoryPolygons,
    adhocTerritory,
    setClickedFeatures,
    setCurrentTerritory,
    setDrawingMode,
    setIsCreating,
    setIsEditing,
    setTerritoryPolygons,
    setAdhocTerritory,
    setRadius,
    currentCircleRef,
    drawingManagerRef,
    setHeatMap,
  } = useMedScoutMap();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);

  const values = {
    ...currentTerritory,
    user_id: currentTerritory?.owner?.id || user?.id,
    is_cononical: currentTerritory?.is_canonical || false,
  };

  const showRepList =
    toggleDrawer?.drawer === BLADE_NAMES.REP_LIST && toggleDrawer?.open;
  const showTerritory =
    !!adhocTerritory &&
    toggleDrawer?.drawer === BLADE_NAMES.TERRITORY_ANALTYICS &&
    toggleDrawer?.open;

  const methods = useForm({
    values: isEditing
      ? values
      : {
          user: user?.id,
          is_canonical: false,
        },
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid, isDirty },
  } = methods;

  const { data: allUsersData } = useGetAllUsers(user?.company?.id?.toString());
  const { mutate: mutateAll } = useGetAllMedTerritories();

  const repValuesAndLabels = useMemo(() => {
    if (!allUsersData) return [];

    // get uniuqe reps from owner value is owner id and label is owner first name and last name
    // without lodash
    return allUsersData
      .map((user) => {
        return {
          value: user.id,
          label: `${user.first_name} ${user.last_name}`,
        };
      })
      .filter(
        (rep, index, self) =>
          index === self.findIndex((t) => t.value === rep.value)
      )
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [allUsersData, user]);

  useEffect(() => {
    if ((!isEditing && !isCreating) || drawingMode === 'RADIUS') {
      setAdhocTerritory(null);
      return;
    }

    const isValidTerritory = territoryPolygons[drawingMode]?.length > 0;

    if (isValidTerritory) {
      const data = isEditing
        ? {
            name: currentTerritory?.name,
            user: currentTerritory?.owner?.id,
            is_canonical: currentTerritory?.is_canonical,
          }
        : {
            name: 'Adhoc Territory',
            user: 'test_user_id',
            is_canonical: false,
          };

      if (drawingMode === 'DRAW') {
        const head = territoryPolygons[drawingMode]?.[0];
        const tail =
          territoryPolygons[drawingMode][
            territoryPolygons[drawingMode]?.length - 1
          ];

        if (!head || !tail || !head?.[0] || !tail?.[0]) return;

        if (head[0] !== tail[0] || head[1] !== tail[1]) {
          territoryPolygons[drawingMode].push(head);
        }

        const formatedPoints = [
          territoryPolygons[drawingMode].map((point) => {
            return { lat: Number(point[1]), lng: Number(point[0]) };
          }),
        ];

        data['points'] = formatedPoints;

        if (data['points'].length < 4) {
          setAdhocTerritory(null);
          return;
        }

        setAdhocTerritory({
          ...data,
        });
      } else {
        data['boundary_ids'] = territoryPolygons[drawingMode];

        if (!data['boundary_ids']) return;

        setAdhocTerritory(data);
      }
    } else {
      setAdhocTerritory(null);
    }

    return () => {
      setAdhocTerritory(null);
    };
  }, [isEditing, isCreating, drawingMode, territoryPolygons]);

  const handleDelete = async () => {
    if (!confirm('Are you sure you want to delete this territory?')) return;
    setIsDeleting(true);
    try {
      await deleteTerritory(currentTerritory.id);
      log.event('territoryDeleteSubmitted');
    } catch (err) {
      log.exception('Error deleting territory', {
        tags: {
          source: 'AddEditTerritory',
          error: err,
        },
      });

      setNotification({
        title: 'Error',
        message: 'Error deleting territory',
        type: 'error',
      });
    } finally {
      setCurrentTerritory(null);
      handleCancel();
      setUser({
        ...user,
        last_territory: null,
      });
      mutateAll();
      setIsDeleting(false);
    }
  };

  const clearMapOverlays = () => {
    if (drawingMode === 'DRAW') {
      currentOverlay?.setMap(null);
    }
    if (currentCircleRef.current) {
      currentCircleRef.current.setMap(null);
      currentCircleRef.current = null;
    }
  };

  const resetPolygonsAndRadius = () => {
    setTerritoryPolygons({
      STATE: [],
      COUNTY: [],
      ZIPCODE: [],
      DRAW: [],
      RADIUS: [],
    });
    setRadius(80467);
    setAdhocTerritory(null);
  };

  const handleCancel = () => {
    setIsCreating(false);
    setIsEditing(false);
    setHeatMap(false);

    resetPolygonsAndRadius();
    clearMapOverlays();

    drawingManagerRef.current?.setMap(null);
    setDrawingMode('STATE');

    if (toggleDrawer?.drawer !== BLADE_NAMES.RESULTS) {
      setToggleDrawer(BLADE_NAMES.RESULTS, true);
    }
    reset();
  };

  const handleClear = () => {
    resetPolygonsAndRadius();
    clearMapOverlays();
    setClickedFeatures([]);
  };

  const onSubmit = async (data: MedScout.Territory) => {
    if (!data) return;
    let territory = null;

    // drawing or other
    if (drawingMode === 'DRAW') {
      const head = territoryPolygons[drawingMode]?.[0];
      const tail =
        territoryPolygons[drawingMode][
          territoryPolygons[drawingMode]?.length - 1
        ];

      if (!head?.[0] || !tail?.[0]) {
        // trying to collect more data so we can figure out what is causing the error
        log.context('Error creating territory', {
          data: territoryPolygons[drawingMode] || [],
        });
        log.exception('Error creating territory', {
          tags: {
            source: 'AddEditTerritory',
            error: 'There are no points to create the territory',
          },
        });
        return;
      }

      // make sure that the last point is the same as the first point
      if (head[0] !== tail[0] || head[1] !== tail[1]) {
        territoryPolygons[drawingMode].push(head);
      }

      const formatedPoints = [
        territoryPolygons[drawingMode].map((point) => {
          return { lat: Number(point[1]), lng: Number(point[0]) };
        }),
      ];

      data['points'] = formatedPoints;
    } else if (drawingMode === 'RADIUS') {
      // Currently there is only one circle allowed when drawing.
      data['circles'] = [
        {
          center: territoryPolygons[drawingMode].center,
          radius: territoryPolygons[drawingMode].radius,
        },
      ];

      // make sure that the last point is the same as the first point
      const head = territoryPolygons[drawingMode]?.points[0]
        ? territoryPolygons[drawingMode]?.points[0]
        : null;

      const tail = territoryPolygons[drawingMode]?.points[
        territoryPolygons[drawingMode]?.points.length - 1
      ]
        ? territoryPolygons[drawingMode]?.points[
            territoryPolygons[drawingMode]?.points.length - 1
          ]
        : null;

      if (!head || !tail) {
        setNotification({
          title: 'Error',
          message:
            'There are no points to create the territory, please create radius',
          type: 'error',
        });
        return;
      }

      if (head.lat !== tail.lat || head.lng !== tail.lng) {
        territoryPolygons[drawingMode].points.push(head);
      }

      data['points'] = [territoryPolygons[drawingMode]?.points];
    } else {
      data['geometry'] = territoryPolygons[drawingMode];
    }

    // Updated check for undefined or length 0
    if (
      (!data['geometry'] || data['geometry'].length === 0) &&
      (!data['points'] || data['points'].length === 0) &&
      (!data['circles'] || data['circles'].length === 0)
    ) {
      log.exception('Territory values are empty', {
        tags: {
          source: 'AddEditTerritory',
          error: 'There are no points or geometry to create the territory',
          data: data as any,
        },
      });
      setNotification({
        title: 'Error',
        message: 'There are no points or geometry to create the territory',
        type: 'error',
      });
      return;
    }

    try {
      setIsLoading(true);
      if (isEditing) {
        territory = await editTerritory(currentTerritory.id, data);
      } else {
        territory = await createTerritory(data);
      }
      setCurrentTerritory(territory);
      setUser({
        ...user,
        last_territory: territory,
      });
      log.event(`Territory ${isEditing ? 'Updated' : 'Created'}`, {
        source: 'AddEditTerritory',
        drawingMode,
        territory,
      });
      setNotification({
        title: 'Success',
        message: `Territory ${isEditing ? 'updated' : 'created'} successfully`,
        type: 'success',
      });
    } catch (err) {
      log.exception(`Error ${isEditing ? 'updating' : 'creating'} Territory`, {
        tags: {
          source: 'AddEditTerritory',
          error: err,
        },
      });

      setNotification({
        title: 'Error',
        message: `Error ${isEditing ? 'updating' : 'creating'} Territory`,
        type: 'error',
      });
    } finally {
      handleCancel();
      mutateAll();
      setIsLoading(false);
    }
  };

  const isManager = user?.permissions?.is_manager || user?.is_superuser;
  const isDisabled =
    !isEditing &&
    (!isValid || !isDirty || territoryPolygons[drawingMode]?.length === 0);
  return (
    <FormProvider {...methods}>
      <Card>
        <CardContent>
          <Typography level="title-lg">
            {isEditing ? 'Edit' : 'Add'} Territory
          </Typography>
          <Typography level="body-sm">
            Click on the map to draw your territory.
          </Typography>
          <DrawActionButtons />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <HeatmapButton />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography level="body-xs" sx={{ fontWeight: 600 }}>
              Territory Name
            </Typography>
            <Button
              size="sm"
              variant="plain"
              onClick={() => setToggleDrawer(BLADE_NAMES.REP_LIST)}
              sx={{ fontSize: '0.75rem' }}
            >
              {showRepList ? 'Hide' : 'Show'} Existing Territories
            </Button>
          </Box>
          <ControlledJoyInput
            name="name"
            placeholder="Territory Name"
            required
          />
          {drawingMode === 'RADIUS' && <RadiusPanel />}
          {isManager && (
            <>
              <Typography level="body-xs" sx={{ fontWeight: 600 }}>
                Assigned Rep
              </Typography>
              <Controller
                name={isEditing ? 'user_id' : 'user'}
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      size="sm"
                      aria-label="Assigned Rep Select"
                      value={field.value}
                      onChange={(e, newValue) => {
                        field.onChange(newValue);
                      }}
                    >
                      <Option value={user?.id}>
                        Assigned to Me ({user?.first_name} {user?.last_name})
                      </Option>
                      {repValuesAndLabels.map((rep) => {
                        if (rep.value === user?.id) return null;

                        return (
                          <Option key={rep.value} value={rep.value}>
                            {rep.label}
                          </Option>
                        );
                      })}
                    </Select>
                  );
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0.5rem 0',
                  gap: '0.5rem',
                }}
              >
                <ControlledJoyCheckbox size="sm" name="is_canonical" />
                <Typography level="body-xs" sx={{ fontWeight: 600 }}>
                  Assigned Territory (Manager Editable Only)
                </Typography>
              </Box>
            </>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              size="sm"
              variant="plain"
              onClick={() => setToggleDrawer(BLADE_NAMES.TERRITORY_ANALTYICS)}
              sx={{ fontSize: '0.75rem' }}
              disabled={!adhocTerritory}
            >
              {showTerritory ? 'Hide' : 'Show'} Territory Analytics
            </Button>
          </Box>

          <CardActions sx={{ justifyContent: 'space-between' }}>
            <Button
              size="sm"
              variant="outlined"
              color="danger"
              onClick={isEditing ? handleDelete : handleClear}
              loading={isDeleting}
            >
              {isEditing ? <DeleteRounded fontSize="small" /> : 'Clear'}
            </Button>
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
              <Button
                size="sm"
                variant="outlined"
                color="neutral"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                variant="solid"
                color="primary"
                onClick={handleSubmit(onSubmit)}
                disabled={isDisabled}
                loading={isLoading}
              >
                {isEditing ? 'Update' : 'Create'} Territory
              </Button>
            </Box>
          </CardActions>
        </CardContent>
      </Card>
    </FormProvider>
  );
};

export default AddEditTerritory;
