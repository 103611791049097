import React from 'react';
import { Typography } from '@mui/joy';
import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import { useSite, useProfile } from 'src/context';
import { useGetSavedSearches } from 'src/hooks';

const HtmlToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#f5f5f9',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: '220px',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    boxShadow: theme.shadows[6],
  },
}));

const NewVolumeCell = ({ row, field, ...props }) => {
  const { state } = useSite();
  const { details } = useProfile();
  const { data: savedSearches = [] } = useGetSavedSearches();

  let cellValue = null;
  let message = '';

  const providerName = details?.name;
  const type = details?.content_type;
  const pageFilter = state?.siteProvider[type];
  const isClaims = pageFilter?.selectedType === 'claims';

  const isTreating = field.includes('here') || field.includes('total');

  if (isTreating) {
    // Destructure location and id directly from field
    const [location, id] = field.split('_');

    const locationMap = {
      here: 'volume_at_location',
      total: 'volume_total',
    };

    const dataKey = locationMap[location];
    cellValue = row[dataKey]?.[id] ?? null;
    const currentValue = savedSearches.find(
      (search) => search.id === Number(id)
    );

    if (!isClaims) {
      if (location === 'here') {
        message += `${row?.name} has a total patient volume of ${cellValue} for ${currentValue?.search_name} at ${providerName}`;
      } else {
        message += `${row?.name} has a total patient volume of ${cellValue} for ${currentValue?.search_name} across all locations`;
      }
    } else {
      if (location === 'here') {
        message += `${row?.name} has a total claims volume of ${cellValue} for ${currentValue?.search_name} at ${providerName}`;
      } else {
        message += `${row?.name} has a total claims volume of ${cellValue} for ${currentValue?.search_name} across all locations`;
      }
    }
  } else {
    cellValue = row.volume[field] ?? null;
    const currentValue = savedSearches.find(
      (search) => search.id === Number(field)
    );
    if (!isClaims) {
      message += `${row?.name}  has referred ${cellValue} patients into ${providerName} for ${currentValue?.search_name}`;
    } else {
      message += `${row?.name}  has referred ${cellValue} claims to ${providerName} for ${currentValue?.search_name}`;
    }
  }

  return (
    <HtmlToolTip title={message} arrow>
      <Typography level="body-md" sx={{ fontWeight: 400, padding: '0 0.5rem' }}>
        {cellValue}
      </Typography>
    </HtmlToolTip>
  );
};

export default NewVolumeCell;
