import { Box, Card, Tooltip, Typography } from '@mui/joy';
import { ReportDataIssueButton } from 'src/modules/Profile/components/Dialogs';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { useLayoutControl, useProfile } from 'src/context';
import { DataInfo } from 'src/components';
import AddressDisplay from './AddressDisplay';
import DetailsDisplay from './DetailsDisplay';
import { headerStyle } from '../styleConstants';
import { exists, hasContent } from 'src/utils';
import TypeIcon from 'src/components/TypeIcon';
import { useMemo } from 'react';
import React from 'react';

const hcpLabelMap = {
  NPI: 'NPI',
  Name: 'Name',
  Specialty: 'Specialty',
  'Addresses.primary_address.Phone': 'Phone',
  Email: 'Email',
};

const additionalDetailsMap = {
  group_purchasing_orgs: 'Group Purchasing Organization (GPO)',
};

const groupLabelMap = {
  specialty: 'Specialty',
  pac_id: 'PAC ID',
  name: 'Name',
};

const ProfileDetails = () => {
  const { details } = useProfile();
  const { setShowReportDataIssueDialog } = useLayoutControl();

  const formattedHCPDetails = details?.hcp?.general
    ? Object.entries(hcpLabelMap)?.map(([key, title]) => {
        const matchingDetail = details?.hcp?.general.find(
          (detail) => detail.title === title
        );
        return matchingDetail || { title, value: '' };
      })
    : [];

  const clinicDetails = useMemo(() => {
    const general = details?.hcp?.general || [];

    if (details?.content_type === 'clinic') {
      Object.entries(additionalDetailsMap).forEach(([key, title]) => {
        const value = details?.hcp[key];
        if (value) {
          general.push({ title, value });
        }
      });
    }

    return general.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.title === item.title) &&
        exists(item.value) &&
        hasContent(item.value)
    );
  }, [details]);

  const formattedGroupDetails = details?.hcp_group?.general
    ? Object.entries(groupLabelMap)
        ?.map(([key, title]) => ({
          title,
          value:
            key === 'Name' ? details?.name : details?.hcp_group?.general[key],
        }))
        .filter((item) => exists(item.value))
    : [];

  const displayItems = hasContent(clinicDetails)
    ? clinicDetails
    : hasContent(formattedGroupDetails)
    ? formattedGroupDetails
    : hasContent(formattedHCPDetails)
    ? formattedHCPDetails
    : null;

  return (
    <>
      <Card sx={{ padding: 0 }}>
        <Box sx={headerStyle} aria-label="Details">
          <TypeIcon type={details?.content_type} />
          <Typography
            sx={{
              display: 'inline-block',
            }}
          >
            Details
            <Tooltip
              placement="top"
              variant="outlined"
              arrow
              title={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: 320,
                    justifyContent: 'center',
                    p: 1,
                  }}
                >
                  <DataInfo view="physicianProfile" />
                </Box>
              }
            >
              <Icon
                icon={faInfoCircle}
                size="xs"
                style={{
                  color: '#a9aaad',
                  marginLeft: '0.25rem',
                }}
              />
            </Tooltip>
          </Typography>
        </Box>
        <Box padding={'0 1rem 1rem'}>
          <DetailsDisplay items={displayItems} />
          {hasContent(formattedHCPDetails) && (
            <>
              <AddressDisplay isPrimary={true} />
              <AddressDisplay isPrimary={false} />
            </>
          )}
          <ReportDataIssueButton
            onClick={() =>
              setShowReportDataIssueDialog({
                show: true,
                providerId: details?.provider_id,
                providerType: 'hcp',
              })
            }
          />
        </Box>
      </Card>
    </>
  );
};

export default ProfileDetails;
