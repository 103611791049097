import React from 'react';
import { Box, IconButton, Typography } from '@mui/joy';
import { grey } from '@mui/material/colors';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

interface ActivityColumnHeaderProps {
  column: string;
  headerName: string;
  onColSort: (col: string, order: 'asc' | 'desc') => void;
  sort: { sort_value: string; order: 'asc' | 'desc' };
  flex?: number;
  width?: string;
  isSortable?: boolean;
}

const ActivityColumnHeader = ({ ...props }: ActivityColumnHeaderProps) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      flex={props.flex}
      width={props.width}
      sx={{
        height: '100%',
        color: grey[500],
        '&:hover': {
          color: grey[700],
        },
      }}
      onClick={() =>
        props.isSortable &&
        props.onColSort(
          props.column,
          props.sort.order === 'asc' ? 'desc' : 'asc'
        )
      }
    >
      <Typography level="title-sm" sx={{ color: '#4A4B4C' }}>
        {props.headerName}
      </Typography>
      {props.isSortable && (
        <IconButton
          size="sm"
          variant="plain"
          sx={{
            marginRight: '0.5rem',
            color: '#636B74',
            transition: 'all 0.2s ease',
            paddingLeft: 0,
            '&:hover': {
              color: grey[900],
              backgroundColor: 'transparent',
            },
            '& svg': {
              height: '1.25rem',
              width: '1.25rem',
            },
          }}
          onClick={() => {
            if (props.sort.sort_value === props.column) {
              props.onColSort(
                props.column,
                props.sort.order === 'asc' ? 'desc' : 'asc'
              );
            } else {
              props.onColSort(props.column, 'asc');
            }
          }}
        >
          {props.sort.sort_value === props.column &&
          props.sort.order === 'asc' ? (
            <ArrowUpward />
          ) : (
            <ArrowDownward />
          )}
        </IconButton>
      )}
    </Box>
  );
};

export default ActivityColumnHeader;
