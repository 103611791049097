import React, { useEffect, useState } from 'react';
import { Box, Tooltip } from '@mui/joy';
import { GeoJsonLayer } from '@deck.gl/layers';
import type { GeoJSON } from 'geojson';
import { useLogging, useMedScoutMap } from 'src/context';
import { MedMapDeckOverlay } from './components';
import { useGetGeoJsonData } from './hooks';
import { CircleContent, DrawContent } from '../Geometry';
import { HeatMapLayer } from '../MedHeatMapMarkers';

const MedMapContent = () => {
  const log = useLogging();

  const {
    heatMap,
    isEditing,
    isCreating,
    drawingMode,
    territoryPolygons,
    setTerritoryPolygons,
    clickedFeatures,
    setClickedFeatures,
  } = useMedScoutMap();

  // Get the geojson data
  const { geoJsonData, existingGeoJsonData } = useGetGeoJsonData();
  const [hoveredFeature, setHoveredFeature] = useState<string | null>(null);
  const [tooltipInfo, setTooltipInfo] = useState({ x: 0, y: 0, name: '' });

  useEffect(() => {
    if (!isEditing && !isCreating) {
      setTooltipInfo({
        x: 0,
        y: 0,
        name: '',
      });
    }
    setHoveredFeature(null);
    return () => {
      setTooltipInfo({
        x: 0,
        y: 0,
        name: '',
      });
      setHoveredFeature(null);
    };
  }, [isEditing, isCreating]);

  // Handles hovering over a feature
  const handleHover = (info) => {
    if (info.object) {
      setHoveredFeature(info.object.properties.id);
      setTooltipInfo({
        x: info.x,
        y: info.y,
        name: info.object.properties.name,
      });
    } else {
      setHoveredFeature(null);
      setTooltipInfo({
        x: 0,
        y: 0,
        name: '',
      });
    }
  };

  // Adds or removes the clicked feature from the list
  const handleClick = (info) => {
    // does info.object.properties.id exist in clickedFeatures, if yes remove it, if no add it
    const newClickedFeatures = clickedFeatures.includes(
      info.object.properties.id
    )
      ? clickedFeatures.filter((id) => id !== info.object.properties.id)
      : [...clickedFeatures, info.object.properties.id];

    // set the new clicked features
    setClickedFeatures(newClickedFeatures);

    // add to territory polygons if the id is not in the list
    const newTerritoryPolygons = territoryPolygons[drawingMode] || [];
    if (newTerritoryPolygons.includes(info.object.properties?.id)) {
      const index = newTerritoryPolygons.indexOf(info.object.properties?.id);
      newTerritoryPolygons.splice(index, 1);
    } else {
      newTerritoryPolygons.push(info.object.properties?.id);
    }

    // set the new territory polygons
    setTerritoryPolygons({
      [drawingMode]: newTerritoryPolygons,
    });

    log.event('territoryGeoJsonElementClicked', {
      selected: info.object,
      state: info?.object?.properties?.name,
      county: info?.object?.properties?.name,
      mode: drawingMode,
    });
  };

  // The actual layer that gets rendered on the map
  const getDeckGlLayers = (data: GeoJSON | null) => {
    if (!data) return null;
    return [
      new GeoJsonLayer({
        id: 'geojson-layer',
        data,
        stroked: true,
        filled: true,
        extruded: true,
        wireframe: true,
        pointType: 'circle',
        lineWidthScale: 20,
        lineWidthMinPixels: 4,
        getFillColor: (d) => {
          const isHovered = hoveredFeature === String(d.properties.id);
          const isClicked = clickedFeatures.includes(d.properties.id);
          if (isClicked) return [2, 27, 156, 105];
          if (isHovered) return [0, 0, 0, 75];
          return [0, 0, 0, 35]; // Default color
        },
        getLineColor: [0, 0, 0, 100],
        getPointRadius: 200,
        getLineWidth: 1,
        getElevation: 30,
        pickable: isEditing || isCreating,
        onHover: handleHover,
        onClick: handleClick,
        updateTriggers: {
          getFillColor: [hoveredFeature, clickedFeatures], // Causes the layer to update and show the hovered state
        },
      }),
    ];
  };

  function hexToRgba(hex, alpha = 255): [number, number, number, number] {
    // Remove the # if it exists
    hex = hex.replace('#', '');

    // Check if the hex code is valid
    if (!/^([0-9A-Fa-f]{3}){1,2}$/.test(hex)) {
      throw new Error('Invalid hex color code');
    }

    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    if (hex.length === 3) {
      hex = hex
        .split('')
        .map((char) => char + char)
        .join('');
    }

    // Parse hex values
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Return the rgba string
    return [r, g, b, alpha];
  }

  const getExistingTerritories = (data: GeoJSON | null) => {
    if (!data) return null;

    return [
      new GeoJsonLayer({
        id: 'existing-territory-layer',
        data,
        stroked: true,
        filled: true,
        extruded: true,
        wireframe: true,
        pointType: 'circle',
        lineWidthScale: 20,
        getFillColor: (d) => {
          const color = hexToRgba(
            d.properties.fillColor,
            d.properties.fillOpacity
          );
          // Convert the color to RGBA format if needed, for example:
          return color || [0, 0, 0, 100];
        },
        getLineColor: (d) => {
          const color = hexToRgba(
            d.properties.strokeColor,
            d.properties.strokeOpacity
          );
          return color || [0, 0, 0, 100];
        },
        lineWidthMinPixels: 4,
        getLineWidth: (d) => d.properties.strokeWidth || 3,
        getPointRadius: 200,
        getElevation: 30,
        pickable: isEditing || isCreating,
      }),
    ];
  };

  const isNotDrawOrRadius = drawingMode !== 'DRAW' && drawingMode !== 'RADIUS';
  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      {drawingMode === 'RADIUS' && <CircleContent />}
      {drawingMode === 'DRAW' && <DrawContent />}
      {isNotDrawOrRadius && geoJsonData.length > 0 && (
        <MedMapDeckOverlay
          layers={
            getDeckGlLayers({
              type: 'FeatureCollection',
              features: geoJsonData,
            }) || []
          }
        />
      )}
      {existingGeoJsonData && existingGeoJsonData?.length > 0 && (
        <MedMapDeckOverlay
          layers={
            getExistingTerritories({
              type: 'FeatureCollection',
              features: existingGeoJsonData,
            }) || []
          }
        />
      )}
      {heatMap && <HeatMapLayer />}
      {/* Render the Joy UI Tooltip */}
      {tooltipInfo.name && (
        <Tooltip
          color="neutral"
          variant="soft"
          arrow
          open // Keep the tooltip open as long as the user hovers
          title={tooltipInfo.name} // Display the feature name
          placement="top"
          sx={{
            pointerEvents: 'none', // Prevent tooltip from interfering with map interactions
          }}
        >
          {/* Invisible span as the anchor for the tooltip */}
          <span
            style={{
              position: 'absolute',
              top: tooltipInfo.y, // Offset from the cursor
              left: tooltipInfo.x,
              width: 0,
              height: 0,
            }}
          />
        </Tooltip>
      )}
    </Box>
  );
};

export default MedMapContent;
