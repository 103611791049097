import React from 'react';
import ActivityColumnHeader from './ActivityColumnHeader';
import { TableHeaderItem } from './components/TableHeaderItem';

const ActivityTableHeader = ({
  sort,
  handleColSort,
  isManager,
  showStatusColumn,
}) => {
  return (
    <thead
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 4,
      }}
    >
      <tr style={{ display: 'flex' }}>
        <TableHeaderItem styles={{ width: '1rem' }}></TableHeaderItem>
        <TableHeaderItem styles={{ width: '15rem' }}>
          <ActivityColumnHeader
            column="type"
            headerName="Activity Type"
            sort={sort}
            onColSort={handleColSort}
            isSortable
          />
        </TableHeaderItem>
        <TableHeaderItem styles={{ width: '15rem' }}>
          <ActivityColumnHeader
            column="name"
            headerName="Target"
            sort={sort}
            onColSort={handleColSort}
            isSortable
          />
        </TableHeaderItem>
        {isManager && (
          <TableHeaderItem styles={{ width: '15rem' }}>
            <ActivityColumnHeader
              column="owner"
              headerName="Representative"
              sort={sort}
              onColSort={handleColSort}
            />
          </TableHeaderItem>
        )}
        <TableHeaderItem styles={{ width: '15rem' }}>
          <ActivityColumnHeader
            column="created_at"
            headerName="Time"
            sort={
              sort?.sort_value && sort?.order
                ? sort
                : { sort_value: 'created_at', order: 'asc' }
            }
            onColSort={handleColSort}
            isSortable
          />
        </TableHeaderItem>
        {showStatusColumn && (
          <TableHeaderItem styles={{ width: '15rem' }}>
            <ActivityColumnHeader
              column="crm status"
              headerName="CRM Status"
              sort={sort}
              onColSort={handleColSort}
            />
          </TableHeaderItem>
        )}
        <TableHeaderItem styles={{ width: '15rem', flexGrow: 1 }}>
          <ActivityColumnHeader
            column="Note"
            headerName="Note"
            sort={sort}
            onColSort={handleColSort}
          />
        </TableHeaderItem>
      </tr>
    </thead>
  );
};

export default ActivityTableHeader;
