/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useRouter } from 'next/router';
import {
  LoadingIndicator,
  TermsAndConditionsModal,
  PrimaryNav,
  Notification,
  AddListDialog,
  ReportDataIssueDialog,
  ResolveDataIssueDialog,
  PushToCRMDialog,
  PushToCRMDuplicateDialog,
} from 'src/components';
import { useHasMounted } from 'src/utils/hooks/useHasMounted';
import { useStore } from 'src/store/store';
import { useGetDataSourcesInfo } from 'src/api/prospects/meta';
import { useLogging, useAuth, useLayoutControl } from 'src/context';
import ErrorFallback from './ErrorFallback';
import { styled } from '@mui/material';
import { red } from '@mui/material/colors';
import TestPushToCRMDialog from 'src/components/PushToCRM/TestPushToCRMDialog';

export const NOTIFICATION_DISPLAY_STATUS_VALUES = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  INFO: 'INFO',
};

const AppFrame = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  height: '100%',
  width: '100%',
  overflow: 'hidden',
});

const AppContent = styled('div')({
  flexGrow: 1,
  position: 'relative',
  overflowY: 'auto',
  overflowX: 'hidden',
});

function AuthorizedLayout({ children }) {
  const router = useRouter();
  const hasMounted = useHasMounted();
  const updateDataSourcesInfo = useStore(
    (store: any) => store.updateDataSourcesInfo
  );
  const { logout, user } = useAuth();
  const { data: dataSourcesInfo } = useGetDataSourcesInfo(user?.id);
  const log = useLogging();
  const {
    showAddListDialog,
    setShowAddListDialog,
    showReportDataIssueDialog,
    setShowReportDataIssueDialog,
    showResolveDataIssueDialog,
    setShowResolveDataIssueDialog,
    pushProviderToCRM,
    setPushProviderToCRM,
    testPushToCRMDialog,
    setTestPushToCRMDialog,
    pushDuplicateToCRM,
    setPushDuplicateToCRM,
    setScrollValue,
  } = useLayoutControl();

  const [lastUrl, setLastUrl] = useState(null);
  const [lastTitle, setLastTitle] = useState(null);

  useEffect(() => {
    const handleRouteChange = (url) => {
      if (lastUrl !== url) {
        setLastUrl(url);
        const docTitle = document.title;

        if (lastTitle !== docTitle) {
          setLastTitle(docTitle);
          const title = handleTitleBuilder(url, docTitle);

          if (title) {
            log.event(title);
          }
        }

        log.page(url);
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  });

  useEffect(() => {
    if (!window || !document) return;

    const url = window.location.pathname + window.location.search;
    const docTitle = document.title;
    if (lastUrl !== url) {
      setLastUrl(url);
      if (lastTitle !== docTitle) {
        setLastTitle(docTitle);
        const title = handleTitleBuilder(url, docTitle);

        if (title !== '') {
          log.event(title);
        }
      }
      log.page(url);
    }
  }, [lastUrl, log, lastTitle]);

  useEffect(() => {
    if (user || !hasMounted) return;

    (async () => {
      const returnTo = await logout();
      router.replace(returnTo);
    })();
  }, [user, hasMounted, logout, router]);

  useEffect(() => {
    if (!dataSourcesInfo) return;

    updateDataSourcesInfo(dataSourcesInfo);
  }, [dataSourcesInfo]);

  // track all dom events
  useEffect(() => {
    const handleEvent = (e) => {
      const element = e.target;
      log.event('DOM Event', {
        type: e?.type,
        target: element?.tagName,
        id: element?.id,
        class: element?.className,
        value: element?.value,
      });
    };

    // Add event listeners
    document.addEventListener('click', handleEvent);

    return () => {
      // Remove event listeners
      document.removeEventListener('click', handleEvent);
    };
  }, []);

  if (!user && hasMounted) {
    return (
      <div style={{ height: '100vh' }}>
        <LoadingIndicator />
      </div>
    );
  }

  function handleTitleBuilder(url: string, docTitle: string) {
    const targetedAreas = ['hcp', 'hcp-group', 'clinic', 'center'];
    const titleAreas = [
      'drg',
      'apc',
      'cpt',
      'discovery',
      'search',
      'hcpcs',
      'icd',
      'icdp',
      'patient_patterns',
      'payments',
      'education',
      'prescriptions',
      'territories',
      'territory-overview',
      'territory-dashboard',
      'affiliations',
    ];

    const n = url.indexOf('?');
    const urlSplit = url?.substring(0, n != -1 ? n : url.length)?.split('/');
    const lastSegment = urlSplit[urlSplit.length - 1];

    let newTitle = titleAreas.includes(lastSegment)
      ? docTitle.split(' | ')[0].trim()
      : 'Profile';

    let location = urlSplit[2];

    // We are building titles here, so we only want the targeted areas provided.
    if (targetedAreas.includes(location)) {
      location = location.charAt(0).toUpperCase() + location.slice(1);
      newTitle = `${location} ${newTitle}`;
    } else if (newTitle === 'Profile') {
      newTitle = '';
    }

    return newTitle;
  }

  const scrollEvent = (e) => {
    setScrollValue(e.target.scrollTop);
  };

  // logo utilizing an <a></a> tag to force app/page reload whenever clicked
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(err) => {
        log.alert('Authorized layout error boundary hit');
        log.exception(err);
      }}
      onReset={() => {
        router.reload();
      }}
    >
      {hasMounted && (
        <AppFrame
          sx={{
            border: !!user?.impersonation ? `3px solid ${red[900]}` : 'none',
          }}
        >
          <PrimaryNav />
          <AppContent onScroll={(e) => scrollEvent(e)}>
            {children}
            <TermsAndConditionsModal />
            <Notification />
          </AppContent>
        </AppFrame>
      )}

      <AddListDialog
        open={showAddListDialog}
        onClose={() => setShowAddListDialog(false)}
      />

      <ReportDataIssueDialog
        open={showReportDataIssueDialog?.show}
        onClose={() => setShowReportDataIssueDialog({ show: false })}
        providerId={showReportDataIssueDialog?.providerId}
        providerType={showReportDataIssueDialog?.providerType}
      />

      <ResolveDataIssueDialog
        open={showResolveDataIssueDialog?.show}
        onClose={() => setShowResolveDataIssueDialog({ show: false })}
        dataIssueId={showResolveDataIssueDialog?.dataIssueId}
      />

      <PushToCRMDialog
        open={!!pushProviderToCRM?.integration || !!pushProviderToCRM?.provider}
        onClose={() => setPushProviderToCRM(null, null)}
        provider={pushProviderToCRM?.provider}
        integration={pushProviderToCRM?.integration}
      />

      <TestPushToCRMDialog
        open={!!testPushToCRMDialog?.mapping}
        onClose={() => setTestPushToCRMDialog(null, null, null, null)}
        mapping={testPushToCRMDialog?.mapping}
        isDryRun={testPushToCRMDialog?.isDryRun}
        handleCRMTestDialogResponse={
          testPushToCRMDialog?.handleCRMTestDialogResponse
        }
        setTestPushError={testPushToCRMDialog?.setTestPushError}
      />

      <PushToCRMDuplicateDialog
        open={!!pushDuplicateToCRM}
        onClose={() => setPushDuplicateToCRM(null)}
      />
    </ErrorBoundary>
  );
}

export default AuthorizedLayout;
