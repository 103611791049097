// /api/v1/analytics/dashboards/{dashboard id}/render/{territory_id}?saved_search_ids=[list, of, ids, ...]
import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEYS } from './constants';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import DashboardService from 'src/services/DashboardService';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';

interface DashboardBladeParams {
  dashboardId: number;
  territoryId: number | string;
  enabledSearchIds: string[];
  type?: string;
  territory?: any;
  isAnalytics?: boolean;
}

/**
 * @description   This hook is used to fetch the data for a dashboard blade
 * @param         {DashboardBladeParams} params
 * @returns       {QueryObserverResult<MedScout.Dashboard, unknown>}
 */

const useTerritoryDashboard = ({
  dashboardId,
  territoryId,
  enabledSearchIds,
  isAnalytics,
  territory,
  type,
}: DashboardBladeParams) => {
  const theme = useTheme();

  const queryParams = getSearchQueryParamsForAPI({
    saved_search_ids: enabledSearchIds,
    type: type || undefined,
  });

  let enabled = isAnalytics
    ? !!dashboardId && !!territoryId && enabledSearchIds.length > 0
    : !!dashboardId &&
      !!territoryId &&
      enabledSearchIds.length > 0 &&
      !!territory;

  const isAdhoc = territory?.name === 'Adhoc Territory';
  if (isAdhoc) {
    // check if points or boundary_ids are present
    const hasPoints = territory?.points && territory?.points.length > 3;
    const hasBoundaryIds =
      territory?.boundary_ids && territory?.boundary_ids.length > 0;
    if (!hasPoints && !hasBoundaryIds) {
      enabled = false;
    }
  }

  return useQuery<undefined, unknown, MedScout.Dashboard>(
    [QUERY_KEYS.dashboard, territoryId, enabledSearchIds, territory],
    () => {
      return request({
        url: `/v1/analytics/dashboards/${dashboardId}/render/${territoryId}/${queryParams}`,
        method: isAnalytics ? 'GET' : 'POST',
        data: territory,
      });
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onSuccess: (data) => {
        const formattedData = DashboardService.formatDashboardData(
          data,
          theme.palette.chart
        );
        return formattedData;
      },
    }
  );
};

export default useTerritoryDashboard;
