import { SavedSearchActionTypes } from './actionTypes';

type SavedSearchFolder = {
  id?: string;
  type: string;
  saved_search?: string;
  saved_searches?: string[];
  org_saved_search?: boolean;
};

type InitialState = {
  folders: SavedSearchFolder[];
  tempFolders: SavedSearchFolder[];
  tempSavedSearches: any[];
  prevSavedSearch: MedScout.SavedSearch;
  openAccordion: number | null;
  activeFolder: SavedSearchFolder | null;
  org_saved_search?: 'company' | 'personal' | null;
  filterBySavedSearch: {
    selectedSavedSearch?: any;
    selectedSavedSearches?: any[];
    showSearches: boolean;
  };
  queryParams?: {
    q: string;
  };
};

export const initialSavedSearchState: InitialState = {
  folders: [],
  tempFolders: [],
  tempSavedSearches: [],
  prevSavedSearch: null,
  openAccordion: null,
  activeFolder: null,
  org_saved_search: null,
  filterBySavedSearch: {
    selectedSavedSearch: null,
    selectedSavedSearches: [],
    showSearches: false,
  },
  queryParams: {
    q: '',
  },
};

export const savedSearchReducer = (
  state = initialSavedSearchState,
  action: any
) => {
  switch (action.type) {
    case SavedSearchActionTypes.SET_FOLDERS:
      return {
        ...state,
        folders: action.payload,
      };
    case SavedSearchActionTypes.SET_TEMP_FOLDER:
      return {
        ...state,
        tempFolders: [...state.tempFolders, action.payload],
      };
    case SavedSearchActionTypes.REMOVE_TEMP_FOLDER:
      return {
        ...state,
        tempFolders: state.tempFolders.filter(
          (folder) => folder.id !== action.payload
        ),
      };
    case SavedSearchActionTypes.SET_ACTIVE_FOLDER:
      return {
        ...state,
        activeFolder: action.payload,
      };
    case SavedSearchActionTypes.SET_MOVED_SAVED_SEARCH: {
      const { id, folderId } = action.payload;

      const folder = state.folders.find((f) => f.id === folderId);
      // add the search to the folder
      if (folder) {
        const updatedFolders = state.folders.map((f) => {
          if (f.id === folderId) {
            return {
              ...f,
              saved_searches: [...f.saved_searches, Number(id)],
            };
          }
          return f;
        });

        return {
          ...state,
          folders: updatedFolders,
        };
      }
      return state;
    }
    case SavedSearchActionTypes.SET_TEMP_SAVED_SEARCH:
      return {
        ...state,
        tempSavedSearches: [...state.tempSavedSearches, action.payload],
      };
    case SavedSearchActionTypes.REMOVE_TEMP_SAVED_SEARCH:
      return {
        ...state,
        tempSavedSearches: state.tempSavedSearches.filter(
          (search) => search.id !== action.payload
        ),
      };
    case SavedSearchActionTypes.SET_PREVIOUS_SAVED_SEARCH:
      return {
        ...state,
        prevSavedSearch: action.payload,
      };
    case SavedSearchActionTypes.SET_ACCORDION: {
      return {
        ...state,
        openAccordion: action.payload,
      };
    }
    case SavedSearchActionTypes.SET_SAVED_SEARCH_TYPE:
      return {
        ...state,
        org_saved_search: action.payload,
      };
    case SavedSearchActionTypes.SET_SELECTED_SAVED_SEARCH: {
      return {
        ...state,
        filterBySavedSearch: {
          ...state.filterBySavedSearch,
          selectedSavedSearch: action.payload,
        },
      };
    }
    case SavedSearchActionTypes.SET_SELECTED_SAVED_SEARCHES: {
      return {
        ...state,
        filterBySavedSearch: {
          ...state.filterBySavedSearch,
          selectedSavedSearches: action.payload,
        },
      };
    }

    case SavedSearchActionTypes.SET_QUERY_PARAMS:
      return {
        ...state,
        queryParams: action.payload,
      };

    default:
      return state;
  }
};
