import { useMemo, useEffect } from 'react';
import {
  useLogging,
  useMedScoutMap,
  useNotification,
  useDiscoveryMap,
} from 'src/context';
import { useGetMedBoundaries, convertStateData } from 'src/hooks';

const useGetGeoJsonData = () => {
  const log = useLogging();
  const { setNotification } = useNotification();
  const { data: boundaries, isLoading } = useGetMedBoundaries();
  const {
    isEditing,
    isCreating,
    currentTerritory,
    territoryPolygons,
    drawingMode,
    clickedFeatures,
    setClickedFeatures,
  } = useMedScoutMap();

  const { existingTerritories } = useDiscoveryMap();
  const typeNameMap = {
    COUNTY: 'counties',
    ZIPCODE: 'zip_codes',
  };

  let source = [];

  // Paths can be in the form of new or old polygons
  const isNew = !!currentTerritory?.polygons;
  const paths = isNew
    ? currentTerritory?.polygons
    : currentTerritory?.old_polygons;

  // Construct coordinates for current territory
  const coordinates = useMemo(() => {
    if (!paths) return [];
    return paths?.map((path) => {
      return path.map((point) => {
        return [point.lng, point.lat];
      });
    });
  }, [paths]);

  // handle getting source
  useEffect(() => {
    handleGetSource();
  }, [currentTerritory, isEditing, isCreating, drawingMode]);

  useEffect(() => {
    if (isCreating) {
      setClickedFeatures([]);
    }
  }, [isCreating]);

  const handleConvert = async () => {
    try {
      const response = await convertStateData(
        territoryPolygons['STATE'],
        null,
        typeNameMap[drawingMode]
      );
      territoryPolygons['STATE'] = [];
      const newSource = response?.boundaries?.map((item) => item.id) || [];
      territoryPolygons[drawingMode] = newSource;
      setClickedFeatures(newSource);

      return newSource;
    } catch (err) {
      log.event('Error converting state to counties', {
        source: 'handleDrawingManagerLoad',
        error: err,
      });
      setNotification({
        title: 'Error converting state to counties',
        message: 'Error converting state to counties',
        type: 'error',
      });
      return [];
    }
  };
  const shouldConvert =
    drawingMode !== 'STATE' &&
    drawingMode !== 'RADIUS' &&
    drawingMode !== 'DRAW' &&
    territoryPolygons['STATE']?.length > 0;

  // Handle getting source for the territory
  const handleGetSource = async () => {
    if (
      !drawingMode ||
      !territoryPolygons['STATE']?.length ||
      !isCreating ||
      drawingMode === 'STATE'
    ) {
      if (isEditing && shouldConvert) {
        source = await handleConvert();
      } else if (isEditing && currentTerritory?.source) {
        const {
          states = [],
          counties = [],
          zip_codes = [],
          drawn = false,
          circles = [],
        } = currentTerritory.source || {};

        // drawingMode is not set as fast as this runs.
        if (states.length > 0) {
          source = states.map((state) => state.id);
          territoryPolygons['STATE'] = source;
        } else if (counties.length > 0) {
          source = counties.map((county) => county.id);
          territoryPolygons['COUNTY'] = source;
        } else if (zip_codes.length > 0) {
          source = zip_codes.map((zip) => zip.id);
          territoryPolygons['ZIPCODE'] = source;
        } else if (circles.length > 0 || drawn) {
          source = coordinates;
          territoryPolygons['RADIUS'] = source;
        } else {
          source = coordinates;
          territoryPolygons['DRAW'] = source;
        }

        setClickedFeatures(source);
      }
    } else if (isCreating && shouldConvert) {
      // Handle case where drawing and creating
      source = await handleConvert();
    }

    return Array.from(new Set([...source, ...clickedFeatures]));
  };

  // Construct GeoJson data for the map
  // Valid for all types of territories
  const geoJsonData = useMemo(() => {
    let newBoundaries = [];
    if (isEditing || isCreating) {
      if (!boundaries && !isLoading) return [];
      const tempPolys = [];

      boundaries?.boundaries.map((boundary) => {
        const polys = boundary.geometry.map((coord) => {
          return {
            type: 'Feature',
            properties: {
              id: boundary.id,
              name: boundary.label,
            },
            geometry: {
              type: 'Polygon',
              coordinates: [coord],
            },
          };
        });
        tempPolys.push(...polys);
      });

      newBoundaries = [...tempPolys];
    } else {
      if (!coordinates && !isLoading) return [];
      const polys = coordinates.map((coord) => {
        return {
          type: 'Feature',
          properties: {
            selected: false,
            hovered: false,
          },
          geometry: {
            type: 'Polygon',
            coordinates: [coord],
          },
        };
      });

      newBoundaries = [...polys];
    }

    return newBoundaries;
  }, [isEditing, isCreating, boundaries, isLoading, coordinates]);

  // Construct GeoJson data for existing territories
  const existingGeoJsonData = useMemo(() => {
    if (!existingTerritories) return [];
    let newBoundaries = [];
    existingTerritories.forEach((territory) => {
      const isNew = !!territory?.polygons;
      const paths = isNew ? territory?.polygons : territory?.old_polygons;

      const coordinates = paths?.map((path) => {
        return path.map((point) => {
          return [point.lng, point.lat];
        });
      });

      const polys = coordinates?.map((coord) => {
        return {
          type: 'Feature',
          properties: {
            selected: false,
            hovered: false,
            fillColor: territory?.color,
            fillOpacity: 100,
            strokeColor: territory?.color,
            strokeOpacity: 200,
            strokeWidth: 3,
          },
          geometry: {
            type: 'Polygon',
            coordinates: [coord],
          },
        };
      });

      newBoundaries = [...newBoundaries, ...polys];
    });

    return newBoundaries;
  }, [existingTerritories]);

  return {
    geoJsonData,
    existingGeoJsonData,
  };
};

export default useGetGeoJsonData;
