import React, { useEffect, useMemo } from 'react';
import { Box, Divider } from '@mui/joy';
import { styled } from '@mui/material';
import { SavedSearchHeader, Section } from './components';
import { useLogging, useSite, useAuth, useProspectSearch } from 'src/context';
import { useGetSavedSearches } from 'src/hooks';

const SavedSearchContainer = styled(Box)({
  position: 'absolute',
  height: '100%',
  width: '25.5rem',
  backgroundColor: 'white',
  borderRight: '1px solid #ccc',
  overflow: 'hidden',
});

const SectionsContainer = styled(Box)({
  height: '100%',
  width: '100%',
  overflow: 'hidden',
  paddingBottom: 'var(--1, 0.5rem)',
});

const SearchMenu = () => {
  const { user } = useAuth();
  const log = useLogging();
  const sections = ['PERSONAL', 'COMPANY'];
  const { state, dispatch } = useSite();
  const { prospectSearch } = useProspectSearch();
  const { data: savedSearches } = useGetSavedSearches();

  const toggleAccordion = (index) => {
    const newIndex = state?.savedSearch?.openAccordion === index ? null : index;
    dispatch({
      type: 'SET_ACCORDION',
      payload: newIndex,
    });
    log.event('toggleSavedSearchAccordion', {
      location: 'SavedSearch',
      action: 'onSubmit',
      object: index === 0 ? 'PERSONAL' : 'COMPANY',
      user: user,
    });
  };

  // group by type
  const groupedData = useMemo(() => {
    if (!state.savedSearch?.folders) return [];
    const folders = state.savedSearch.folders;
    const tempFolders = state.savedSearch?.tempFolders || [];

    const combined = [...tempFolders, ...folders];
    // group by type to remain in an array
    const grouped = combined?.reduce((acc, item) => {
      if (!acc[item.type]) {
        acc[item.type] = [];
      }

      acc[item.type].push(item);
      return acc;
    }, {});

    return Object.entries(grouped).map(([type, results]) => ({
      type,
      results,
    }));
  }, [state?.savedSearch?.folders, state?.savedSearch?.tempFolders]);

  useEffect(() => {
    const showCompanyToggle = () => {
      // if there are Personal searches then don't toggle company else if there aren't any personal searches then toggle company
      const personalSearches = savedSearches?.filter(
        (search) => !search.org_saved_search
      );

      return (
        personalSearches?.length === 0 &&
        state?.savedSearch?.openAccordion !== 1 &&
        !prospectSearch?.id
      );
    };

    const showCompany = showCompanyToggle();
    if (showCompany) {
      dispatch({
        type: 'SET_ACCORDION',
        payload: 1,
      });
    }
  }, [dispatch, savedSearches]);

  useEffect(() => {
    if (prospectSearch?.id) {
      const selectedSearch = savedSearches?.find(
        (search) => search.id === prospectSearch.id
      );
      const currentFolderType = selectedSearch?.org_saved_search ? 1 : 0;
      const isFolderOpen =
        state?.savedSearch?.openAccordion === currentFolderType;
      if (!!selectedSearch && !isFolderOpen) {
        dispatch({
          type: 'SET_ACCORDION',
          payload: currentFolderType,
        });
      }
    }
  }, [dispatch, prospectSearch, savedSearches]);

  return (
    <SavedSearchContainer>
      <SavedSearchHeader />
      <Divider />
      <SectionsContainer>
        {sections.map((name, index) => {
          const section = groupedData.find((group) => group.type === name);
          return (
            <Section
              key={index}
              index={index}
              name={name}
              section={section}
              toggleAccordion={toggleAccordion}
            />
          );
        })}
      </SectionsContainer>
    </SavedSearchContainer>
  );
};

export default SearchMenu;
