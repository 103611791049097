export const INTEGRATION_KEYS = {
  GET_MAPPINGS: 'getMappings',
  DELETE_MAPPING: 'deleteMapping',
  UPDATE_MAPPING: 'updateMapping',
  GET_MAPPING: 'getMapping',
  ADD_MAPPING: 'addMapping',
  GET_OBJECT_TYPE_ATTRIBUTES: 'getObjectTypeAttributes',
  GET_SETTINGS: 'getSettings',
  ADD_SETTING: 'addSetting',
  UPDATE_SETTINGS: 'updateSettings',
  DELETE_SETTING: 'deleteSetting',
  PUSH_TO_CRM: 'pushToCRM',
  GET_INTEGRATION_TOKEN: 'getIntegrationToken',
  GET_AVAILABLE_INTEGRATIONS: 'getAvailableIntegrations',
  INITIATE_INTEGRATION: 'initiateIntegration',
  GET_AUTOPOPULATE_OPTIONS: 'getAutopopulateOptions',
  GET_USER_INPUT_OPTIONS: 'getUserInputOptions',
  START_ONE_TIME_SYNC: 'startOneTimeSync',
  GET_SEARCH_CRM: 'getSearchCRM',
  GET_CRM_OBJECTS: 'getCrmObjects',
  ADD_DUPLICATE_PUSH: 'addDuplicatePush',
  TEST_PUSH_TO_CRM: 'testPushToCRM',
  GET_SYNC_PULL_HISTORY: 'getSyncPullHistory',
  GET_SYNC_PUSH_HISTORY: 'getSyncPushHistory',
  GET_ALL_STATUSES: 'getAllStatuses',
};
