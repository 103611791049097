import { request } from 'src/api/request';

export const getSavedSearches = async () => {
  return await request({
    url: `/v1/saved-searches`,
    method: 'GET',
  });
};

export const getNewSavedSearches = async (queryParams) => {
  return await request({
    url: `/v1/account/saved-searches/${queryParams}`,
    method: 'GET',
  });
};

export const getSavedSearchesById = async (queryParams) => {
  if (!queryParams) return;
  return await request({
    url: `/v1/account/saved-searches/${queryParams}`,
    method: 'GET',
  });
};

export const createSavedSearch = async (data) => {
  if (!data) return;
  return request({
    url: `/v1/account/saved-searches/`,
    method: 'POST',
    data,
  });
};

export const updateSavedSearch = async ({ id, data }) => {
  if (!id) return;

  delete data.id;

  return request({
    url: `/v1/account/saved-searches/${id}`,
    method: 'PATCH',
    data,
  });
};

export const deleteSavedSearch = async (id) => {
  if (!id) return;
  return request({
    url: `/v1/account/saved-searches/${id}`,
    method: 'DELETE',
  });
};

// Search Folders

export const getSavedSearchFolders = async () => {
  return await request({
    url: '/v1/accounts/saved-search-folders/',
    method: 'GET',
  });
};

export const addSavedSearchFolder = async (data) => {
  return await request({
    url: '/v1/accounts/saved-search-folders/',
    method: 'POST',
    data,
  });
};

export const updateSavedSearchFolder = async (data) => {
  return await request({
    url: `/v1/accounts/saved-search-folders/${data.id}/`,
    method: 'PATCH',
    data,
  });
};

export const deleteSavedSearchFolder = async (id) => {
  return await request({
    url: `/v1/accounts/saved-search-folders/${id}/`,
    method: 'DELETE',
  });
};
