import { request } from 'src/api/request';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

interface GetExportProps {
  fileType: string;
  columns: string[];
  searchParams: {
    listId: number;
    enabledSearchIds: string[];
    dateRange?: {
      lte: string;
      gte: string;
    };
    page: number;
    pageSize: number;
    sort: any;
    order: any;
    sort_search: any;
    sort_value: string;
    q: string;
    mode: 'code_volume' | 'sending' | 'receiving';
    statusFilters: string[];
    include_unpushed: number;
    shouldRefetch: boolean;
  };
}

export const getListExport = async ({
  searchParams,
  fileType,
  columns,
}: GetExportProps) => {
  const {
    listId,
    enabledSearchIds,
    dateRange,
    page,
    pageSize,
    sort,
    order,
    sort_search,
    sort_value,
    q,
    mode,
    statusFilters,
    include_unpushed,
  } = searchParams;
  let queryParams = getSearchQueryParamsForAPI({
    sort,
    order,
    sort_search,
    sort_value,
    page: page + 1,
    pageSize,
    q,
    mode,
    include_unpushed,
    dateRange,
  });

  const statusFilterParams = statusFilters
    ?.sort((a, b) => a.localeCompare(b))
    ?.map(
      (status) =>
        `&${encodeURIComponent('crm_status')}=${encodeURIComponent(status)}`
    )
    ?.join('');

  queryParams += statusFilterParams;

  const searchIdParams = enabledSearchIds
    .map(
      (id) => `&${encodeURIComponent('saved_search')}=${encodeURIComponent(id)}`
    )
    .join('');

  queryParams += searchIdParams;

  const url = `https://api.medscout.io/api/v1/provider-lists/lists/${listId}/items/${queryParams}`;

  return request({
    url: '/v1/admin/export/',
    method: 'POST',
    data: {
      url,
      file_format: fileType,
      columns: columns,
    },
  });
};
