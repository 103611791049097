import React from 'react';
import { Box, Typography } from '@mui/joy';
import { ActivityIcon } from 'src/components/Activity/components';
import useGetActivityTypes from 'src/hooks/activities/useGetActivityTypes';

interface ActivityTypeLabelProps {
  activityId: number;
}

const ActivityTypeLabel = ({ activityId }: ActivityTypeLabelProps) => {
  const { data: allActivities } = useGetActivityTypes();
  const activityList = allActivities?.results;

  const targetActivity = activityList?.find(
    (activity) => activity.id === activityId
  );
  const label = targetActivity?.label;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <ActivityIcon
        item={targetActivity}
        height="1.5rem"
        width="1.5rem"
        iconSize=".75rem"
      />
      <Typography sx={{ paddingLeft: '.5rem', color: '#2C2E2F' }}>
        {label}
      </Typography>
    </Box>
  );
};

export default ActivityTypeLabel;
