import React from 'react';
import { Box, Typography } from '@mui/joy';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faFolderBookmark,
  faFolderOpen,
} from '@fortawesome/pro-solid-svg-icons';
import { SavedSearch } from '../SavedSearch';

interface FolderProps {
  folder: any;
  context?: string | null;
  multiple?: boolean;
  onChange?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    search: any
  ) => void;
}

const Folder = ({
  folder,
  multiple = false,
  context,
  onChange,
}: FolderProps) => {
  const [isActive, setIsActive] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);

  const hasContent = folder.saved_searches?.length > 0;

  return (
    <>
      <Box
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          display: 'flex',
          height: '32px',
          padding: 'var(--none, 0px) var(--1, 0.5rem)',
          alignItems: 'center',
          gap: 'var(--05, 0.25rem)',
          alignSelf: 'stretch',
          borderRadius: 'var(--radius-md, 0.5rem)',
          cursor: hasContent ? 'pointer' : 'default',
          '&:hover': {
            background: hasContent
              ? 'var(--background-level1, #F0F4F8)'
              : 'none',
          },
        }}
        onClick={(e) => {
          e.stopPropagation();
          if (!hasContent) return;
          setIsActive((prev) => !prev);
        }}
      >
        <Box
          sx={{
            width: '20px',
            height: '20px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Icon
            icon={
              (isHovered || isActive) && hasContent
                ? faFolderOpen
                : faFolderBookmark
            }
            style={{
              color: hasContent
                ? 'var(--text-primary, #171A1C)'
                : 'text.disabled',
              opacity: hasContent ? 1 : 0.5,
            }}
          />
        </Box>
        <Typography
          sx={{
            color: hasContent
              ? 'var(--text-primary, #171A1C)'
              : 'text.disabled',
            fontSize: '0.875rem',
            fontWeight: 700,
            lineHeight: '1.243rem' /* 19.88px */,
            flex: '1 0 0',
            opacity: hasContent ? 1 : 0.5,
          }}
        >
          {folder.name}
        </Typography>
      </Box>
      {isActive &&
        folder.saved_searches?.length > 0 &&
        folder.saved_searches?.map((search, index) => {
          return (
            <SavedSearch
              key={index}
              search={search}
              context={context}
              multiple={multiple}
              onChange={onChange}
              inFolder
            />
          );
        })}
    </>
  );
};

export default Folder;
