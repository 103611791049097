import React, { useState } from 'react';
import { Box, IconButton } from '@mui/joy';
import { MedRMStatus, NewActivityLog, PushToCRMGroup } from 'src/components';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { AddProviderDialog } from 'src/components';

const ProfileBladeActions = ({ provider, onRefetch }) => {
  const [newProviders, setNewProviders] = useState([]);

  return (
    <Box sx={{ display: 'flex', gap: '0.5rem', width: '100%', height: 'sm' }}>
      <NewActivityLog />
      <PushToCRMGroup
        newHeaderView={true}
        provider={provider}
        variant="regular"
      />
      <MedRMStatus provider={provider} />
      <IconButton
        onClick={() => {
          setNewProviders([provider?.provider_id]);
        }}
        color="neutral"
        variant="soft"
        aria-label="Add to List"
      >
        <Icon icon={faPlus} size="sm" />
      </IconButton>
      {newProviders.length > 0 && (
        <AddProviderDialog
          onHide={() => {
            setNewProviders([]);
          }}
          newProviders={{
            providers: newProviders,
            ui_fromMap: false,
            onSuccess: () => {
              onRefetch();
            },
          }}
        />
      )}
    </Box>
  );
};

export default ProfileBladeActions;
