import React, { useMemo } from 'react';
import { Typography, Tooltip, Box } from '@mui/joy';
import { useProspectSearch } from 'src/context';
import { useGetSavedSearches } from 'src/hooks';

const HeaderVolume = ({ field, ...props }) => {
  const { data: savedSearches } = useGetSavedSearches();
  const { prospectSearch } = useProspectSearch();

  const title = useMemo(() => {
    if (!savedSearches) return null;
    const search = savedSearches?.find((item) => {
      return item.id?.toString() === field;
    });

    const filters = search?.search_filters;
    const cptCodes = filters?.cpt?.map((item) => item.value).join(', ');
    const hcpcsCodes = filters?.hcpcs?.map((item) => item.value).join(', ');
    const icd10Codes = filters?.icd?.map((item) => item.value).join(', ');
    const specialty = filters?.spec?.map((item) => item.title)?.join(', ');
    const drugs = filters?.drg?.map((item) => item.title)?.join(', ');
    const payer = filters?.payer?.map((item) => item.title)?.join(', ');
    const payments = filters?.payments?.map((item) => item.title)?.join(', ');
    const sites = filters?.site?.map((item) => item.title)?.join(', ');
    const systems = filters?.system?.map((item) => item.title)?.join(', ');

    const formattedCodes = [
      cptCodes || hcpcsCodes || icd10Codes
        ? `Codes: ${[cptCodes, hcpcsCodes, icd10Codes]
            .filter(Boolean)
            .join(', ')}`
        : null,
      specialty ? `Specialties: ${specialty}` : null,
      drugs ? `Drugs: ${drugs}` : null,
      payer ? `Payer: ${payer}` : null,
      payments ? `Payments: ${payments}` : null,
      sites ? `Sites: ${sites}` : null,
      systems ? `Systems: ${systems}` : null,
    ]
      .filter(Boolean) // Filter out null/empty values
      .join('; '); // Join the non-empty sections with a semicolon

    return (
      <Box
        sx={{
          padding: '0.25rem',
          maxWidth: '20rem',
        }}
      >
        <Typography level="body-sm">{search?.search_name}</Typography>
        <Typography level="body-xs">
          {formattedCodes || 'No data available'}
        </Typography>
      </Box>
    );
  }, [savedSearches, field, prospectSearch?.id]);

  return (
    <Tooltip
      variant="soft"
      color="neutral"
      size="sm"
      title={title}
      placement="top"
      arrow
      sx={{ padding: 0 }}
    >
      <Typography
        level="title-md"
        color="neutral"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          paddingRight: '0.5rem',
        }}
      >
        {props?.colDef?.headerName}
      </Typography>
    </Tooltip>
  );
};

export default HeaderVolume;
