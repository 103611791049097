import { ActivityActionTypes } from './actionTypes';

type InitialState = {
  hasData: boolean;
};

export const initialActivityState: InitialState = {
  hasData: false,
};

export const activityReducer = (state = initialActivityState, action: any) => {
  switch (action.type) {
    case ActivityActionTypes.SET_HAS_DATA:
      return {
        ...state,
        hasData: action.payload,
      };
    default:
      return state;
  }
};
