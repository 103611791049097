import React, { useMemo } from 'react';
import { Box, Card, CardContent, Tooltip, Typography } from '@mui/joy';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { useLayoutControl, useProfile } from 'src/context';
import { CardHeader } from '../Card/CardHeader';
import { DataInfo } from 'src/components';
import TypeIcon from 'src/components/TypeIcon';
import { exists, formatPhoneNumber, hasContent } from 'src/utils';
import { CardRow } from '../Card/CardRow';
import { ReportDataIssueButton } from 'src/modules/Profile/components/Dialogs';
import { useFlags } from 'launchdarkly-react-client-sdk';

const traumaLabelMap = {
  1: 'Level I',
  2: 'Level II',
  3: 'Level III',
  4: 'Level IV',
  5: 'Level V',
};

const additionalDetailsMap = {
  alos_days: 'Average Length of Stay',
  operating_room_count: 'Operating Rooms',
  total_bed_count: 'Staffed Beds',
  mac_carrier: 'Medicare Administrative Contractor (MAC)',
  adult_trauma_level: 'Adult Trauma',
  pediatric_trauma_level: 'Pediatric Trauma',
  group_purchasing_orgs: 'Group Purchasing Organization (GPO)',
};

const ProfileCenterDetails = () => {
  const { details } = useProfile();
  const { setShowReportDataIssueDialog } = useLayoutControl();
  const { sameTabRouting } = useFlags();

  const centerDetails = useMemo(() => {
    const general = details?.center?.general || [];
    // additional details from within details?.center object
    Object.entries(additionalDetailsMap).forEach(([key, title]) => {
      const value = details?.center[key];
      if (key === 'adult_trauma_level' || key === 'pediatric_trauma_level') {
        general.push({
          title,
          value: traumaLabelMap[value],
        });
      } else if (key === 'alos_days') {
        general.push({
          title,
          value: value && parseFloat(value)?.toFixed(1) + ' days',
        });
      } else if (typeof value === 'number' && value > 0) {
        general.push({ title, value: value.toString() });
      } else {
        general.push({ title, value });
      }
    });

    return general.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.title === item.title) &&
        exists(item.value) &&
        hasContent(item.value)
    );
  }, [details]);

  return (
    <Card sx={{ marginBottom: '1.5rem' }}>
      <CardHeader>
        <TypeIcon type="center" />
        <Typography>
          Details
          <Tooltip
            placement="top"
            variant="outlined"
            arrow
            title={
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: 320,
                  justifyContent: 'center',
                  alignItems: 'center',
                  p: 1,
                }}
              >
                <DataInfo view="physicianProfile" />
              </Box>
            }
          >
            <Icon
              icon={faInfoCircle}
              size="xs"
              style={{
                color: '#a9aaad',
                marginLeft: '0.25rem',
              }}
            />
          </Tooltip>
        </Typography>
      </CardHeader>
      <CardContent>
        {centerDetails?.map((item, i) => {
          const isPhone = item.title === 'Phone';
          const isWebsite = item.title === 'Website';
          const formattedValue = isPhone
            ? formatPhoneNumber(item.value)
            : item.value;

          return (
            <CardRow key={i}>
              <Typography level="body-sm" sx={{ alignSelf: 'flex-start' }}>
                {item.title}
              </Typography>
              <Typography
                level="body-sm"
                sx={{ color: '#3f4148', textAlign: 'end' }}
              >
                {isPhone && formattedValue ? (
                  <a href={`tel:${formattedValue}`}>{formattedValue}</a>
                ) : isWebsite && formattedValue ? (
                  <a
                    href={
                      formattedValue.startsWith('http')
                        ? formattedValue
                        : `http://${formattedValue}`
                    }
                    target={sameTabRouting ? '_self' : '_blank'}
                    rel="noreferrer"
                  >
                    {formattedValue}
                  </a>
                ) : Array.isArray(formattedValue) ? (
                  formattedValue.map((value, index) => (
                    <Box sx={{ textAlign: 'right' }} key={index}>
                      {value}
                    </Box>
                  ))
                ) : (
                  formattedValue
                )}
              </Typography>
            </CardRow>
          );
        })}
      </CardContent>
      <ReportDataIssueButton
        onClick={() =>
          setShowReportDataIssueDialog({
            show: true,
            providerId: details?.provider_id,
            providerType: 'hcp',
          })
        }
      />
    </Card>
  );
};

export default ProfileCenterDetails;
