import {
  createContext,
  useReducer,
  useContext,
  useEffect,
  useMemo,
  useState,
  Dispatch,
} from 'react';
import { request } from 'src/api/request';
import { useMutation } from 'react-query';
import { useAuth } from 'src/context';
import { ProspectService } from 'src/services';

const ProspectActionTypes: { [key: string]: string } = {
  SET_SEARCH: 'SET_SEARCH',
  SET_TYPE: 'SET_TYPE',
  SET_MODE: 'SET_MODE',
  SET_VOLUME_TYPE: 'SET_VOLUME_TYPE',
  SET_RELATIONSHIP: 'SET_RELATIONSHIP',
  SET_FILTERS: 'SET_FILTERS',
};

// Note that the saved search object is stored on the user model
type ProspectState = {
  queryString: string;
  filters: any[];
  type: 'HCP' | 'C';
  mode: 'code_volume' | 'sending' | 'receiving';
  relationship: 'inbound' | 'outbound';
  volumeType: 'claims' | 'patients';
};
type ProspectActions = {
  type: keyof typeof ProspectActionTypes;
  payload: {
    search?: MedScout.SavedSearch;
    type?: ProspectState['type'];
    mode?: ProspectState['mode'];
    volumeType?: ProspectState['volumeType'];
    relationship?: ProspectState['relationship'];
    filters?: ProspectState['filters'];
  };
};

const initialState: ProspectState = {
  queryString: '',
  filters: [],
  type: 'HCP',
  mode: 'code_volume',
  relationship: 'inbound',
  volumeType: 'claims',
};

const ProspectSearchContext = createContext<{
  state: ProspectState;
  dispatch: Dispatch<ProspectActions>;
}>({
  state: initialState,
  dispatch: () => null,
});
const ProspectSelectionContext = createContext(null);

const searchReducer = (state: ProspectState, action: ProspectActions) => {
  const newState = { ...state };

  switch (action.type) {
    case ProspectActionTypes.SET_SEARCH:
      newState.type =
        action.payload.search?.content_type ||
        action.payload.search?.type ||
        'HCP';
      newState.filters = ProspectService.getGroupedFilters(
        action.payload.search?.filters || action.payload.search || []
      );
      // newState.search = action.payload.search;
      break;
    case ProspectActionTypes.SET_FILTERS:
      newState.filters = action.payload.filters;
      break;
    case ProspectActionTypes.SET_TYPE:
      newState.type = action.payload.type;
      break;
    case ProspectActionTypes.SET_MODE:
      newState.mode = action.payload.mode;
      break;
    case ProspectActionTypes.SET_VOLUME_TYPE:
      newState.volumeType = action.payload.volumeType;
      break;
    case ProspectActionTypes.SET_RELATIONSHIP:
      newState.relationship = action.payload.relationship;
      break;
  }

  newState.queryString = ProspectService.getQueryString(
    newState.filters,
    newState.type
  );

  return newState;
};

const ProspectSearchProvider = (props) => {
  const [state, dispatch] = useReducer(searchReducer, initialState);
  const { user } = useAuth();
  const last_saved_search = user?.last_saved_search;

  useEffect(() => {
    if (last_saved_search) {
      dispatch({
        type: ProspectActionTypes.SET_SEARCH,
        payload: { search: last_saved_search },
      });
    }
  }, [last_saved_search]);

  return (
    <ProspectSearchContext.Provider value={{ state, dispatch }} {...props} />
  );
};

const ProspectSelectionProvider = (props) => {
  // TODO: convert this to a reducer
  const newProspectState = useState([]);
  const selectedState = useState(false);

  return (
    <ProspectSelectionContext.Provider
      value={{ state: newProspectState, selectedRows: selectedState }}
      {...props}
    />
  );
};

export const useProspectSearch = () => {
  const context = useContext(ProspectSearchContext);
  const { user, setUser } = useAuth();
  const prospectSearch = useMemo<MedScout.SavedSearch>(
    () => user?.last_saved_search || ({} as MedScout.SavedSearch),
    [user.last_saved_search]
  );

  const {
    state: {
      queryString,
      filters: prospectFilters,
      type: prospectType,
      mode: prospectMode,
      volumeType: prospectVolumeType,
      relationship: prospectRelationship,
    },
    dispatch,
  } = context;

  if (!context) {
    throw new Error(
      'useProspectSearch must be used within a ProspectSearchProvider'
    );
  }

  const { mutateAsync, isLoading } = useMutation(
    'v1/user/update',
    (newSearch: MedScout.SavedSearch) =>
      request({
        url: `/v1/account/current-user/`,
        method: 'PATCH',
        data: {
          last_saved_search_id: newSearch?.id || null,
        },
      }),
    {
      onMutate: (newSearch) => {
        setUser((oldUser) => ({
          ...oldUser,
          last_saved_search: newSearch,
        }));
      },
    }
  );

  const selectedFilters = ProspectService.getGroupedFilters(
    user?.last_saved_search?.filters || user?.last_saved_search
  );
  const isCustom =
    JSON.stringify(prospectFilters) !== JSON.stringify(selectedFilters);

  async function setProspectSearch(search?: MedScout.SavedSearch) {
    dispatch({
      type: ProspectActionTypes.SET_SEARCH,
      payload: {
        search,
      },
    });
    await mutateAsync(search);
  }

  function setProspectType(type: ProspectState['type']) {
    dispatch({ type: ProspectActionTypes.SET_TYPE, payload: { type } });
  }

  function setProspectMode(mode: ProspectState['mode']) {
    dispatch({ type: ProspectActionTypes.SET_MODE, payload: { mode } });
  }

  function setProspectVolumeType(volumeType: ProspectState['volumeType']) {
    dispatch({
      type: ProspectActionTypes.SET_VOLUME_TYPE,
      payload: { volumeType },
    });
  }

  function setProspectRelationship(
    relationship: ProspectState['relationship']
  ) {
    dispatch({
      type: ProspectActionTypes.SET_RELATIONSHIP,
      payload: { relationship },
    });
  }

  function setProspectFilters(filters: any[]) {
    dispatch({ type: ProspectActionTypes.SET_FILTERS, payload: { filters } });
  }

  return {
    prospectSearch,
    setProspectSearch,
    prospectFilters,
    setProspectFilters,
    prospectType,
    setProspectType,
    prospectMode,
    setProspectMode,
    prospectVolumeType,
    setProspectVolumeType,
    prospectRelationship,
    setProspectRelationship,
    queryString,
    isLoading,
    isCustom,
  };
};

export const useProspectSelection = () => {
  const context = useContext(ProspectSelectionContext);

  if (!context) {
    throw new Error(
      'useProspectSelection must be used within a ProspectSelectionProvider'
    );
  }

  return context;
};

export const ProspectProvider = ({ children }) => {
  return (
    <ProspectSearchProvider>
      <ProspectSelectionProvider>{children}</ProspectSelectionProvider>
    </ProspectSearchProvider>
  );
};
