import { GridColDef } from '@mui/x-data-grid-premium';
import { TableColumn } from 'src/components';

export const baseColumns: GridColDef[] = [
  TableColumn?.providerName('name', 'Name'),
  TableColumn?.crm_links('crm_links', 'Status'),
  TableColumn?.email('email', 'Email'),
  TableColumn?.specialty('specialty', 'Specialty'),
  TableColumn?.address('address', 'Address'),
  TableColumn?.npi('provider_id', 'NPI'),
  TableColumn?.city('city', 'City'),
  TableColumn?.state('state', 'State'),
  TableColumn?.zip('zip_code', 'Zip'),
  TableColumn?.phone('phone', 'Phone'),
];
