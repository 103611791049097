import { Box, Option, Typography, Select, styled } from '@mui/joy';
import { useLogging } from 'src/context';
import { useRouter } from 'next/router';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { useGetProfileDetails } from 'src/api/profiles/profiles';
import { useProspectSearch } from 'src/context';
import { useEffect } from 'react';
import { grey } from '@mui/material/colors';

const SelectorLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '1.125rem' /* 18px */,
  paddingBottom: '0.5rem',
}));

const ReferralSelector = () => {
  const router = useRouter();
  const { type, id, view } = router.query as {
    type: string;
    id: string;
    view: string;
  };

  const log = useLogging();
  const {
    prospectMode,
    setProspectMode,
    prospectRelationship,
    setProspectRelationship,
  } = useProspectSearch();
  const { data: profileDetails } = useGetProfileDetails({
    id: id,
    contentType: type,
  });

  useEffect(() => {
    if (prospectRelationship === 'outbound' && prospectMode === 'sending') {
      setProspectMode('receiving');
    } else if (
      prospectRelationship === 'inbound' &&
      prospectMode === 'receiving'
    ) {
      setProspectMode('sending');
    }
  }, [prospectRelationship]);

  function handleOnChange(e, newValue) {
    const queryParams = getSearchQueryParamsForAPI({
      relationship: newValue,
    });
    setProspectRelationship(newValue);

    // Sets the URL incase of page refresh, but does not cause a refresh.
    router.push(`/profiles/${type}/${id}/${view}${queryParams}`, undefined, {
      shallow: true,
    });

    log.event(`Referral Selector Changed`, {
      relationship: newValue,
      source: 'Referral Selector',
    });
  }

  return (
    <Box>
      <SelectorLabel>Providers Who</SelectorLabel>
      <Box sx={{ display: 'flex', gap: '0.25rem', alignItems: 'center' }}>
        <Select
          name="relationship"
          defaultValue="inbound"
          value={prospectRelationship || 'inbound'}
          onChange={handleOnChange}
          aria-label="Providers Who"
          sx={{ minWidth: '250px' }}
          size="sm"
        >
          <Option value="inbound" aria-label="Have referred to">
            have referred to
          </Option>
          <Option value="outbound" aria-label="Were sent referrals from">
            were sent referrals from
          </Option>
        </Select>
        <Typography
          sx={{
            alignSelf: 'center',
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: '1.5rem',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '16.271rem', // 260.333px
          }}
        >
          {profileDetails?.details?.name}
        </Typography>
      </Box>
    </Box>
  );
};

export default ReferralSelector;
