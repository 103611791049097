import useSWR from 'swr';
import { getEntityActivities } from 'src/api';
import { QUERY_KEYS } from './constants';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { useSite } from 'src/context';
const useGetEntityActivities = (providerId: string) => {
  const shouldFetch = !!providerId;
  const { state } = useSite();

  let queryParams = getSearchQueryParamsForAPI({});

  const activities = state?.profile?.queryParams?.activities
    ?.map(
      (id) =>
        `&${encodeURIComponent('activity_type')}=${encodeURIComponent(id)}`
    )
    ?.join('');

  queryParams += activities;

  // if queryparams does not start with a question mark, add it
  if (!queryParams.startsWith('?')) {
    // remove the first character which is an ampersand
    queryParams = `?${queryParams.slice(1)}`;
  }

  return useSWR(
    shouldFetch
      ? [QUERY_KEYS.GET_ENTITY_ACTIVITIES, providerId, queryParams]
      : null,
    () => getEntityActivities({ providerId, queryParams }),
    {
      keepPreviousData: true,
    }
  );
};

export default useGetEntityActivities;
