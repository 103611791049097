import React, { useRef, useState } from 'react';
import { useAuth } from 'src/context';
import {
  Box,
  IconButton,
  Typography,
  Dropdown,
  Menu,
  MenuItem,
} from '@mui/joy';
import { styled } from '@mui/material';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import {
  faFolderPlus,
  faMagnifyingGlassPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { useOnClickOutside } from 'src/hooks';
import { useSite, useProspectSearch } from 'src/context';
import { SavedSearchActionTypes } from 'src/context/reducers/actionTypes';

const SearchHeaderContainer = styled(Box)({
  display: 'flex',
  height: '2.5rem',
  padding: 'var(--none, 0px) var(--1, 0.5rem)',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexShrink: '0',
  alignSelf: 'stretch',
});

const HeaderTitle = styled(Typography)({
  color: 'var(--text-primary, #171A1C)',
  fontSize: '1rem',
  fontWeight: 700,
  lineHeight: '1.42rem' /* 22.72px */,
});

const StyledMenu = styled(Menu)({
  zIndex: 9999,
  display: 'flex',
  width: '10.438rem',
  padding: 'var(--05, 0.25rem)',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: 'var(--05, 0.25rem)',
});

const SectionTitle = styled(Typography)({
  display: 'flex',
  padding: '0.375rem var(--1, 0.5rem)',
  alignItems: 'center',
  gap: 'var(--05, 0.25rem)',
  alignSelf: 'stretch',
});

const StyledMenuItem = styled(MenuItem)({
  display: 'flex',
  padding: '0.375rem var(--1, 0.5rem)',
  alignItems: 'center',
  gap: 'var(--05, 0.25rem)',
  alignSelf: 'stretch',
  borderRadius: 'var(--radius-sm, 0.375rem)',
});

const MenuItemContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 'var(--1, 0.5rem)',
});

const SavedSearchMenuItem = styled(Typography)({
  color: 'var(--text-primary, #171A1C)',
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '1rem' /* 16px */,
});

const SavedSearchHeader = () => {
  const { user } = useAuth();
  const { dispatch } = useSite();
  const { setProspectFilters, setProspectSearch } = useProspectSearch();
  const menuRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNewSearch = (type) => {
    setProspectFilters([]);
    setProspectSearch(null);
    handleClose();
    dispatch({
      type: 'SET_SAVED_SEARCH_TYPE',
      payload: type.toLowerCase(),
    });
  };

  const handleInsertNewFolder = (type) => {
    const randomId = `temp-${Math.floor(Math.random() * 1000)}`;
    const newFolder = {
      id: randomId,
      type,
      name: 'New Folder',
    };

    // Dispatch the new folder action
    dispatch({
      type: SavedSearchActionTypes.SET_TEMP_FOLDER,
      payload: newFolder,
    });

    // close all accordions
    dispatch({
      type: SavedSearchActionTypes.SET_ACCORDION,
      payload: null,
    });

    // open the new accordion
    dispatch({
      type: SavedSearchActionTypes.SET_ACCORDION,
      payload: type === 'PERSONAL' ? 0 : 1,
    });

    handleClose();
  };

  useOnClickOutside(menuRef, anchorEl, handleClose);

  const isManager = user?.permissions?.is_manager || user?.is_superuser;
  return (
    <SearchHeaderContainer>
      <HeaderTitle>Saved Searches</HeaderTitle>

      <Dropdown>
        <IconButton
          size="sm"
          variant="plain"
          onClick={handleClick}
          aria-label="Add Search or Folder"
        >
          <Icon icon={faPlus} />
        </IconButton>
        <StyledMenu
          ref={menuRef}
          placement="bottom-end"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          size="sm"
        >
          <SectionTitle>Personal</SectionTitle>
          <StyledMenuItem onClick={() => handleInsertNewFolder('PERSONAL')}>
            <MenuItemContainer>
              <Icon icon={faFolderPlus} style={{ color: '#636B74' }} />
              <Typography sx={{}}>New Folder</Typography>
            </MenuItemContainer>
          </StyledMenuItem>
          <StyledMenuItem onClick={() => handleNewSearch('PERSONAL')}>
            <MenuItemContainer aria-label="New Saved Search">
              <Icon icon={faMagnifyingGlassPlus} style={{ color: '#636B74' }} />
              <SavedSearchMenuItem>New Search</SavedSearchMenuItem>
            </MenuItemContainer>
          </StyledMenuItem>
          {isManager && (
            <>
              <SectionTitle>Company</SectionTitle>
              <StyledMenuItem onClick={() => handleInsertNewFolder('COMPANY')}>
                <MenuItemContainer>
                  <Icon icon={faFolderPlus} style={{ color: '#636B74' }} />
                  <SavedSearchMenuItem>New Folder</SavedSearchMenuItem>
                </MenuItemContainer>
              </StyledMenuItem>
              <StyledMenuItem onClick={() => handleNewSearch('COMPANY')}>
                <MenuItemContainer>
                  <Icon
                    icon={faMagnifyingGlassPlus}
                    style={{ color: '#636B74' }}
                  />
                  <SavedSearchMenuItem>New Search</SavedSearchMenuItem>
                </MenuItemContainer>
              </StyledMenuItem>
            </>
          )}
        </StyledMenu>
      </Dropdown>
    </SearchHeaderContainer>
  );
};

export default SavedSearchHeader;
