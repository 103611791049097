import { Box, Link, Typography } from '@mui/joy';
import { styled } from '@mui/material';
import { useProfile } from 'src/context';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { exists } from 'src/utils';

const ItemWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: '.5rem',
});

const ItemLabel = styled(Typography)({
  color: '#134170',
  fontFamily: 'Inter',
  fontSize: '.875rem',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '146%',
  paddingRight: '0.5rem',
});

const ItemDetails = styled(Typography)({
  color: '#FFF',
  fontFamily: 'Inter',
  fontSize: '.875rem',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '146%',
});

const CRMLink = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  color: 'var(--Secondary-100, #97E8DB)',
  textAlign: 'right',
  fontFamily: 'Inter',
  fontSize: '.875rem',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '146%' /* 20.44px */,
});

const LinkDetails = styled(Typography)({
  color: 'var(--Secondary-100, #97E8DB)',
  textAlign: 'right',
  fontFamily: 'Inter',
  fontSize: '.875rem',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '146%' /* 20.44px */,
  textUnderlinePosition: 'from-font',
  textDecorationLine: 'underline',
  textDecorationStyle: 'solid',
  textDecorationSkipInk: 'none',
  textDecorationThickness: 'auto',
  textUnderlineOffset: 'auto',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
});

const ProfileBladeCRMLinks = () => {
  const { details } = useProfile();
  const links = details?.crm_links;

  if (!details) return null;

  const singleOwnerName = `${links[0]?.owner?.firstname} ${links[0]?.owner?.lastname}`;
  const singleStatus = links[0]?.crm_status;
  const objectType = links[0]?.crm_object_type;
  const hasLink = exists(details?.crm_links[0]?.crm_link);

  return (
    <Box sx={{ width: '100%' }}>
      {links.length === 1 && (
        <>
          {singleOwnerName.length > 0 && (
            <ItemWrapper>
              <ItemLabel>Owner</ItemLabel>
              <ItemDetails>{singleOwnerName}</ItemDetails>
            </ItemWrapper>
          )}
          {singleStatus && (
            <ItemWrapper>
              <ItemLabel>Status</ItemLabel>
              <ItemDetails>{singleStatus}</ItemDetails>
            </ItemWrapper>
          )}
          {hasLink && (
            <ItemWrapper>
              <ItemLabel>Link</ItemLabel>
              <CRMLink
                href={details.crm_links[0].crm_link}
                target={'_blank'}
                rel="noopener noreferrer"
              >
                <LinkDetails>View {objectType}</LinkDetails>
                <Icon
                  style={{
                    paddingLeft: '.25rem',
                  }}
                  icon={faExternalLink}
                />
              </CRMLink>
            </ItemWrapper>
          )}
        </>
      )}
      {links.length > 1 && (
        <>
          {links.map((link, index) => {
            const object = link.crm_object_type;
            const owner = `${link.owner.firstname} ${link.owner.lastname}`;
            const status = link.crm_status;
            const hasCRMDetails = link.owner.length > 0 || exists(status);
            const defaultLink = 'View in CRM';

            return (
              <ItemWrapper key={index}>
                <ItemLabel>{object}</ItemLabel>
                <CRMLink
                  href={details.crm_links[0].crm_link}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  <LinkDetails
                    sx={{
                      maxWidth: '240px',
                    }}
                  >
                    {hasCRMDetails
                      ? [owner, status].filter(Boolean).join(' / ')
                      : defaultLink}
                  </LinkDetails>
                  <Icon
                    style={{ paddingLeft: '.25rem' }}
                    icon={faExternalLink}
                  />
                </CRMLink>
              </ItemWrapper>
            );
          })}
        </>
      )}
    </Box>
  );
};

export default ProfileBladeCRMLinks;
