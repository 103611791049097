import React, { useMemo } from 'react';
import { useGetSavedSearches } from 'src/hooks';
import { TableColumn } from 'src/components';
import { VOLUME_SELECT_OPTIONS } from 'src/components/VolumeSelectors/constants';

/**
 * @description Custom hook to get the volume columns for the table including the grouping model for volumes
 * @returns volumeColumns, optionColumns, overlayColumn, columnGroupingModel
 */
const useVolumeColumns = () => {
  const { data: savedSearches = [] } = useGetSavedSearches();

  // TODO: Should be in a reusable hook
  const volumeColumns = useMemo(() => {
    if (!savedSearches) return [];
    const sortedSearches = [...savedSearches].sort((a, b) =>
      a.search_name?.localeCompare(b.search_name)
    );

    return sortedSearches.map((search) => {
      return {
        ...TableColumn.searchVolume(`${search?.id}`, search?.search_name),
        flex: 2,
        disableColumnMenu: true,
      };
    });
  }, [savedSearches]);

  // TODO: Should be in a reusable hook
  const optionColumns = useMemo(() => {
    if (!VOLUME_SELECT_OPTIONS) return [];

    return VOLUME_SELECT_OPTIONS.map((option) => {
      const tableField = `option_${option.value}`;
      return {
        ...TableColumn.trendVolume(tableField, option.label),
        flex: 1,
        disableColumnMenu: true,
      };
    });
  }, [VOLUME_SELECT_OPTIONS]);

  const overlayColumn = useMemo(() => {
    return TableColumn.overlayColumn('overlay', '');
  }, []);

  // Grouping model for volume columns
  const columnGroupingModel = useMemo(
    () => [
      {
        headerClassName: 'MuiDataGrid-groupHeader MuiDataGrid-lightHeader',
        groupId: 'volumes',
        renderHeaderGroup: () => <></>,
        children: [
          { field: 'volume' },
          ...volumeColumns,
          ...optionColumns,
          overlayColumn,
        ],
      },
    ],

    [volumeColumns, optionColumns, overlayColumn]
  );

  return {
    volumeColumns,
    optionColumns,
    overlayColumn,
    columnGroupingModel,
  };
};

export default useVolumeColumns;
