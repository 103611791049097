'use client';
import React, { useEffect, useState } from 'react';
import { Box, FormLabel, Input, styled } from '@mui/joy';
import { ManageSearchRounded } from '@mui/icons-material';
import { faBolt, faRotate, faUserTie } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { useAuth, useDashboard } from 'src/context';
import { useCompanyUsers } from 'src/hooks';
import RadioButtonSelect from './components/RadioButtonSelect';
import { MultiSelectMenu } from './components/MultiSelectMenu';
import useGetActivityTypes from 'src/hooks/activities/useGetActivityTypes';
import { SegmentedMultiSelectMenu } from './components/SegmentedMultiSelectMenu';

const FilterWrapper = styled(Box)({
  display: 'flex',
  gap: '0.5rem',
  width: '160px',
  minWidth: '120px',
  flexDirection: 'column',
  alignItems: 'flex-start',
});
interface ActivityTableToolbarProps {
  searchTerm: string;
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedReps: string[];
  setSelectedReps: any;
  selectedActivities: string[];
  setSelectedActivities: any;
  selectedStatuses: {
    [key: string]: string[];
  };
  setSelectedStatuses: any;
  selectedTimeframe: string;
  setSelectedTimeframe: any;
  statuses: { crm_type: string; crm_status: string }[];
}

const ActivityTableToolbar = ({
  searchTerm,
  onSearch,
  selectedReps,
  setSelectedReps,
  selectedActivities,
  setSelectedActivities,
  selectedStatuses,
  setSelectedStatuses,
  selectedTimeframe,
  setSelectedTimeframe,
  statuses,
}: ActivityTableToolbarProps) => {
  const { user } = useAuth();
  const companyId = user.company.id.toString();
  const isManager = user?.permissions?.is_manager || user.is_superuser;

  const { data: users, isLoading } = useCompanyUsers(companyId);
  const totalRepCount = users && users?.count;
  const { data: allActivities } = useGetActivityTypes();
  const activityList = allActivities?.results;
  const totalActivityCount = allActivities && allActivities?.count;
  const { userQueryParams, setUserQueryParams } = useDashboard();
  const { pageSize, page } = userQueryParams;

  useEffect(() => {
    return () => {
      setUserQueryParams({
        q: '',
        page,
        pageSize: 50,
        sort: '',
        order: '',
      });
    };
  }, []);

  const onMenuScroll = (isActivity: boolean) => {
    const totalCount = isActivity ? totalActivityCount : totalRepCount;
    if (totalCount > pageSize) {
      setUserQueryParams({ ...userQueryParams, pageSize: pageSize + 50 });
    }
  };

  const representativeOptions =
    users &&
    users?.results?.map((report) => {
      const fullName = `${report.first_name} ${report.last_name}`;
      return { id: report.id, label: fullName };
    });

  const statusOptions = statuses?.reduce((acc, status) => {
    const { crm_type, crm_status } = status;
    if (!crm_status) {
      return acc;
    }
    if (!acc[crm_type]) {
      acc[crm_type] = [];
    }
    acc[crm_type].push({ label: crm_status, id: crm_status });
    return acc;
  }, {});

  const [expandedSections, setExpandedSections] = useState<{
    [key: string]: boolean;
  }>({});

  const handleTimeframeChange = (value: string) => {
    if (value === 'all') {
      setSelectedTimeframe();
    } else {
      setSelectedTimeframe(value);
    }
  };

  const setSelectedOptions = (
    optionType: string,
    options?: string[] | { crm_type: string; crm_status: string }[]
  ) => {
    if (optionType === 'reps') {
      setSelectedReps(options);
    }
    if (optionType === 'activities') {
      setSelectedActivities(options);
    }
    if (optionType === 'statuses') {
      setSelectedStatuses(options);
    }
  };

  const hasStatuses = statuses?.some((obj) => obj.crm_status != null);

  return (
    <Box sx={{ marginLeft: '1rem' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '1rem 0',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            width: '100%',
          }}
        >
          <Box
            sx={{
              flex: 0.2,
              display: 'flex',
              flexDirection: 'column',
              gap: '0.375rem',
              minWidth: '17rem' /* 275px */,
            }}
          >
            <FormLabel htmlFor="search-input" sx={{ fontSize: '0.75rem' }}>
              Search Targets
            </FormLabel>
            <Input
              id="search-input"
              type="search"
              variant="outlined"
              sx={{
                display: 'flex',
                borderRadius: '1rem',
                backgroundColor: '#EBECED',
                minHeight: '2rem',
                padding: '0.25rem .5rem',
                alignItems: 'center',
                alignSelf: 'stretch',
                fontSize: '0.875rem',
                color: 'var(--text-tertiary, #555E68)',
                lineHeight: '142%' /* 19.88px */,
              }}
              size="sm"
              startDecorator={
                <ManageSearchRounded
                  style={{ height: '1rem', width: '1rem' }}
                />
              }
              placeholder="Find a Target by name"
              value={searchTerm}
              onChange={onSearch}
            />
          </Box>
          {isManager ? (
            <FilterWrapper>
              <FormLabel sx={{ fontSize: '0.75rem' }} htmlFor="rep-name-select">
                Rep. Name
              </FormLabel>
              <MultiSelectMenu
                header="Filter by Rep"
                optionType="reps"
                options={representativeOptions}
                enableSearch={true}
                selectedOptions={selectedReps}
                setSelectedOptions={setSelectedOptions}
                startDecorator={
                  <Icon
                    icon={faUserTie}
                    color="#5D5E5F"
                    style={{ height: '1rem', width: '1rem' }}
                  />
                }
                onMenuScroll={onMenuScroll}
                isLoading={isLoading}
              />
            </FilterWrapper>
          ) : null}
          <FilterWrapper>
            <FormLabel
              sx={{ fontSize: '0.75rem' }}
              htmlFor="activity-type-select"
            >
              Type
            </FormLabel>
            <MultiSelectMenu
              header="Filter by Type"
              optionType="activities"
              options={activityList}
              isActivity={true}
              startDecorator={
                <Icon
                  icon={faBolt}
                  color="#5D5E5F"
                  style={{ height: '1rem', width: '1rem' }}
                />
              }
              selectedOptions={selectedActivities}
              setSelectedOptions={setSelectedOptions}
              isLoading={isLoading}
              onMenuScroll={onMenuScroll}
            />
          </FilterWrapper>
          {hasStatuses && (
            <FilterWrapper>
              <FormLabel
                sx={{ fontSize: '0.75rem' }}
                htmlFor="activity-type-select"
              >
                Status
              </FormLabel>
              <SegmentedMultiSelectMenu
                filterHeader="Filter by Status"
                optionType="statuses"
                optionData={statusOptions}
                startDecorator={
                  <Icon
                    icon={faRotate}
                    color="#5D5E5F"
                    style={{ height: '1rem', width: '1rem' }}
                  />
                }
                selectedOptions={selectedStatuses}
                setSelectedOptions={setSelectedOptions}
                isLoading={isLoading}
                onMenuScroll={onMenuScroll}
                expandedSections={expandedSections}
                setExpandedSections={setExpandedSections}
              />
            </FilterWrapper>
          )}
          <FilterWrapper>
            <FormLabel htmlFor="timeframe-select" sx={{ fontSize: '0.75rem' }}>
              Timeframe
            </FormLabel>
            <RadioButtonSelect
              header="Timeframe"
              selectOnChange={handleTimeframeChange}
              selectedOption={selectedTimeframe || 'all'}
            />
          </FilterWrapper>
        </Box>
      </Box>
    </Box>
  );
};

export default ActivityTableToolbar;
