import React, { useEffect, useMemo, useRef } from 'react';
import { useLogging, useNotification, useAuth, useProfile } from 'src/context';
import { Box, Button, Select, Textarea, Option } from '@mui/joy';
import { styled } from '@mui/material';
import { useGetEntityActivities, useGetActivityTypes } from 'src/hooks';
import { createActivity, updateActivity } from 'src/api';
import { ActivitySelectOption } from 'src/components/Activity';
import { useSite } from 'src/context';

const ActivityContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  width: '100%',
  padding: '0.75rem',
  borderRadius: '0.75rem',
  border: '1px solid var(--Neutral-300, #CACCCD)',
  background: 'var(--Common-White, #FFF)',
  /* shadow-lg */
  boxShadow:
    '0px 2px 8px -2px rgba(21, 21, 21, 0.08), 0px 12px 16px -4px rgba(21, 21, 21, 0.08)',
});

interface AddEditActivityProps {
  item?: any;
  onClose?: () => void;
  onCancel?: () => void;
  checkHasData?: boolean;
}

const AddEditActivity = ({
  item = null,
  onClose,
  onCancel,
  checkHasData = false,
}: AddEditActivityProps) => {
  const { user } = useAuth();
  const log = useLogging();
  const { setNotification } = useNotification();
  const { details: providerDetails } = useProfile();
  const { dispatch } = useSite();

  const { data: activityTypes } = useGetActivityTypes();
  const { mutate } = useGetEntityActivities(providerDetails?.provider_id);

  const [activity, setActivity] = React.useState(null);
  const [details, setDetails] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const initialData = useRef({
    activity: activity,
    details: details,
  });

  const isEditing = !!item;

  const activityData = useMemo(() => {
    return activityTypes?.results
      ?.map((item) => ({
        label: item.label,
        value: item.id,
        ...item,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [activityTypes]);

  useEffect(() => {
    if (!item) {
      // set default activity to first item in list
      setActivity(activityData?.[0]);
      setDetails('');
      initialData.current = {
        activity: activityData?.[0],
        details: '',
      };
    } else {
      const activity = activityData?.find((i) => i.value === item?.type);
      setActivity(activity);
      setDetails(item?.details || '');
      initialData.current = {
        activity,
        details: item?.details || '',
      };
    }
  }, [activityData, item]);

  const setHasDataFlag = (flag) => {
    dispatch({ type: 'SET_HAS_DATA', payload: flag });
  };

  const handleActivityChange = (e, activity: string) => {
    const newActivity = activityData?.find((item) => item.value === activity);
    setActivity(newActivity);
  };

  const handleDetailsChange = (e) => {
    setDetails(e.target.value);
  };

  const checkIfDataChanged = () => {
    return (
      initialData.current.activity?.value !== activity?.value ||
      initialData.current.details !== details
    );
  };

  useEffect(() => {
    if (checkHasData) {
      setHasDataFlag(checkIfDataChanged());
    }
  }, [activity, details]);

  const handleCancel = () => {
    if (!checkHasData) {
      setDetails('');
      setActivity({
        label: 'Note',
        value: 'note',
      });
    }

    onCancel && onCancel();
  };

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      if (isEditing) {
        await updateActivity({
          activityId: item.id,
          data: {
            type: activity?.value,
            details,
          },
        });
        // optimistically update with submission
        mutate((data) => {
          return {
            ...data,
            results: data.results?.map((result) => {
              if (result.id === item.id) {
                return {
                  ...result,
                  type: activity?.value,
                  details,
                };
              }
              return result;
            }),
          };
        });
        log.event('Update Activity', {
          location: 'Profile',
          data: {
            type: activity?.value,
            details,
          },
          user: user,
        });
      } else {
        const response = await createActivity({
          providerId: providerDetails?.provider_id,
          data: {
            type: activity?.value,
            details,
          },
        });

        // optimistically update with response
        if (response) {
          mutate((data) => {
            // update data.results with new activity
            return {
              ...data,
              results: [response, ...data.results].sort(
                (a, b) =>
                  new Date(b.created_at).getTime() -
                  new Date(a.created_at).getTime()
              ),
            };
          });
        }

        log.event(isEditing ? 'Add Activity' : 'Updated Activity', {
          location: 'Profile',
          data: response,
          user: user,
        });
      }
      setNotification({
        title: 'Success',
        message: isEditing
          ? 'Activity updated successfully'
          : 'Activity added successfully',
        type: 'success',
      });
    } catch (err) {
      log.exception('Failed to save activity', {
        tags: {
          location: 'Profile',
          error: err,
        },
      });
      setNotification({
        title: 'Error',
        message: 'Failed to save activity',
        type: 'error',
      });
    } finally {
      mutate();
      onClose && onClose();
      setIsLoading(false);
      setHasDataFlag(false);
    }
  };

  return (
    <ActivityContainer onClick={(e) => e.stopPropagation()}>
      <Select
        value={activity?.value}
        onChange={handleActivityChange}
        slotProps={{
          listbox: { sx: { zIndex: 9999 } },
        }}
        renderValue={(value) => {
          const selectedActivity = activityData?.find(
            (item) => item.value === value.value
          );
          return <ActivitySelectOption item={selectedActivity} />;
        }}
      >
        {activityData?.map((item) => (
          <Option key={item.value} value={item.value}>
            <ActivitySelectOption item={item} />
          </Option>
        ))}
      </Select>
      <Textarea
        size="md"
        color="neutral"
        variant="outlined"
        placeholder={`Type a ${activity?.label.toLowerCase()}...`}
        value={details}
        onChange={handleDetailsChange}
        autoFocus
        onFocus={(e) => e.target.select()}
        sx={{
          width: '100%',
          height: '100%',
          minHeight: '10rem',
          maxHeight: '20rem',
          padding: '0.5rem',
          background: '#F5F7F8',
        }}
        onKeyDown={(e) => {
          if (e.metaKey && e.key === 'Enter') {
            onSubmit();
          }
        }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '0.5rem' }}>
        <Button
          color="neutral"
          variant="soft"
          onClick={handleCancel}
          disabled={details?.length === 0}
        >
          {isEditing ? 'Discard Changes' : 'Cancel'}
        </Button>
        <Button
          variant="solid"
          loading={isLoading}
          sx={{
            backgroundColor: '#2391D9',
          }}
          onClick={onSubmit}
          disabled={!details || isLoading}
        >
          Log
        </Button>
      </Box>
    </ActivityContainer>
  );
};

export default AddEditActivity;
