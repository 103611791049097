import React from 'react';

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div
      className={`fixed top-16 right-3 max-w-sm w-full bg-white border border-gray-300 rounded-lg shadow-lg transition-opacity duration-300 opacity-100`}
      style={{ zIndex: 1400 }}
    >
      <div className="flex items-center justify-between px-4 py-2 border-b border-gray-300">
        <h2 className="text-red-600 font-semibold">There was an error</h2>
        <span className="text-gray-500 text-sm">just now</span>
      </div>
      <div className="p-4">
        <p className="text-red-600">
          {error?.message || 'Something unexpected happened.'}
        </p>
        <div className="flex justify-end mt-4">
          <button
            onClick={resetErrorBoundary}
            className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700"
          >
            Reload
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorFallback;
